import { PermissionService } from '@services/permission.service';
import * as angular from 'angular';

export class DayNumberService {
  private _permissionService: PermissionService;

  private localeDateBaseString = '12/30/1899'; //en-US
  private oaDateBaseString = '12/30/1899'; //en-US
  private oaDateBaseUtc: number;

  constructor(
    PermissionService: PermissionService,
  ) {
    this._permissionService = PermissionService;

    const oaDateBase = new Date(this.oaDateBaseString);

    this.oaDateBaseUtc = Date.UTC(oaDateBase.getFullYear(), oaDateBase.getMonth(), oaDateBase.getDate());
  }

  private getTimezoneOffsetFromBrowser(currentDate: Date, timezoneId: string) {
    const date = currentDate.clone();
    const browserTime = date.setMilliseconds(0); //clear milliseconds part
    const localeDateString = date.toLocaleString('en-US', { timeZone: timezoneId });
    const localeTime = new Date(localeDateString).getTime();

    return (localeTime - browserTime) / (60 * 1000); //in minutes
  }

  public getBrowserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public getEpochDate() {
    return (Number(Date.today()) - Number(new Date(1899, 11, 30))) / 8.64e7;
  }

  public convertDayNumberToDate(dayNumber: number): Date {
    if (dayNumber == null) {
      return null;
    }

    return new Date(this.localeDateBaseString).addDays(dayNumber);
  }

  public convertDayNumberToYMD(dayNumber: number): string {
    return this.convertDayNumberToDate(dayNumber)?.toString('yyyy-MM-dd');
  }

  public convertCalendarDateToYMD(date: Date): string {
    // date is in browser timezone, but treat it as locale timezone
    return date.toString('yyyy-MM-dd');
  }

  public convertDateToLocaleDayNumber(date: Date, timezoneId: string): number {
    //date is in browser need transfer to locale date first
    const localeDateString = date.toLocaleDateString('en-US', { timeZone: timezoneId });
    const localeDate = new Date(localeDateString);
    const localeDateBase = new Date(this.localeDateBaseString);
    const dayNumber =
      (Date.UTC(localeDate.getFullYear(), localeDate.getMonth(), localeDate.getDate()) -
        Date.UTC(localeDateBase.getFullYear(), localeDateBase.getMonth(), localeDateBase.getDate())) /
      (24 * 60 * 60 * 1000);

    return Math.floor(dayNumber);
  }

  public convertLocaleDateToLocaleDayNumber(date: Date): number {
    //date is in local format, but suppose it is a locale date. Normally the date is picked from calendar
    if (date == null) return null;
    const dateString = date.toDateString();
    const localeDate = new Date(dateString);
    const localeDateBase = new Date(this.localeDateBaseString);
    const dayNumber =
      (Date.UTC(localeDate.getFullYear(), localeDate.getMonth(), localeDate.getDate()) -
        Date.UTC(localeDateBase.getFullYear(), localeDateBase.getMonth(), localeDateBase.getDate())) /
      (24 * 60 * 60 * 1000);

    return Math.floor(dayNumber);
  }

  public convertLocaleDateTimeToUtcString(localeDate: Date, timezoneId: string): string {
    // date is in locale format (with browser timezone), need change to browser, then get iso
    const minutes = this.getTimezoneOffsetFromBrowser(localeDate, timezoneId);
    const browserDate = localeDate.addMinutes(-minutes);

    return browserDate.toISOString();
  }

  public convertBrowserDateToLocaleDayNumber(browserDate: Date, timezoneId: string): number {
    //date is a browser date, need change it to locale date first
    const timezoneOffset = this.getTimezoneOffsetFromBrowser(browserDate, timezoneId);
    const localeDate = browserDate.addMinutes(timezoneOffset);
    const localeDateBase = new Date(this.localeDateBaseString);
    const dayNumber =
      (Date.UTC(localeDate.getFullYear(), localeDate.getMonth(), localeDate.getDate()) -
        Date.UTC(localeDateBase.getFullYear(), localeDateBase.getMonth(), localeDateBase.getDate())) /
      (24 * 60 * 60 * 1000);

    return Math.floor(dayNumber);
  }

  // browserDate is a browser date with browser timezone, need change it to locale date first (Note: the timezone of localeDate is still browser date timezone)
  // then compare the 'localeDate' time part with 'localStartTime':
  // - if the time part is less than the 'localStartTime', the 'targetDate' is the previous day, otherwise the 'targetDate' is the local date
  // then compare 'localStartTime' with mid day:
  // - if it is configured in the morning, the 'adjustedTargetDate' is the same as 'targetDate' otherwise 'adjustedTargetDate' is the next day of 'targetDate'
  // then get 'dayNumber' of the 'adjustedTargetDate'
  public convertBrowserDateTimeToLocaleDayNumber(browserDate = new Date()): number {
    const localeTimezoneId = this._permissionService.currentAccount.timezoneId;
    const localStartTimeInMinutes = this._permissionService.currentAccount.irrigationLocalStartTimeInMinutes;
    const localeDateString = browserDate.toLocaleString('en-US', { timeZone: localeTimezoneId });
    const localeDate = new Date(localeDateString);
    const localeDateTimePartInMinutes = localeDate.getHours() * 60 + localeDate.getMinutes();
    const targetDate = new Date(localeDate.getFullYear(), localeDate.getMonth(), localeDate.getDate());

    if (localeDateTimePartInMinutes < localStartTimeInMinutes) {
      targetDate.addDays(-1);
    }

    const adjustedTargetDate = targetDate.clone();
    if (localStartTimeInMinutes > 12 * 60) {
      // midday in minutes is 12*60
      adjustedTargetDate.addDays(1);
    }

    const adjustedTargetDateUtc = Date.UTC(
      adjustedTargetDate.getFullYear(),
      adjustedTargetDate.getMonth(),
      adjustedTargetDate.getDate(),
    );

    const dayNumber = (adjustedTargetDateUtc - this.oaDateBaseUtc) / (24 * 60 * 60 * 1000);

    return Math.floor(dayNumber);
  }

  public convertBrowserDateTimeToLocaleDate(browserDate = new Date()): Date {
    const dayNumber = this.convertBrowserDateTimeToLocaleDayNumber(browserDate);

    return this.convertDayNumberToLocaleDate(dayNumber);
  }

  public convertCalendarDateToLocaleDayNumber(calendarDate: Date): number {
    // date is picked from calendar. it is in browser timezone, but treate it as a locale timezone
    const dateString = calendarDate.toDateString();
    const localeDate = new Date(dateString);
    const localeDateBase = new Date(this.localeDateBaseString);
    const dayNumber =
      (Date.UTC(localeDate.getFullYear(), localeDate.getMonth(), localeDate.getDate()) -
        Date.UTC(localeDateBase.getFullYear(), localeDateBase.getMonth(), localeDateBase.getDate())) /
      (24 * 60 * 60 * 1000);

    return Math.floor(dayNumber);
  }

  public convertDayNumberToLocaleDate(dayNumber: number): Date {
    return new Date(this.localeDateBaseString).addDays(dayNumber);
  }

  public convertBrowserDateToLocaleDate(browserDate: Date, localeTimezoneId: string): Date {
    // browserDate is a browser date with browser timezone
    // return date value is in locale timezone, but the data timezone still is browser tiemzone
    const localeDateString = browserDate.toLocaleString('en-US', { timeZone: localeTimezoneId });

    return new Date(localeDateString);
  }

  public convertYMDToLocaleDate(ymdString: string): Date {
    // ymdString is a locale date in "yyyy-mm-dd" format, convert to locale Date
    const parts = ymdString.split('-').map((a) => parseInt(a));

    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  public convertYMDToLocalMidnight(ymdString: string): Date {
    return new Date(`${ymdString}T00:00:00`);
  }

  //DayNumberService may not be the best place for these, but still date related and used in same controllers
  public getCurrentDate(): Date {
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0); //remove hour part so we are not filtering dates relative to the current time

    return currentDate;
  }

  public daysAgo(days: number): Date {
    return this.getCurrentDate().addDays(-days);
  }

  public endOfToday(): Date {
    const currentDate = new Date();

    currentDate.setHours(23, 59, 59);

    return currentDate;
  }
}

angular.module('fuse').service('DayNumberService', DayNumberService);
