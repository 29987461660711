import * as angular from 'angular';
import { IIdNameItem } from '@common/models/interfaces';
import { UnitTypes, unitSizes } from '@common/enums';
import { SWANConstants } from '@common/SWANConstants';

export interface uomCollection {
  [index: string]: { [size: number]: uomUnit };
}
export class uomUnit {
  unitId: number;
  name: string;
  symbol: string;
  description: string;
  context: string;
  scale: fuse.scaleDto;
  toBase: Function;
  fromBase: Function;
  decimalPlaces: number;
  authAccountId: number;

  constructor(unit: fuse.uomPreferenceDto) {
    if (unit) {
      this.unitId = unit.unitId;
      this.name = unit.symbol;
      this.symbol = unit.symbol;
      this.description = unit.description;
      this.context = unit.context;
      this.scale = unit.scale;
      this.fromBase = new Function('a', unit.fromBaseFormulaJS);
      this.toBase = new Function('a', unit.toBaseFormulaJS);
      this.decimalPlaces = unit.decimalPlaces;
      this.authAccountId = unit.authAccountId;
    }
  }

  // Inverse unit, for eg. $/kg
  public getInverse(): uomUnit {
    const uom = new uomUnit(null);

    uom.unitId = this.unitId;
    uom.name = '/' + this.name;
    uom.description = this.description;
    uom.context = this.context;
    uom.scale = this.scale;
    uom.decimalPlaces = this.decimalPlaces;
    uom.authAccountId = this.authAccountId;
    uom.fromBase = this.toBase;
    uom.toBase = this.fromBase;

    return uom;
  }

  public fromBaseRounded(num: number | string, decimalPlaces = null): number {
    if (num == null || num === '' || isNaN(Number(num))) {
      return null;
    }

    // NOTE: Decimal places < 0 may be used as a display flag, so treat as valid and make positive
    if (decimalPlaces == null || decimalPlaces < 0) {
      decimalPlaces = Math.abs(this.decimalPlaces);
    }

    const ret = Number(Number(this.fromBase(num)).toFixed(decimalPlaces));

    return ret;
  }

  public toBaseWithNulls(num: number | string): number | null {
    if (num == null || num === '' || isNaN(Number(num))) {
      return null;
    }

    const ret = this.toBase(num);

    return ret;
  }

  public fromBaseText(num: number, decimalPlaces = null): string {
    const converted = this.fromBaseRounded(num, decimalPlaces);
    let spacer = ' ';

    if (this.context == UnitTypes.Temperature || this.context == UnitTypes.SugarContent || this.name == '%') {
      spacer = ''; // these use the degrees symbol
    }

    const ret = `${converted}${spacer}${this.name}`;

    return ret;
  }

  public toString(): string {
    return this.name;
  }

  /** Returns unit symbol in (mm) type format. Returns empty string if unit has no symbol (integer value, etc) */
  public bracketedSymbol(): string {
    if (this.name == '') return '';
    return `(${this.name})`;
  }

  public isWeight(): boolean {
    return this.context == UnitTypes.Weight;
  }
}

export class UnitOptions {
  private _unitScales: fuse.unitScaleDto[];

  constructor(unitScales: fuse.unitScaleDto[]) {
    this._unitScales = unitScales;
  }

  public getAllowedUnitScales(baseClass: string, scale: number | string): fuse.unitScaleDto[] {
    if (typeof scale == 'number') {
      const res = this._unitScales.filter((a) => a.context == baseClass && a.scale.id == scale);

      return res;
    } else {
      const res = this._unitScales.filter((a) => a.context == baseClass && a.scale.name == scale);

      return res;
    }
  }

  public baseClassArray() {
    const baseClassArray = this._unitScales.map((a) => {
      return { id: a.baseClassId, name: a.context } as IIdNameItem;
    });

    return baseClassArray.filter((v, i, a) => a.findIndex((b) => b.id == v.id) == i);
  }

  public getScaleFor(baseClassName: string, scaleId: number, symbol: string) {
    return this._unitScales.find((a) => {
      return a.context == baseClassName && a.scale.id == scaleId && a.symbol == symbol;
    });
  }
}

export class UnitOfMeasureService {
  private units: uomCollection = {};

  constructor() {
  }

  // Dummy unit, returns f(a)=a for all functions (no conversion)
  public createDummyUnit(decimalPlaces = 2) {
    const dto = {
      unitId: 0,
      baseClassId: 0,
      context: '',
      unitScaleId: 0,
      scale: { id: 0, name: '' },
      symbol: '',
      decimalPlaces: decimalPlaces,
      toBaseFormulaJS: 'return a',
      fromBaseFormulaJS: 'return a',
    } as fuse.uomPreferenceDto;

    return new uomUnit(dto);
  }

  public setUnits(uomArray: fuse.uomPreferenceDto[]) {
    for (let i = 0; i < uomArray.length; i++) {
      const unit = uomArray[i];

      if (!this.units[unit.context]) {
        this.units[unit.context] = {};
      }

      this.units[unit.context][unit.scale.id] = new uomUnit(unit);
    }
  }

  public fertiliserUnits(units: string, byArea = false) {
    const baseClass = this.fertiliserUnitBase(units, byArea);
    const uom = this.getUnits(baseClass, unitSizes.normal);

    return uom;
  }

  public fertiliserUnitBase(units: string, byArea = false) {
    let baseClass = 'FertVolume';

    if (units == 'kg') {
      baseClass = 'Weight';
    }

    if (byArea) {
      baseClass = baseClass + '/Area';
    }

    return baseClass;
  }

  public convertToBase(unitType: string, size: unitSizes, value: number): number {
    if (!value && value != 0) {
      return null;
    }

    const unit = this.getUnits(unitType, size);

    if (unit == null) {
      return null;
    }

    return unit.toBase(value);
  }

  public getUnitLabel(unitType: string, size: unitSizes): string {
    const unit = this.getUnits(unitType, size);

    if (unit == null) {
      return null;
    }

    return unit.name;
  }

  public getDecimalPlaces(unitType: string, size: unitSizes): number {
    const unit = this.getUnits(unitType, size);

    if (unit == null) {
      return null;
    }

    return unit.decimalPlaces;
  }

  public convertFromBase(unitType: string, size: unitSizes = unitSizes.normal, value: number): number {
    if (value == null) {
      return value;
    }

    if (size == null) {
      size = unitSizes.normal;
    }

    const unit = this.getUnits(unitType, size);

    if (unit == null) {
      return null;
    }

    let num = unit.fromBase(value);

    num = parseFloat(Number(num).toFixed(unit.decimalPlaces));

    return num;
  }

  public convertFromBaseWithDecimal(
    unitType: string,
    size: unitSizes = unitSizes.normal,
    value: number,
    unitDecimal: number,
  ): number {
    if (value == null) {
      return value;
    }

    if (size == null) {
      size = unitSizes.normal;
    }

    const unit = this.getUnits(unitType, size);

    if (unit == null) {
      return null;
    }

    let num = unit.fromBase(value);

    num = parseFloat(Number(num).toFixed(unitDecimal));

    return num;
  }

  public getUnits(unitType: string, size: unitSizes = unitSizes.normal, showWarnings = true): uomUnit {
    const unitCollection = this.units[unitType];

    if (!unitCollection) {
      if (showWarnings) {
        console.log('UnitOfMeasureService: The specified unit context ' + unitType + ' does not exist');
      }

      return null;
    }

    const unit = unitCollection[size];

    if (!unit) {
      if (showWarnings) {
        console.log(
          'UnitOfMeasureService: The specified unit context size ' + size.toString() + ' does not exist on context ' + unitType,
        );
      }

      return null;
    }

    return unit;
  }

  public getUnitCollection(unitType: string): uomUnit[] {
    return Object.keys(this.units[unitType]).map((key) => this.units[unitType][key]);
  }

  public getUnitById(unitId: number): uomUnit {
    if (!unitId) {
      return null;
    }

    const unit = SWANConstants.units.find((a) => a.unitId == unitId);

    if (!unit) {
      return null;
    }

    return {
      unitId: unit.unitId,
      toBase: new Function('a', unit.toBaseFormulaJS),
      fromBase: new Function('a', unit.fromBaseFormulaJS),
    } as uomUnit;
  }

  // Used in converter directives - convenient place to make it available to all
  // Allowed input decimal places limited, and changes on keyboard or mousewheeel up/down are disabled
  public setListeners(element, decimalPlaces) {
    element.on('keydown', (e) => {
      if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {
        e.preventDefault();
      }
    });

    element.on('keyup', (e) => {
      const newVal = Number(element.val()); // + (e.charCode !== 0 ? String.fromCharCode(e.charCode) : ''));

      if (newVal) {
        const modelVal = parseFloat(newVal.toFixed(decimalPlaces));

        if (newVal != modelVal) {
          element.val(modelVal);
        }
      }
    });

    element.on('wheel', (event) => {
      event.preventDefault();
    });
  }
}

angular.module('fuse').service('UnitOfMeasureService', UnitOfMeasureService);
