import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';

export interface IEquipType {
  kind: string;
  name: string;
  id: number;
  iconURL: string;
}

export class SiteService {
  public selectedAsset: Asset;
  public selectedMarker: google.maps.Marker;
  public selectedAreaMarker: any;
  public selectedAssetClass: fuse.assetClassDto;
  public isEditMode: boolean = false;
  public equipments = [] as fuse.assetClassDto[];
  public isSiteGeometryCreated = false;
  public isNewSite = false;
  public siteGeometryArea = 0;

  private _siteMap = null as google.maps.Map;

  public getSiteMap(): google.maps.Map {
    return this._siteMap;
  }

  public setSiteMap(value: google.maps.Map): void {
    this._siteMap = value;
  }
}

angular.module('fuse').service('SiteService', SiteService);
