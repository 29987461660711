import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { ColourSchemeService } from '@services/colour-scheme.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';

declare let ee: any; // Earth Engine js global object

interface EeFilter {
  name: string;
  operator: string;
  value: any;
}

interface EeConfig {
  path: string;
  cloudProperty: string;
  redBand: string;
  greenBand: string;
  blueBand: string;
  nirBand: string;
  swir1Band: string;
  pixelSize: number;
  bandScale: number;
  rgbVisParams: any;
  filters: EeFilter[];
}

export interface ImageInfo {
  date: Date;
  cloud: number;
}

interface VisParams {
  min: number;
  max: number;
  palette: string[];
}

export class HealthIndexService {
  public APItoken: string;
  public earthToken: string;

  public dateSearchDuration: number = 90;

  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _colourSchemeService: ColourSchemeService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _permissionService: PermissionService;

  private initDate: Date;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    ColourSchemeService: ColourSchemeService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    this._http = $http;
    this._q = $q;
    this._colourSchemeService = ColourSchemeService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._permissionService = PermissionService;

    this.getEarthToken();
  }
  // ^^^^^^^^^^^^^^^^^^^^^^^^^ START Health Index Layer ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // Get GEE Auth token
  private getEarthToken(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    //Only re-authenticate every 5 mins
    if (this.earthToken != null && new Date().addMinutes(-5) < this.initDate) {
      defer.resolve();
    } else {
      const params = {
        method: 'GET',
        url: CommonHelper.getApiUrl('earth/token'),
      };
      this._http(params)
        .then((response) => {
          this.earthToken = response.data as string;
          this.earthEngineAuthenticate().then(
            () => {
              this.earthEngineinitialise().then(
                () => {
                  defer.resolve();
                },
                (e) => defer.reject(e),
              );
            },
            (e) => defer.reject(e),
          );
          //this.getEEMap();
        })
        .catch((e) => {
          console.log('Error: ', e);
          defer.reject(e);
        })
        .finally(() => {
          // console.log('Earth token: ' + self.earthToken);
        });
    }
    return defer.promise;
  }

  private earthEngineinitialise(): Promise<void> {
    return new Promise((resolve, reject) => {
      ee.initialize(
        null,
        null,
        () => {
          console.log('Earth Engine initialize success');
          this.initDate = new Date();
          resolve();
        },
        (e) => {
          console.error('Earth Engine initialize failed. ' + e);
          reject();
        },
        null,
      );
    });
  }

  private earthEngineAuthenticate(): Promise<void> {
    return new Promise((resolve) => {
      ee.data.setAuthToken('111181238219497085711', 'Bearer', this.earthToken, 1800, null, () => {
        resolve();
      });
    });
  }

  public createRGBMapLayer(
    eeConfig: string,
    cloud: number,
    clip: boolean,
    clipBounds: fuse.geometry[],
    maxDate: Date,
  ): angular.IPromise<google.maps.ImageMapType> {
    const defer = this._q.defer<google.maps.ImageMapType>();

    this.getEarthToken().then(
      () => {
        const config = JSON.parse(eeConfig) as EeConfig;
        let imagery = this.getImagery(config, cloud, clip, clipBounds, maxDate, this.dateSearchDuration);
        if (config.path.includes('ee-planet')) {
          imagery = imagery.select(['b1', 'b2', 'b3', 'b4']);
        }
        const visParams = config.rgbVisParams ? config.rgbVisParams : {};
        visParams.bands = [config.redBand, config.greenBand, config.blueBand];
        imagery.getMap(visParams, (map, err) => {
          if (map != null) {
            const eeMapOptions = {
              getTileUrl: (tile, zoom) => {
                const url = ['https://earthengine.googleapis.com/v1alpha', map.mapid, 'tiles', zoom, tile.x, tile.y].join('/');
                return url;
              },
            };
            // Create the image layer.
            defer.resolve(new google.maps.ImageMapType(eeMapOptions));
          } else {
            console.error('No suitable imagery could be found.' + err);
            defer.reject();
          }
        });
      },
      (e) => {
        defer.reject(e);
      },
    );
    return defer.promise;
  }

  private groupPixels(min: number, max: number, buckets: number, clip: boolean, clipBounds: fuse.geometry[], img: any) {
    const bandRange = max - min;
    const step = bandRange / buckets;
    let resultImg = ee.Image(0);
    let pixelValueFrom = -100000;
    let pixelValueTo = min + step;
    for (let i = 0; i < buckets; i++) {
      if (i == buckets - 1) {
        pixelValueTo = 100000;
      }
      const mask = img.gte(pixelValueFrom).and(img.lt(pixelValueTo));
      const newPixels = ee.Image(i + 1).mask(mask);
      resultImg = resultImg.add(newPixels.unmask(0));
      pixelValueFrom = pixelValueTo;
      pixelValueTo += step;
    }
    if (clip) {
      if (clipBounds == null) {
        throw new Error('Site shape was not supplied');
      }
      const bounds = ee.FeatureCollection(clipBounds.map((b) => ee.Geometry.Polygon(b.coordinates)));
      resultImg = resultImg.clipToCollection(bounds);
    }
    return resultImg.updateMask(resultImg.neq(0));
  }

  public createHealthIndexMapLayer(
    eeConfig: string,
    index: string,
    cloud: number,
    clip: boolean,
    clipBounds: fuse.geometry[],
    maxDate: Date,
    minValue: number,
    maxValue: number,
  ): angular.IPromise<google.maps.ImageMapType> {
    const defer = this._q.defer<google.maps.ImageMapType>();

    try {
      this.getEarthToken().then(
        () => {
          //console.log('getting createHealthIndexMapLayer image..');
          const config = JSON.parse(eeConfig) as EeConfig;
          const theme = this._colourSchemeService.healthThemes.find((h) => h.healthIndex == index);
          const colours = this._colourSchemeService.getColourPalette(index, theme.reverse);
          const visParams = { min: minValue, max: maxValue, palette: colours } as VisParams;
          const imagery = this.getImagery(config, cloud, clip, clipBounds, maxDate, this.dateSearchDuration);
          let image = this.applyIndexCalculation(imagery, index, config).mosaic();
          if (theme.numberOfColours > 0) {
            //We need to group the pixels into buckets here based on the colour pallete
            image = this.groupPixels(minValue, maxValue, colours.length, clip, clipBounds, image);
            visParams.min = 1;
            visParams.max = colours.length;
          }

          try {
            image.getMap(visParams, (map) => {
              if (map != null) {
                const eeMapOptions = {
                  getTileUrl: (tile, zoom) => {
                    const url = ['https://earthengine.googleapis.com/v1alpha', map.mapid, 'tiles', zoom, tile.x, tile.y].join(
                      '/',
                    );
                    return url;
                  },
                };
                // Create the image layer.
                defer.resolve(new google.maps.ImageMapType(eeMapOptions));
              } else {
                console.error('No suitable imagery could be found.');
                defer.reject();
              }
            });
          } catch (err) {
            console.error('No suitable imagery could be found. ' + err);
            defer.reject();
          }
        },
        (e) => defer.reject(e),
      );
    } catch (e) {
      defer.reject(e);
    }

    return defer.promise;
  }

  public createHealthIndexMapChangeLayer(
    eeConfig: string,
    index: string,
    cloud: number,
    clip: boolean,
    clipBounds: fuse.geometry[],
    date1: Date,
    date2: Date,
    maxSearchDays: number,
    maxValue: number,
  ): angular.IPromise<google.maps.ImageMapType> {
    const defer = this._q.defer<google.maps.ImageMapType>();

    try {
      this.getEarthToken().then(
        () => {
          //console.log('getting createHealthIndexMapChangeLayer image..');
          const config = JSON.parse(eeConfig) as EeConfig;
          const max = maxValue;
          const min = -max;

          const theme = this._colourSchemeService.healthThemes.find((h) => h.healthIndex == 'TEMPORALVARIATION');
          const colours = this._colourSchemeService.getColourPalette('TEMPORALVARIATION', theme.reverse);
          const visParams = { min: min, max: max, palette: colours } as VisParams;

          let image1 = this.getImagery(config, cloud, clip, clipBounds, date1, maxSearchDays);
          image1 = this.applyIndexCalculation(image1, index, config).mosaic();

          let image2 = this.getImagery(config, cloud, clip, clipBounds, date2, maxSearchDays);
          image2 = this.applyIndexCalculation(image2, index, config).mosaic();

          let image3 = image2.subtract(image1);

          if (theme.numberOfColours > 0) {
            //We need to group the pixels into buckets here based on the colour pallete
            image3 = this.groupPixels(min, max, colours.length, clip, clipBounds, image3);
            visParams.min = 1;
            visParams.max = colours.length;
          }

          try {
            image3.getMap(visParams, (map) => {
              if (map != null) {
                const eeMapOptions = {
                  getTileUrl: (tile, zoom) => {
                    const url = ['https://earthengine.googleapis.com/v1alpha', map.mapid, 'tiles', zoom, tile.x, tile.y].join(
                      '/',
                    );
                    return url;
                  },
                };

                // Create the image layer.
                defer.resolve(new google.maps.ImageMapType(eeMapOptions));
              } else {
                defer.reject(this._languageService.instant('PROJ.NO_IMAGE_TRY_CHANGE'));
              }
            });
          } catch (err) {
            defer.reject(this._languageService.instant('PROJ.NO_IMAGES_FOUND'));
          }
        },
        (e) => defer.reject(e),
      );
    } catch (e) {
      defer.reject(e);
    }

    return defer.promise;
  }

  private getImagery(
    eeConfig: EeConfig,
    cloud: number,
    clip: boolean,
    clipBounds: fuse.geometry[],
    date: Date,
    dateSearchDuration: number,
  ): any {

    // Set DATEs from and To.
    const account = this._permissionService.currentAccount;
    const localeDateTo = date.clone().addDays(1).addSeconds(-1);
    const utcDateTo = this._dayNumberService.convertLocaleDateTimeToUtcString(localeDateTo, account.timezoneId);
    const localeDateFrom = localeDateTo.clone().addDays(-dateSearchDuration);
    const utcDateFrom = this._dayNumberService.convertLocaleDateTimeToUtcString(localeDateFrom, account.timezoneId);
    // SENSOR Setting Variables to pass
    let sensorImagery = ee
      .ImageCollection(eeConfig.path)
      .filterDate(utcDateFrom, utcDateTo)
      .filter(ee.Filter.lte(eeConfig.cloudProperty, cloud))
      .filter(ee.Filter.gte(eeConfig.cloudProperty, 0));

    eeConfig.filters?.forEach((filter) => {
      sensorImagery = sensorImagery.filterMetadata(filter.name, filter.operator, filter.value);
    });

    if (clip) {
      if (clipBounds == null) {
        throw new Error('Site shape was not supplied');
      }
      const bounds = ee.FeatureCollection(clipBounds.map((b) => ee.Geometry.Polygon(b.coordinates)));
      sensorImagery = sensorImagery.filterBounds(bounds);
      sensorImagery = sensorImagery.map((image) => {
        return image.clipToCollection(bounds);
      });
    }

    return sensorImagery.sort('system:time_start'); //, false);
  }

  private applyIndexCalculation(imagery: any, index: string, eeConfig: EeConfig): any {
    switch (index) {
      case 'NDVI':
        //(NIR - Red) / (NIR + Red)
        if (eeConfig.nirBand == null || eeConfig.redBand == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          return image.normalizedDifference([eeConfig.nirBand, eeConfig.redBand]);
        });
        break;
      case 'GNDVI':
        //(NIR - Green) / (NIR + Green)
        if (eeConfig.nirBand == null || eeConfig.greenBand == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          return image.normalizedDifference([eeConfig.nirBand, eeConfig.greenBand]);
        });
        break;
      case 'NDMI':
        //(NIR - SWIR 1)/(NIR + SWIR 1)
        if (eeConfig.nirBand == null || eeConfig.swir1Band == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          return image.normalizedDifference([eeConfig.nirBand, eeConfig.swir1Band]);
        });
        break;
      case 'ENDVI':
        //((NIR + Green)-(2*Blue)) / ((NIR + Green)+(2*Blue))
        if (eeConfig.nirBand == null || eeConfig.greenBand == null || eeConfig.blueBand == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          let band1 = image.select(eeConfig.nirBand).add(image.select(eeConfig.greenBand)).rename('B1');
          const band2 = ee.Image(2).multiply(image.select(eeConfig.blueBand)).rename('B2');
          band1 = band1.addBands(band2, ['B2']);
          return band1.normalizedDifference(['B1', 'B2']).rename('ENDVI');
        });
        break;
      case 'SAVI':
        //(1.5(NIR-Red))/(NIR + Red + 0.5)
        if (eeConfig.nirBand == null || eeConfig.redBand == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          const band1 = ee
            .Image(1.5)
            .multiply(image.select(eeConfig.nirBand).subtract(image.select(eeConfig.redBand)))
            .rename('SAVI');
          const band2 = image.select(eeConfig.nirBand).add(image.select(eeConfig.redBand)).add(ee.Image(0.5)).rename('SAVI');
          return band1.divide(band2).rename('SAVI');
        });
        break;
      case 'MSAVI':
        //(2 * NIR + 1 – sqrt ((2 * NIR + 1)2 – 8 * (NIR - R))) / 2
        if (eeConfig.nirBand == null || eeConfig.redBand == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          const imNir = ee.Image(2).multiply(image.select(eeConfig.nirBand)).add(ee.Image(1));
          const imRed = image.select(eeConfig.nirBand).subtract(image.select(eeConfig.redBand)).multiply(ee.Image(8));

          return imNir.subtract(imNir.pow(2).subtract(imRed).sqrt()).divide(ee.Image(2)).rename('ESAVI');
        });
        break;
      case 'ARVI':
        // (NIR – (2 * Red) + Blue) / (NIR + (2 * Red) + Blue)
        if (eeConfig.nirBand == null || eeConfig.redBand == null || eeConfig.blueBand == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          const imRedBlue = image.select(eeConfig.redBand).multiply(2).add(image.select(eeConfig.blueBand));
          const imNir = image.select(eeConfig.nirBand);

          return imNir.subtract(imRedBlue).divide(imNir.add(imRedBlue)).rename('ARVI');
        });
        break;
      case 'SIPI':
        //(NIR – Blue) / (NIR – Red)
        if (eeConfig.nirBand == null || eeConfig.redBand == null || eeConfig.blueBand == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          const imRed = image.select(eeConfig.redBand);
          const imBlue = image.select(eeConfig.blueBand);
          const imNir = image.select(eeConfig.nirBand);

          return imNir.subtract(imBlue).divide(imNir.subtract(imRed)).rename('SIPI');
        });

        break;
      case 'EVI':
        //2.5 * ((NIR – Red) / ((NIR) + (6 * Red) – (7.5 * Blue) + 1))
        if (eeConfig.nirBand == null || eeConfig.redBand == null || eeConfig.blueBand == null) {
          //Error not all bands that are required.
          throw new Error(this._languageService.instant('PROJ.INDEX_NOT_SUPPORTED', { index: index }));
        }
        imagery = imagery.map((image) => {
          const imRed = image.select(eeConfig.redBand);
          const imBlue = image.select(eeConfig.blueBand);
          const imNir = image.select(eeConfig.nirBand);

          return imNir
            .subtract(imRed)
            .divide(imNir.add(imRed.multiply(6)).subtract(imBlue.multiply(7.5)).add(eeConfig.bandScale))
            .multiply(2.5)
            .rename('EVI');
        });

        break;
    }
    return imagery;
  }

  // Used to get latest image tile date at given lat, lng (map center)
  public getImageInfo(
    eeConfig: string,
    cloud: number,
    latMin: number,
    lngMin: number,
    latMax: number,
    lngMax: number,
    maxDate: Date,
    dateSearchDuration: number,
  ): angular.IPromise<ImageInfo> {
    const defer = this._q.defer<ImageInfo>();
    // Date location search criteria
    const eeBounds = ee.Geometry.Rectangle(
      [
        [lngMin, latMin],
        [lngMax, latMax],
      ],
      null,
      false,
    );

    const config = JSON.parse(eeConfig) as EeConfig;

    //Set DATEs from and To.
    const account = this._permissionService.currentAccount;
    const localeDateTo = maxDate.clone().addDays(1).addSeconds(-1);
    const utcDateTo = this._dayNumberService.convertLocaleDateTimeToUtcString(localeDateTo, account.timezoneId);
    const localeDateFrom = localeDateTo.clone().addDays(-dateSearchDuration);
    const utcDateFrom = this._dayNumberService.convertLocaleDateTimeToUtcString(localeDateFrom, account.timezoneId);

    try {
      this.getEarthToken().then(
        () => {
          //console.log('getting getImageInfo image..');
          let ic = ee
            .ImageCollection(config.path)
            .filterDate(utcDateFrom, utcDateTo)
            .filterBounds(eeBounds)
            .filter(ee.Filter.lte(config.cloudProperty, cloud))
            .filter(ee.Filter.gte(config.cloudProperty, 0))
            .sort('system:time_start', false);

          config.filters?.forEach((filter) => {
            ic = ic.filterMetadata(filter.name, filter.operator, filter.value);
          });

          const img = ic.first();
          const dt = ee.Date(img.get('system:time_start')).format();
          const cl = ee.Number(img.get(config.cloudProperty));
          const info = ee.Dictionary({ date: dt, cloud: cl });
          try {
            info.getInfo((i, err) => {
              if (err) {
                defer.resolve(null);
                return;
              }
              if (i != null) {
                const obj = {
                  date: new Date(i.date + 'Z'),
                  cloud: i.cloud,
                } as ImageInfo;
                defer.resolve(obj);
              } else {
                defer.resolve(null);
              }
            });
          } catch (err) {
            defer.reject(err);
          }
        },
        (e) => defer.reject(e),
      );
    } catch (e) {
      defer.reject(e);
    }
    return defer.promise;
  }

  // vvvvvvvvvvvvvvvvvvvvvvvvv   END Helth Index Layer  vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
}

angular.module('fuse').service('HealthIndexService', HealthIndexService);
