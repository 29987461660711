import * as angular from 'angular';
import { CalculatedResults } from '@common/nutrients.interface';
import { LanguageService } from '@services/language.service';
import { NutrientFields } from 'src/app/_DBContext/NutrientFields';

export class SamplePointService {
  private _languageService: LanguageService;

  constructor(LanguageService: LanguageService) {
    this._languageService = LanguageService;
  }

  getCalculatedResults(Noots: NutrientFields): CalculatedResults {
    const calculatedResults = {} as CalculatedResults;

    // Calculated Values
    const Nameq = Noots.Na / 22.99;
    const Cameq = Noots.Ca / 20.04;
    const Mgmeq = Noots.Mg / 12.16;
    const NO3meq = Noots.NO3_N / 14.01;
    const Clmeq = Noots.Cl / 35.45;
    const SO4meq = Noots.S / 16.01;

    let CO3meq = 0;
    let HCO3meq = 0;
    if (Noots.JSONAnalytes) {
      for (let i = 0; i < Noots.JSONAnalytes.length; i++) {
        if (Noots.JSONAnalytes[i].Name == 'Carbonate') {
          CO3meq = Noots.JSONAnalytes[i].Value / 30;
          break;
        }
      }

      for (let j = 0; j < Noots.JSONAnalytes.length; j++) {
        if (Noots.JSONAnalytes[j].Name == 'Bicarbonate') {
          HCO3meq = Noots.JSONAnalytes[j].Value / 61;
          break;
        }
      }
    }

    calculatedResults.residualSodiumCarbonate = CO3meq + HCO3meq - (Cameq + Mgmeq); //(CO3.meq + HCO3.meq)-( Ca.meq + Mg.meq)

    //26.	Sodium Adsorption Ratio (SAR)
    calculatedResults.sodiumAdsorptionRatio = Nameq / Math.sqrt((Cameq + Mgmeq) / 2); //Na.meq /((( Ca.meq + Mg.meq)/2)^0.5)

    //30.	Mg adsorption ratio (MAR)
    //100* Na.meq /( Ca.meq + Na.meq) <-- incorrect
    // Revised by IG 21/07/2017 = 100* Mg.meq /( Ca.meq + Mg.meq)
    calculatedResults.mgAdsorptionRatio = 100 * (Mgmeq / (Cameq + Mgmeq));

    //29.	Permeability Index (PI)
    // 100*( Na.meq +( HCO3.meq ^0.5))/( Ca.meq + Mg.meq + Na.meq)
    calculatedResults.permeabilityIndex = (100 * (Nameq + Math.sqrt(HCO3meq))) / (Cameq + Mgmeq + Nameq);

    // 27.	Adjusted SAR
    // Nameq /(((((10^((4.6629 + (0.6103*(LOG((((1.3477*( Cameq + Mgmeq + Nameq)) + 0.5355)/1000)))) + (0.0844*((LOG((((1.3477*( Ca.meq + Mg.meq + Na.meq)) + 0.5355)/1000)))^2)) + (2*(LOG((Cameq /(2* HCO3meq))))))/3))*0.17758) + Mgmeq)/2)^0.5)
    //this.calculatedResults.adjustedSodiumAdsorptionRatio = Nameq/(Math.pow(((Math.pow(10,((4.6629+(0.6103*(Math.log10(((1.3477*( Cameq+ Mgmeq + Nameq))+0.5355)/1000)))+(0.0844*(Math.pow(Math.log10(((1.3477*( Cameq+ Mgmeq + Nameq))+0.5355)/1000)),2))+(2*(Math.log10((Cameq/(2*HCO3meq))))))/3)*0.17758)+Mgmeq)/2),0.5);

    // formula below used before broken down as below
    // this.calculatedResults.adjustedSodiumAdsorptionRatio = Nameq / (Math.pow(10, (4.6629 + (0.6103 * (Math.log10((((1.3477 * (Cameq + Mgmeq + Nameq)) + 0.5355) / 1000)))) + (0.0844 * (Math.pow(Math.log10((((1.3477 * (Cameq + Mgmeq + Nameq)) + 0.5355) / 1000)), 2)) + (2 * (Math.log10((Cameq / (2 * HCO3meq)))))))));

    /*
			Component	Symbol	Formula
			Sum of Cations:	SumCat	= Cameq+ Mgmeq + Nameq
			Ionic Strength:	IonSt	= ((1.3477*SumCat)+0.5355)/1000
			Log(x):		LX	=(4.6629+(0.6103*(Log10(IonSt)))+(0.0844*((Log10(IonSt))^2))+(2*(Log10((Cameq/(2*HCO3meq))))))/3
			Equilibriated Ca:	EqCa	=Math.pow(10,LX)*0.17758
			Adjusted SAR:	SARadj	=Nameq/(Math.pow((EqCa+Mgmeq)/2),0.5)
		*/

    const SumCat = Cameq + Mgmeq + Nameq;
    //console.log("SumCat :" + SumCat);
    const IonSt = (1.3477 * SumCat + 0.5355) / 1000;
    //console.log("IonSt :" + IonSt);
    const LX =
      (4.6629 + 0.6103 * Math.log10(IonSt) + 0.0844 * Math.pow(Math.log10(IonSt), 2) + 2 * Math.log10(Cameq / (2 * HCO3meq))) / 3;
    //console.log("LX :" + LX);
    const EqCa = Math.pow(10, LX) * 0.17758;
    // console.log("EqCa :" + EqCa);
    const SARadj = Nameq / Math.pow((EqCa + Mgmeq) / 2, 0.5);
    calculatedResults.adjustedSodiumAdsorptionRatio = SARadj;

    //33.	Chloride concentration ratio, meq/L
    //(Cl.meq + NO3.meq)/( CO3.meq + HCO3.meq + SO4.meq + Cl.meq + NO3.meq)
    calculatedResults.chlorideConcentrationRatio = (Clmeq + NO3meq) / (CO3meq + HCO3meq + SO4meq + Clmeq + NO3meq);

    if (calculatedResults.chlorideConcentrationRatio < 4) {
      calculatedResults.textCCR = this._languageService.instant('COMMON.EXCELLENT');
    } else if (calculatedResults.chlorideConcentrationRatio >= 4 && calculatedResults.chlorideConcentrationRatio < 7) {
      calculatedResults.textCCR = this._languageService.instant('COMMON.SUITABLE');
    } else if (calculatedResults.chlorideConcentrationRatio >= 7 && calculatedResults.chlorideConcentrationRatio < 12) {
      calculatedResults.textCCR = this._languageService.instant('COMMON.SLIGHTLY_SUITABLE');
    } else {
      calculatedResults.textCCR = this._languageService.instant('COMMON.NOT_SUITABLE');
    }

    //31.	Total carbonates, meq/L
    //CO3.meq + HCO3.meq
    calculatedResults.totalCarbonates = CO3meq + HCO3meq;

    //32.	Total hardness, mg/L as CaCO3
    //(2.497*Ca.mg/L)+(4.118*Mg.mg/L) note not meq/L
    calculatedResults.totalHardness = 2.497 * Noots.Ca + 4.118 * Noots.Mg;

    return calculatedResults;
  }
}

angular.module('fuse').service('SamplePointService', SamplePointService);
