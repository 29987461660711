import * as angular from 'angular';
import { FullStory } from '@fullstory/browser';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { EnvCodes } from '@indicina/swan-shared/constants/EnvCodes';
import { AppUtils } from '@indicina/swan-shared/utils/AppUtils';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { LocalStorageService } from '@services/local-storage.service';

export type PrivilegeFlags = {
  [key in keyof typeof ApplicationPrivileges as `has${key}`]: boolean;
} & {
  hasIrrigationOverride: boolean;
  hasSiteMapDrawing: boolean;
};

export interface AccountModel {
  accountId: number;
  accountStylings: fuse.AccountStyling[];
  accountSubscriptions: string[];
  currencyCode: string;
  irrigationLocalStartTimeInMinutes: number;
  irrigationOverridePermission: boolean;
  latitude: number;
  logo: string;
  longitude: number;
  name: string;
  offsetMinutes: number;
  permissions: ApplicationPrivileges[];
  role: string;
  startMonth: number;
  timezoneId: string;
}

export interface accountCustomStyling {
  swanPageTitleBar: string;
  swanPageTitleBarNew: string;
  swanWidgetTitleBar: string;
  swanWidgetTitleBarNew: string;
  swanSectionTitleBar: string;
  swanSectionTitleBarNew: string;
  swanButton: string;
  swanButtonNew: string;
  swanPrimaryButton: string;
  swanSecondaryButton: string;
  swanActiveNavigation: string;
  NavigationLogoURL: string;
  DropdownColor: string;
  DropdownColorNew: string;
}

export interface IAuthData {
  fullName: string;
  isPFUser: boolean;
  isSuperUser: string;
  permissions: IAuthPermission[];
  refreshToken: string;
  token: string;
  useRefreshTokens: true;
  userId: string;
  userName: string;
}

export interface IAuthPermission {
  accountStylings: IAuthAccountStyling[];
  accountId: number;
  irrigationLocalStartTimeInMinutes: number;
  name: string;
  offsetMinutes: number;
  permissions: number[];
  role: string;
  timezoneId: string;
}

export interface IAuthAccountStyling {
  AuthAccountId: number;
  CustomStylingId: number;
  StylingCode: string;
  Value: string;
}

export class PermissionService {
  public isPFUser: boolean = false;
  public accountCustomStyling: accountCustomStyling;
  public onNewAccounts: (accounts: AccountModel[]) => void = null;

  public isSuperUser: boolean;
  public fullName: string;
  public email: string;
  public accounts = [] as AccountModel[];
  public accountSearchText: string;

  private _http: angular.IHttpService;
  private _rootScope: angular.IRootScopeService;
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _localStorageService: LocalStorageService;

  // IMPORTANT: Must be initialised only once (to maintain the same object reference).
  // Navigation menu component does not behave as expected on the permissions being refreshed.
  private _mutableApf = {} as PrivilegeFlags;
  private _entityManager: breeze.EntityManager;
  private _currentAccount?: AccountModel = null;

  constructor(
    $http: angular.IHttpService,
    $rootScope: angular.IRootScopeService,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    LocalStorageService: LocalStorageService,
  ) {
    this._http = $http;
    this._rootScope = $rootScope;
    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._localStorageService = LocalStorageService;

    this._entityManager = DataEntityService.manager;
  }

  public get isLocalEnvironment(): boolean {
    return AppSettings.app.envCode === EnvCodes.LOCAL;
  }

  public get isNonProdEnvironment(): boolean {
    return AppSettings.app.envCode !== EnvCodes.PRD;
  }

  public get currentAccount(): AccountModel {
    return this._currentAccount;
  }

  public set currentAccount(account: AccountModel) {
    this._currentAccount = account;
  }

  public get accountId(): number | null {
    return this.currentAccount?.accountId;
  }

  // Application Privilege Flags.
  public get apf(): PrivilegeFlags {
    return this._mutableApf;
  }

  public get isSwanSystemAccount(): boolean {
    return this.accountId === SWANConstants.SystemAccountNumber;
  }

  public reloadAvailableAccounts(): void {
    this._http.get(CommonHelper.getApiUrl('users/permissions')).then((response) => {
      this.setAvailableAccounts(response.data as AccountModel[]);
    });
  }

  public async changeAccount(targetAccount: AccountModel): Promise<void> {
    if (this._dataEntityService.hasFormOrDataChanges) {
      this._state.go(this._state.current.name, { isAttemptToChangeToAccountId: targetAccount.accountId });

      return;
    }

    if (!this.currentAccount || this.currentAccount?.accountId === targetAccount.accountId) {
      return;
    }

    const handleStateTransition = (): void => {
      const targetState = 'app.account.dashboard';

      if (this._state.current.name === targetState) {
        // Since 'targetState' matches the current state, the 'setNavActiveItemForState' must be called explicitly,
        // due to state transitions in this instance do not occur, but we need to ensure that account's custom styling is still applied (if configured).
        CommonHelper.setNavActiveItemForState(targetState, this.accountCustomStyling.swanActiveNavigation);
      } else {
        this._state.go(targetState);
      }
    };

    this.accountSearchText = '';
    this._entityManager.clear(); // Clear breeze entities.

    this.setCurrentAccount(targetAccount)

    await this.loadAccountLogo();

    handleStateTransition();

    this._rootScope.$broadcast('accountChanged');
  }

  public async loadAccountLogo(shouldRefreshLogo = false): Promise<string> {
    if (!this.currentAccount?.accountId) {
      return;
    }

    const updateAccountLogoAndLocalStorage = async (): Promise<void> => {
      const existingAccountLogo = this._localStorageService.getAccountLogo();
      const canUseExistingLogo = !!existingAccountLogo && !shouldRefreshLogo;

      if (canUseExistingLogo) {
        this.currentAccount.logo = existingAccountLogo;

        return;
      }

      const { data: logo } = await this._http.get<fuse.accountLogo>(CommonHelper.getApiUrl(`accounts/${this.currentAccount.accountId}/logo`));
      const newAccountLogo = logo?.base64Logo ?? '';

      this._localStorageService.updateAccountLogo(newAccountLogo);
      this.currentAccount.logo = newAccountLogo;
    };

    await updateAccountLogoAndLocalStorage();
  }

  public setAvailableAccounts(accounts: AccountModel[]): void {
    this.accounts = accounts;

    this.onNewAccounts?.(accounts);
  }

  public setCurrentAccount(account: AccountModel): void {
    this.currentAccount = account;

    AppUtils.updateSelectedAccountName(account.name);

    LocalStorageUtils.updateContextData((context) => {
      context.accountId = account.accountId;
    });

    this.setAccountCustomStyling();
    this.updateFullStoryIdentity();
  }

  public hasPermission(permissions: ApplicationPrivileges | ApplicationPrivileges[]): boolean {
    if (!this.currentAccount) {
      return false;
    }

    const hasPermissions = ArrayUtils.toArray(permissions).every((permission) => this.currentAccount.permissions.includes(permission));

    return hasPermissions;
  }

  public hasSubscription(subscription: string): boolean {
    if (!this.currentAccount) {
      return false;
    }

    const hasSubscription = this.currentAccount.accountSubscriptions.some((sub) => sub === subscription);

    return hasSubscription;
  }

  public setAccountCustomStyling(): void {
    this.accountCustomStyling = this.getAccountCustomSkin();
  }

  public setAccountPermissions(): void {
    // Get only enum names and ignore its values.
    const keys = Object.keys(ApplicationPrivileges).filter((key) => isNaN(Number(key)));

    keys.forEach((key) => {
      const prefixedKey = `has${key}` as keyof PrivilegeFlags; // Add 'has' prefix to the key.

      (this._mutableApf as any)[prefixedKey] = this.hasPermission(ApplicationPrivileges[key]); // Get and assign permission.
    });

    // Special use cases (e.g. calculated, etc.).
    this._mutableApf.hasIrrigationOverride = this.currentAccount?.irrigationOverridePermission ?? false;
    this._mutableApf.hasSiteMapDrawing = this.hasPermission([
      ApplicationPrivileges.SiteMapHealthFull,
      ApplicationPrivileges.SiteMapSoilMoistureFull,
      ApplicationPrivileges.SiteMapWaterSampleFull,
      ApplicationPrivileges.SiteMapWeatherStationFull,
    ]);
  }

  public updateFullStoryIdentity(): void {
    if (this.isLocalEnvironment) {
      return;
    }

    const userFullName = this.fullName;
    const userEmail = this.email;
    const currentAccount = this.currentAccount; // ?? { name: 'Unknown', role: 'N/A' }

    FullStory('setIdentity', {
      properties: {
        displayName: userFullName,
        email: userEmail,
        account: currentAccount.name,
        role: currentAccount.role,
      },
      uid: userEmail,
    });
  }

  private getAccountCustomSkin(): accountCustomStyling {
    const stylings = this.currentAccount?.accountStylings ?? [];
    const result = {} as accountCustomStyling;

    stylings?.forEach((x) => {
      switch (x.StylingCode) {
        case SWANConstants.StylingCode.swanPageTitleBar: {
          result.swanPageTitleBar = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanPageTitleBarNew: {
          result.swanPageTitleBarNew = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanWidgetTitleBar: {
          result.swanWidgetTitleBar = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanWidgetTitleBarNew: {
          result.swanWidgetTitleBarNew = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanSectionTitleBar: {
          result.swanSectionTitleBar = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanSectionTitleBarNew: {
          result.swanSectionTitleBarNew = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanButton: {
          result.swanButton = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanButtonNew: {
          result.swanButtonNew = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanPrimaryButton: {
          result.swanPrimaryButton = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanSecondaryButton: {
          result.swanSecondaryButton = x.Value;
          break;
        }

        case SWANConstants.StylingCode.swanActiveNavigation: {
          result.swanActiveNavigation = x.Value;
          break;
        }

        case SWANConstants.StylingCode.NavigationLogoURL: {
          result.NavigationLogoURL = x.Value;
          break;
        }

        case SWANConstants.StylingCode.DropdownColor: {
          result.DropdownColor = x.Value;
          break;
        }

        case SWANConstants.StylingCode.DropdownColorNew: {
          result.DropdownColorNew = x.Value;
          break;
        }
      }
    });

    return result;
  }
}

angular.module('fuse').service('PermissionService', PermissionService);
