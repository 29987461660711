import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { SWANConstants } from '@common/SWANConstants';
import { FilterService } from '@services/filter.service';
import { LanguageService } from '@services/language.service';
import { ApiService } from '@services/api.service';
import { Asset } from '../_DBContext/Asset';

export class StatusFilter {
  Active: boolean;
  Archived: boolean;
  Suspended: boolean;
}

export class EntityListService {
  assets: Asset[];

  private _apiService: ApiService;
  private _languageService: LanguageService;

  constructor(ApiService: ApiService, LanguageService: LanguageService) {
    this._apiService = ApiService;
    this._languageService = LanguageService;
  }

  /** Returns generic list of asset-type objects. Does not use EntityList functionality. */
  public getAssetList(accountId: number, type: string, after: Function) {
    const assetClass = SWANConstants.assetClasses.find((a) => a.name == type);
    const params = {
      OwnerAccountId: accountId,
      AssetClassId: assetClass.id,
    };

    return this._apiService.getGeneric('data/assetNames', params, after);
  }

  /*
   Returns generic list of objects from backend endpoint.
     - 'asFilterableList' must be 'true' to use EntityList functionality.
  */
  public getEntityList(
    route: string,
    after: (data: any) => void,
    asFilterableList = false,
    params = null,
    filterInstance: FilterService | { filters: any; statusFilters: StatusFilter} = null,
  ): EntityList<any> {
    const after2 = (result) => {
      const isFilterServiceInstance = filterInstance instanceof FilterService;
      const entities = new EntityList(result, isFilterServiceInstance ? filterInstance : null);

      if (!isFilterServiceInstance) {
        if (filterInstance?.filters) {
          entities.filters = filterInstance.filters;
        }

        if (filterInstance?.statusFilters) {
          entities.statusFilters = filterInstance.statusFilters;
        }
      }

      after(entities);
    };

    const successCallback = !asFilterableList ? after : after2;

    return this._apiService.getGeneric(route, params, successCallback);
  }

  /** Retrieve entity list and tag non-active entries by status, eg. '[Name] (Archived)' */
  public getStatusTaggedEntityList(route: string, after: (entityList: EntityList<any>) => void, params = null, existingFilters = null) {
    const after2 = (entityList: EntityList<any>) => {
      entityList.tagNonActive(this._languageService);

      after(entityList);
    };

    return this.getEntityList(route, after2, true, params, existingFilters);
  }
}

angular.module('fuse').service('EntityListService', EntityListService);
