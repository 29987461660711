import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { NumberUtils } from '@indicina/swan-shared/utils/NumberUtils';
import { SiteSettingsCrop } from 'src/app/_DBContext/SiteSettingsCrop';
import { SiteSettingsNutrients } from 'src/app/_DBContext/SiteSettingsNutrients';
import { SiteSettingsSoil } from 'src/app/_DBContext/SiteSettingsSoil';
import { SiteSettingsWater } from 'src/app/_DBContext/SiteSettingsWater';
import { SWANConstants } from '@common/SWANConstants';
import { UnitTypes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { WaterBudgetInfo } from './site-setting.service';

export class MoistureCat {
  Saturated: any;
  TooWet: any;
  Wet: any;
  Optimum: any;
  Dry: any;
  TooDry: any;
}
export class GroupSettingService {
  public currentAssetId: number;
  public isGroupSetting: boolean;
  public siteSoil: SiteSettingsSoil;
  public siteCrop: SiteSettingsCrop;
  public siteWater: SiteSettingsWater;
  public siteNoot: SiteSettingsNutrients;
  public waterBudget: WaterBudgetInfo;

  public moisCatPerc = {} as MoistureCat;
  public moisCatDepth = {} as MoistureCat;
  public moistureCats: string[];
  public RAW: number;
  public sliderSoilMoistureTarget = {};

  public rangeArray = [];
  public rangeViews: {};

  private _$http: angular.IHttpService;
  private _q: angular.IQService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  public soilDepthUnit: uomUnit;
  public translateTolerance: Function;
  public rangeValues: number[];

  public soilCopyProps = [
    'WiltingPoint_pct',
    'FieldCapacity_pct',
    'Saturation_pct',
    'WorkingSoilDepthBottom_mm',
    'WorkingSoilDepthTop_mm',
    'WorkingSoilDepth_mm',

    'MinPenetratingRainfall_24Hours_mm',
    'MaxPenetratingRainfall_24Hours_mm',
    'DrainageCoefficient',
    'SoilMoistureLowerTarget_percent',
    'SoilMoistureUpperTarget_percent',
    'AssetId',
  ];

  public waterCopyProps = [
    'CropWaterArea',
    'CropWettedArea_perc',
    'SystemEfficiencyCoefficient',
    'IrrigationApplicationOneHour_mm',
    'SprinklerLossConstantA',
    'SprinklerLossConstantB',
    'WaterBudgetId',
    'WaterBudgetLowerTarget_percent',
    'WaterBudgetUpperTarget_percent',
    'WaterMonday',
    'WaterTuesday',
    'WaterWednesday',
    'WaterThursday',
    'WaterFriday',
    'WaterSaturday',
    'WaterSunday',
    'IrrigationDays',
    'WaterIntervalDays',
    'WaterIntervalFromDayNumber',
    'AssetId',
  ];

  public cropCopyProps = [
    'CropId',
    'CropGrowthPhase',
    'PhaseCropCoefficient',
    'SlopeOption',
    'PhaseName',
    'TolWetSoil',
    'TolDrySoil',
    'TolLowTemp',
    'TolHighTemp',
    'AssetId',
  ];

  public nootCopyProps = ['CropNutrientArea', 'BudgetFertiliserId', 'Comment', 'AssetId', 'SamplePointList'];

  private _entityManager: breeze.EntityManager;

  constructor(
    $http,
    $q,
    DataEntityService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    this._$http = $http;
    this._q = $q;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.updateValues = this.updateValues.bind(this);

    this.soilDepthUnit = UnitOfMeasureService.getUnits(UnitTypes.SoilDepth);

    // Possibly this should be moved to some sort of general settings-service? Currently used by site settings, group settings, and crop settings
    this.translateTolerance = (value: number): string => {
      const stressFactorList = Object.keys(SWANConstants.CropStressFactor);
      const orderedKeys = [
        SWANConstants.CropStressFactor.Poor,
        SWANConstants.CropStressFactor.Average,
        SWANConstants.CropStressFactor.Good,
      ];

      let i = 0;

      while (value > orderedKeys[i]) {
        i++;
      }

      if (i % 1 != 0) {
        const a = 0;
      }

      const stressFactor = stressFactorList[i];
      const translated = this._languageService.instant('COMMON.SCALE.' + stressFactor);

      return translated;
    };

    this._entityManager = DataEntityService.manager;
  }

  public updateValues(pcts: number[]): void {
    if (!this.siteSoil) {
      return;
    }

    const getSaturationValue = (): number => {
      let val = pcts[2];

      if (val > 70) {
        val = 70; // max value
        this.rangeArray[2].value = val;
      }

      return val;
    }

    if (this.siteSoil.WiltingPoint_pct !== pcts[0]) {
      this.siteSoil.WiltingPoint_pct = pcts[0];
    }

    if (this.siteSoil.FieldCapacity_pct !== pcts[1]) {
      this.siteSoil.FieldCapacity_pct = pcts[1];
    }

    const saturation_pct = getSaturationValue();

    if (this.siteSoil.Saturation_pct !== saturation_pct) {
      this.siteSoil.Saturation_pct = saturation_pct;
    }

    this.updateMoistureCategories();
  }

  // Search an array and return the index of the row in effect at the given day - Array must be sorted by EffectiveDate
  public findEffective(atDate: Date, ssArray: any): number {
    for (let idx = ssArray.length - 1; idx >= 0; idx--) {
      if (ssArray[idx].localDate <= atDate) {
        return idx;
      }
    }

    // not found - return the oldest one
    return 0;
  }

  public getEffectiveNutrientArea(dayNumber: number, siteSettingsNutrients: SiteSettingsNutrients[]) {
    if (!siteSettingsNutrients?.length) {
      return null;
    }

    const siteSettingsNutrientsReversed = ArrayUtils.sortByNumber(siteSettingsNutrients, (x) => x.DayNumber);
    const effectiveSetting = siteSettingsNutrientsReversed.find((a) => a.DayNumber <= dayNumber);
    let nootArea: number;

    if (effectiveSetting) {
      nootArea = effectiveSetting.CropNutrientArea;

      if (nootArea == null || nootArea <= 0) {
        nootArea = effectiveSetting.Asset.Site.Area;
      }
    }

    return nootArea;
  }

  // Search an array and return the index of the row with the given id
  // will also work on an IdName array
  public findById(id: number, ssArray: any): number {
    if (ssArray == undefined) {
      return undefined
    };

    for (let idx = ssArray.length - 1; idx >= 0; idx--) {
      if (ssArray[idx].Id == id) {
        return idx;
      }
    }

    // not found - return the oldest one
    return 0;
  }

  // Copy properties from one object into a new object - props is an array of property name strings
  public copyProps(obj, props: string[]): any {
    const copy = {};
    if (null == obj || 'object' != typeof obj) return obj;

    for (const idx in props) {
      const attr = props[idx];
      if (attr in obj) {
        copy[attr] = obj[attr];
      }
    }
    return copy;
  }

  public loadWaterBudget(pAccountId: number, pSiteId: number, cancellationPromise?: angular.IPromise<any>): void {
    // for any given site, the WaterBudgets are already defined
    // and applied from the WaterBudget pages
    const config = {
      params: { accountId: pAccountId, siteId: pSiteId },
      timeout: cancellationPromise,
    };

    this._$http
      .get<WaterBudgetInfo>(CommonHelper.getApiUrl('user/GetSiteWaterBudgetInfo'), config)
      .then((result) => {
        this.waterBudget = result.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public translateDaysInterval(value: string): string {
    if (value == 'I') {
      return this._languageService.instant('COMMON.INTERVAL');
    } else {
      return this._languageService.instant('COMMON.SPECIFIC_DAYS');
    }
  }

  public getSiteSettingNutrients(assetIds: number[]): angular.IPromise<Swan.SiteSettingsNutrient[]> {
    //This endpoint is currently not working. Swan is returning an empty string with an OK 200 response even though
    //this is an invalid route!
    //Currently when sending a request with a large number (over 200) of siteIDs it is throwing an error
    //Commenting out the parameter for now as this wont change current application behaviour.
    const defer = this._q.defer<Swan.SiteSettingsNutrient[]>();
    const inputParam = null; //{ SiteIDs: assetIds}
    const config = {
      params: inputParam,
    };

    this._$http
      .get<Swan.SiteSettingsNutrient[]>(CommonHelper.getApiUrl('user/GetSiteSettingNutrients/'), config)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getReportNutrientBudget(budgetId: number): angular.IPromise<fuse.reportNutrientBudgetDto[]> {
    const defer = this._q.defer<fuse.reportNutrientBudgetDto[]>();
    const inputParam = { budgetID: budgetId };
    const config = {
      params: inputParam,
    };
    this._$http
      .get<fuse.reportNutrientBudgetDto[]>(CommonHelper.getApiUrl('user/ReportNutrientBudget/'), config)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  public addRequiredSiteSettings(assetId: number, settingType: string): angular.IPromise<any> {
    const defer = this._q.defer();
    const thisDateNow: Date = new Date();

    switch (settingType) {
      case 'Crop':
      case 'Nutrients':
      case 'Soil':
      case 'Water':
        const successCallbackCrop = (data: breeze.QueryResult) => {
          if (data.inlineCount === 0) {
            this._languageService.info('AC.SITE.CREATING', 'COMMON.INFORMATION', { type: settingType });
            const initialValues = this._dataEntityService.getDefault('SiteSettings' + settingType, thisDateNow);
            const entityType = this._entityManager.metadataStore.getEntityType('SiteSettings' + settingType) as breeze.EntityType;
            initialValues.AssetId = assetId;
            const newSetting = entityType.createEntity(initialValues);
            this._entityManager.addEntity(newSetting);
            this._entityManager.saveChanges([newSetting]);

            defer.resolve(newSetting);
          }
        };

        // getSiteSettingsCrop
        // getSiteSettingsNutrient
        // getSiteSettingsSoil
        // getSiteSettingsWater
        breeze.EntityQuery.from('getSiteSettings' + settingType)
          .withParameters({ assetId: assetId })
          .take(0)
          .inlineCount(true)
          .using(this._entityManager)
          .execute()
          .then(successCallbackCrop, null);

        break;

      default:
        break;
    }

    return defer.promise;
  }

  public initRangeArray() {
    // IMPORTANT: Storing the rounded values for re-use (i.e., this.rangeValues),
    // as multiplying some rounded values back by 100, leads to the isues with floating numbers.
    // e.g., The following rounded numbers from (1 - 55) result in the below, however all others are ok.
    //   14: 14.000000000000002
    //   28: 28.000000000000004
    //   29: 28.999999999999996
    //   55: 55.00000000000001
    const pwp = NumberUtils.round(this.siteSoil?.WiltingPoint_pct ?? 0);
    const fc = NumberUtils.round(this.siteSoil?.FieldCapacity_pct ?? 0);
    const sp = NumberUtils.round(this.siteSoil?.Saturation_pct ?? 0);

    this.rangeArray = [
      { name: this._languageService.instant('AC.GROUP.SETTINGS.PWP'), value: 0.01 * pwp },
      { name: this._languageService.instant('AC.GROUP.SETTINGS.FC'), value: 0.01 * fc },
      { name: this._languageService.instant('AC.GROUP.SETTINGS.SP'), value: 0.01 * sp },
    ];

    this.rangeValues = [pwp, fc, sp];

    this.rangeViews = [
      {
        zoom: 0.9,
        step: 1 / 100,
        // visible units for this view, first entry being the major unit
        units: [
          {
            value: 1 / 10,
            // Transform your value into labels | true: value itself | false: none
            labeller: (n: number) => {
              return 100 * n + '%';
            },
          },
          {
            value: 1 / 20,
          },
        ],
      },
    ];
  }

  public initSoilMoistureSlider(isDisabled: boolean) {
    this.sliderSoilMoistureTarget = {
      minValue: this.siteSoil.SoilMoistureLowerTarget_percent,
      maxValue: this.siteSoil.SoilMoistureUpperTarget_percent,
      options: {
        floor: 0,
        ceil: 100,
        hideLimitLabels: true,
        disabled: isDisabled,
        onChange: () => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Soil.DataChanges);
        },
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'model':
              this.siteSoil.SoilMoistureLowerTarget_percent = value;
              return `<b>${this._languageService.instant('COMMON.LOWER_TARGET')}:</b> ${value}%`;
            case 'high':
              this.siteSoil.SoilMoistureUpperTarget_percent = value;
              return `<b>${this._languageService.instant('COMMON.UPPER_TARGET')}:</b> ${value}%`;
            default:
              return value + '%';
          }
        },
      },
    };
  }

  public updateWorkingDepths() {
    const soilSettings = this.siteSoil;
    if (!soilSettings) return;

    // Because this value is the 'high' on the slider, trying to clear this field in text produces strange results
    // setting to 0 produces less confusing behaviour
    if (soilSettings.WorkingSoilDepthBottom_mm == null) soilSettings.WorkingSoilDepthBottom_mm = 0;

    soilSettings['depthsError'] = soilSettings.WorkingSoilDepthTop_mm + 10 > soilSettings.WorkingSoilDepthBottom_mm;
    this.updateMoistureCategories();
  }

  public updateMoistureCategories() {
    const workingDepth = this.siteSoil.WorkingSoilDepthBottom_mm - this.siteSoil.WorkingSoilDepthTop_mm;
    const FC = this.siteSoil.FieldCapacity_pct;
    const PWP = this.siteSoil.WiltingPoint_pct;
    const SP = this.siteSoil.Saturation_pct;

    this.RAW = ((FC - PWP) / 2 / 100) * workingDepth;
    this.moisCatPerc.Saturated = SP;
    this.moisCatPerc.TooWet = (SP + FC) / 2;
    this.moisCatPerc.Wet = FC;
    this.moisCatPerc.Optimum = (FC + PWP) / 2;
    this.moisCatPerc.Dry = (FC + PWP * 3) / 4;
    this.moisCatPerc.TooDry = 0;

    this.moistureCats = Object.keys(this.moisCatPerc);
    this.moistureCats.forEach((key) => {
      this.moisCatDepth[key] = (this.moisCatPerc[key] / 100) * workingDepth;
      this.moisCatPerc[key] = `${this.moisCatPerc[key].toFixed(2)} %`;
    });
  }

  public initWorkingDepthSlider(siteSoil, isDisabled = true) {
    siteSoil.workingSoilDepth_mm ?? 500;

    const sliderWorkingDepth = {
      options: {
        floor: 0,
        ceil: 3000,
        showTicks: 250,
        disabled: isDisabled,
        showSelectionBar: true,
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'model':
              return `<b>${this._languageService.instant('AC.SOILSETTINGS.WORKING_DEPTH_MIN')}:</b> ${this.soilDepthUnit.fromBaseText(
                value,
              )}`;
            case 'high':
              return `<b>${this._languageService.instant('AC.SOILSETTINGS.WORKING_DEPTH_MAX')}:</b> ${this.soilDepthUnit.fromBaseText(
                value,
              )}`;
            default:
              return this.soilDepthUnit.fromBaseText(value);
          }
        },
        onChange: (id, value) => {
          this.updateWorkingDepths();
          this._notifyingService.notify(NotifyEvents.Group.Settings.Soil.DataChanges);
        },
      },
    };
    return sliderWorkingDepth;
  }

  public addNewSetting(sType: string, sArray: any): any {
    let retval = null;
    let props = [];
    let typ = '';

    switch (sType) {
      case 'SoilSettings':
        props = this.soilCopyProps;
        typ = 'SiteSettingsSoil';
        break;
      case 'CropSettings':
        props = this.cropCopyProps;
        typ = 'SiteSettingsCrop';
        break;
      case 'WaterSettings':
        props = this.waterCopyProps;
        typ = 'SiteSettingsWater';
        break;
      case 'NutrientSettings':
        props = this.nootCopyProps;
        typ = 'SiteSettingsNutrients';
        break;
    }

    const obj = this.copyProps(sArray, props);
    const assetType: any = this._entityManager.metadataStore.getEntityType(typ);
    const newRec = assetType.createEntity(obj);

    this._entityManager.addEntity(newRec);

    retval = newRec;

    return retval;
  }
}

angular.module('fuse').service('GroupSettingService', GroupSettingService);
