import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { LanguageService } from '@services/language.service';
import { ApplicationInstance } from '@common/nutrients.interface';

export class ProfileService {
  constructor(LanguageService: LanguageService) {
    this._languageService = LanguageService;
  }

  public chartDataProvider: any = [];
  public selectedNutrientProfile: Asset;
  public reloadNutrientSettingsCounter: number = 0;

  public selectedLegends: string[] = [];
  public flag: number = 0;

  private _languageService: LanguageService;

  private legendclick(graph: any) {
    const chart = graph.chart;

    if (graph.hidden) {
      //and now enabling the graph
      for (var i = 0; i < chart.graphs.length; i++) {
        if (graph.id == chart.graphs[i].id) {
          chart.showGraph(chart.graphs[i]);
          const selected: string = graph.id;
          this.selectedLegends.push(selected);
          break;
        }
      }
    } //currently enabled. so making it hidden.
    else {
      for (var i = 0; i < chart.graphs.length; i++) {
        if (graph.id == chart.graphs[i].id) {
          chart.hideGraph(chart.graphs[i]);
          const index = this.selectedLegends.indexOf(graph.id);
          this.selectedLegends.splice(index, 1);
          break;
        }
      }
    }
  }

  private buildDataProvider(applicationInstances: ApplicationInstance[], xAxisField: string) {
    this.chartDataProvider = [];

    // #region Element Weight Totals

    if (!applicationInstances.length) {
      return;
    }

    const NO3Values = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.NO3_N;
    });
    const sumNO3Values = NO3Values.reduce((total, num) => total + num);

    const NH4Values = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.NH4_N;
    });
    const sumNH4Values = NH4Values.reduce((total, num) => total + num);

    const PValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.P;
    });
    const sumPValues = PValues.reduce((total, num) => total + num);

    const KValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.K;
    });
    const sumKValues = KValues.reduce((total, num) => total + num);

    const CaValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Ca;
    });
    const sumCaValues = CaValues.reduce((total, num) => total + num);

    const MgValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Mg;
    });
    const sumMgValues = MgValues.reduce((total, num) => total + num);

    const SValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.S;
    });
    const sumSValues = SValues.reduce((total, num) => total + num);

    const NaValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Na;
    });
    const sumNaValues = NaValues.reduce((total, num) => total + num);

    const ClValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Cl;
    });
    const sumClValues = ClValues.reduce((total, num) => total + num);

    const CuValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Cu;
    });
    const sumCuValues = CuValues.reduce((total, num) => total + num);

    const FeValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Fe;
    });
    const sumFeValues = FeValues.reduce((total, num) => total + num);

    const MnValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Mn;
    });
    const sumMnValues = MnValues.reduce((total, num) => total + num);

    const ZnValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Zn;
    });
    const sumZnValues = ZnValues.reduce((total, num) => total + num);

    const BValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.B;
    });
    const sumBValues = BValues.reduce((total, num) => total + num);

    const MoValues = applicationInstances.map((aiObj) => {
      return aiObj.nutrientSetting.Noots.Mo;
    });
    const sumMoValues = MoValues.reduce((total, num) => total + num);

    // last week
    const maxFunction = applicationInstances.map((o) => {
      return o.week;
    });
    const maxWeek = maxFunction.reduce((prev, current) => {
      return prev > current ? prev : current;
    });

    // #endregion
    switch (xAxisField) {
      case 'percent':
        for (let j = 0; j <= 100; j++) {
          this.chartDataProvider.push({ percent: j });
        }
        break;

      case 'week':
        for (let j = 0; j <= maxWeek; j++) {
          this.chartDataProvider.push({ week: j });
        }
        break;

      case 'allyear':
        for (let j = 0; j <= 52; j++) {
          this.chartDataProvider.push({ week: j });
        }
        break;

      default:
        break;
    }

    let prevNO3_N = 0;
    let prevNH4_N = 0;
    let prevP = 0;
    let prevK = 0;
    let prevCa = 0;
    let prevMg = 0;
    let prevS = 0;
    let prevNa = 0;
    let prevCl = 0;
    let prevCu = 0;
    let prevFe = 0;
    let prevMn = 0;
    let prevZn = 0;
    let prevB = 0;
    let prevMo = 0;

    for (let i = 0; i < applicationInstances.length; i++) {
      const ai = applicationInstances[i];

      const NO3_N = ai.nutrientSetting.Noots.NO3_N + prevNO3_N;
      const NH4_N = ai.nutrientSetting.Noots.NH4_N + prevNH4_N;
      const P = ai.nutrientSetting.Noots.P + prevP;
      const K = ai.nutrientSetting.Noots.K + prevK;
      const Ca = ai.nutrientSetting.Noots.Ca + prevCa;
      const Mg = ai.nutrientSetting.Noots.Mg + prevMg;
      const S = ai.nutrientSetting.Noots.S + prevS;
      const Na = ai.nutrientSetting.Noots.Na + prevNa;
      const Cl = ai.nutrientSetting.Noots.Cl + prevCl;
      const Cu = ai.nutrientSetting.Noots.Cu + prevCu;
      const Fe = ai.nutrientSetting.Noots.Fe + prevFe;
      const Mn = ai.nutrientSetting.Noots.Mn + prevMn;
      const Zn = ai.nutrientSetting.Noots.Zn + prevZn;
      const B = ai.nutrientSetting.Noots.B + prevB;
      const Mo = ai.nutrientSetting.Noots.Mo + prevMo;

      prevNO3_N += ai.nutrientSetting.Noots.NO3_N;
      prevNH4_N += ai.nutrientSetting.Noots.NH4_N;
      prevP += ai.nutrientSetting.Noots.P;
      prevK += ai.nutrientSetting.Noots.K;
      prevCa += ai.nutrientSetting.Noots.Ca;
      prevMg += ai.nutrientSetting.Noots.Mg;
      prevS += ai.nutrientSetting.Noots.S;
      prevNa += ai.nutrientSetting.Noots.Na;
      prevCl += ai.nutrientSetting.Noots.Cl;
      prevCu += ai.nutrientSetting.Noots.Cu;
      prevFe += ai.nutrientSetting.Noots.Fe;
      prevMn += ai.nutrientSetting.Noots.Mn;
      prevZn += ai.nutrientSetting.Noots.Zn;
      prevB += ai.nutrientSetting.Noots.B;
      prevMo += ai.nutrientSetting.Noots.Mo;

      let cdpIndex = null;
      const v = 10;
      switch (xAxisField) {
        case 'percent':
          cdpIndex = Math.floor(applicationInstances[i].nutrientSetting.Percentage);
          this.chartDataProvider[cdpIndex] = {
            percent: applicationInstances[i].nutrientSetting.Percentage,
            NO3_N: Math.floor((NO3_N / sumNO3Values) * 100),
            NO3W: Math.floor((ai.nutrientSetting.Noots.NO3_N / sumNO3Values) * 100),
            NH4_N: Math.floor((NH4_N / sumNH4Values) * 100),
            NH4W: Math.floor((ai.nutrientSetting.Noots.NH4_N / sumNH4Values) * 100),
            P: Math.floor((P / sumPValues) * 100),
            PW: Math.floor((ai.nutrientSetting.Noots.P / sumPValues) * 100),
            K: Math.floor((K / sumKValues) * 100),
            KW: Math.floor((ai.nutrientSetting.Noots.K / sumKValues) * 100),
            Ca: Math.floor((Ca / sumCaValues) * 100),
            CaW: Math.floor((ai.nutrientSetting.Noots.Ca / sumCaValues) * 100),
            Mg: Math.floor((Mg / sumMgValues) * 100),
            MgW: Math.floor((ai.nutrientSetting.Noots.Mg / sumMgValues) * 100),
            S: Math.floor((S / sumSValues) * 100),
            SW: Math.floor((ai.nutrientSetting.Noots.S / sumSValues) * 100),
            Na: Math.floor((Na / sumNaValues) * 100),
            NaW: Math.floor((ai.nutrientSetting.Noots.Na / sumNaValues) * 100),
            Cl: Math.floor((Cl / sumClValues) * 100),
            ClW: Math.floor((ai.nutrientSetting.Noots.Cl / sumClValues) * 100),
            Cu: Math.floor((Cu / sumCuValues) * 100),
            CuW: Math.floor((ai.nutrientSetting.Noots.Cu / sumCuValues) * 100),
            Fe: Math.floor((Fe / sumFeValues) * 100),
            FeW: Math.floor((ai.nutrientSetting.Noots.Fe / sumFeValues) * 100),
            Mn: Math.floor((Mn / sumMnValues) * 100),
            MnW: Math.floor((ai.nutrientSetting.Noots.Mn / sumMnValues) * 100),
            Zn: Math.floor((Zn / sumZnValues) * 100),
            ZnW: Math.floor((ai.nutrientSetting.Noots.Zn / sumZnValues) * 100),
            B: Math.floor((B / sumBValues) * 100),
            BW: Math.floor((ai.nutrientSetting.Noots.B / sumBValues) * 100),
            Mo: Math.floor((Mo / sumMoValues) * 100),
            MoW: Math.floor((ai.nutrientSetting.Noots.Mo / sumMoValues) * 100),
          };
          break;

        case 'week':
          cdpIndex = Math.floor((applicationInstances[i].nutrientSetting.Percentage / 100) * maxWeek);
          this.chartDataProvider[cdpIndex] = {
            week: Math.floor((applicationInstances[i].nutrientSetting.Percentage / 100) * maxWeek),
            NO3_N: Math.floor((NO3_N / sumNO3Values) * 100),
            NO3W: Math.floor((ai.nutrientSetting.Noots.NO3_N / sumNO3Values) * 100),
            NH4_N: Math.floor((NH4_N / sumNH4Values) * 100),
            NH4W: Math.floor((ai.nutrientSetting.Noots.NH4_N / sumNH4Values) * 100),
            P: Math.floor((P / sumPValues) * 100),
            PW: Math.floor((ai.nutrientSetting.Noots.P / sumPValues) * 100),
            K: Math.floor((K / sumKValues) * 100),
            KW: Math.floor((ai.nutrientSetting.Noots.K / sumKValues) * 100),
            Ca: Math.floor((Ca / sumCaValues) * 100),
            CaW: Math.floor((ai.nutrientSetting.Noots.Ca / sumCaValues) * 100),
            Mg: Math.floor((Mg / sumMgValues) * 100),
            MgW: Math.floor((ai.nutrientSetting.Noots.Mg / sumMgValues) * 100),
            S: Math.floor((S / sumSValues) * 100),
            SW: Math.floor((ai.nutrientSetting.Noots.S / sumSValues) * 100),
            Na: Math.floor((Na / sumNaValues) * 100),
            NaW: Math.floor((ai.nutrientSetting.Noots.Na / sumNaValues) * 100),
            Cl: Math.floor((Cl / sumClValues) * 100),
            ClW: Math.floor((ai.nutrientSetting.Noots.Cl / sumClValues) * 100),
            Cu: Math.floor((Cu / sumCuValues) * 100),
            CuW: Math.floor((ai.nutrientSetting.Noots.Cu / sumCuValues) * 100),
            Fe: Math.floor((Fe / sumFeValues) * 100),
            FeW: Math.floor((ai.nutrientSetting.Noots.Fe / sumFeValues) * 100),
            Mn: Math.floor((Mn / sumMnValues) * 100),
            MnW: Math.floor((ai.nutrientSetting.Noots.Mn / sumMnValues) * 100),
            Zn: Math.floor((Zn / sumZnValues) * 100),
            ZnW: Math.floor((ai.nutrientSetting.Noots.Zn / sumZnValues) * 100),
            B: Math.floor((B / sumBValues) * 100),
            BW: Math.floor((ai.nutrientSetting.Noots.B / sumBValues) * 100),
            Mo: Math.floor((Mo / sumMoValues) * 100),
            MoW: Math.floor((ai.nutrientSetting.Noots.Mo / sumMoValues) * 100),
          };
          break;

        default:
          break;
      }
    }

    switch (xAxisField) {
      case 'percent':
        this.chartDataProvider[101] = {
          percent: 100,
          NO3_N: (prevNO3_N == 0 ? 1 : prevNO3_N / sumNO3Values) * 100,
          NH4_N: (prevNH4_N == 0 ? 1 : prevNH4_N / sumNH4Values) * 100,
          P: (prevP == 0 ? 1 : prevP / sumPValues) * 100,
          K: (prevK == 0 ? 1 : prevK / sumKValues) * 100,
          Ca: (prevCa == 0 ? 1 : prevCa / sumCaValues) * 100,
          Mg: (prevMg == 0 ? 1 : prevMg / sumMgValues) * 100,
          S: (prevS == 0 ? 1 : prevS / sumSValues) * 100,
          Na: (prevNa == 0 ? 1 : prevNa / sumNaValues) * 100,
          Cl: (prevCl == 0 ? 1 : prevCl / sumClValues) * 100,
          Cu: (prevCu == 0 ? 1 : prevCu / sumCuValues) * 100,
          Fe: (prevFe == 0 ? 1 : prevFe / sumFeValues) * 100,
          Mn: (prevMn == 0 ? 1 : prevMn / sumMnValues) * 100,
          Zn: (prevZn == 0 ? 1 : prevZn / sumZnValues) * 100,
          B: (prevB == 0 ? 1 : prevB / sumBValues) * 100,
          Mo: (prevMo == 0 ? 1 : prevMo / sumMoValues) * 100,
        };
        break;

      case 'week':
        this.chartDataProvider[maxWeek] = {
          week: maxWeek,
          NO3_N: (prevNO3_N == 0 ? 1 : prevNO3_N / sumNO3Values) * 100,
          NH4_N: (prevNH4_N == 0 ? 1 : prevNH4_N / sumNH4Values) * 100,
          P: (prevP == 0 ? 1 : prevP / sumPValues) * 100,
          K: (prevK == 0 ? 1 : prevK / sumKValues) * 100,
          Ca: (prevCa == 0 ? 1 : prevCa / sumCaValues) * 100,
          Mg: (prevMg == 0 ? 1 : prevMg / sumMgValues) * 100,
          S: (prevS == 0 ? 1 : prevS / sumSValues) * 100,
          Na: (prevNa == 0 ? 1 : prevNa / sumNaValues) * 100,
          Cl: (prevCl == 0 ? 1 : prevCl / sumClValues) * 100,
          Cu: (prevCu == 0 ? 1 : prevCu / sumCuValues) * 100,
          Fe: (prevFe == 0 ? 1 : prevFe / sumFeValues) * 100,
          Mn: (prevMn == 0 ? 1 : prevMn / sumMnValues) * 100,
          Zn: (prevZn == 0 ? 1 : prevZn / sumZnValues) * 100,
          B: (prevB == 0 ? 1 : prevB / sumBValues) * 100,
          Mo: (prevMo == 0 ? 1 : prevMo / sumMoValues) * 100,
        };
        break;

      default:
        break;
    }
  }

  public getChart(chartName: string, applicationInstances: ApplicationInstance[], xAxisField: string, showAll: boolean) {
    this.buildDataProvider(applicationInstances, xAxisField);

    const nutrient: string[] = [
      'NO3_N',
      'NH4_N',
      'P',
      'K',
      'S',
      'Ca',
      'Mg',
      'Na',
      'Cl',
      'Cu',
      'Fe',
      'Mn',
      'Zn',
      'B',
      'Mo',
      'NO3W',
      'NH4W',
      'PW',
      'KW',
      'SW',
      'CaW',
      'MgW',
      'NaW',
      'ClW',
      'CuW',
      'FeW',
      'MnW',
      'ZnW',
      'BW',
      'MoW',
    ];

    const nutrienttitles: string[] = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'NO3_',
      'NH4_',
      'P_',
      'K_',
      'S_',
      'Ca_',
      'Mg_',
      'Na_',
      'Cl_',
      'Cu_',
      'Fe_',
      'Mn_',
      'Zn_',
      'B_',
      'Mo_',
    ];

    const nutrientgraphcolor: string[] = [
      '#66ffff',
      '#d62929',
      '#004d99',
      '#e67300',
      '#248f24',
      '#ff884d',
      '#ac3973',
      '#5c5c8a',
      '#39ac73',
      '#0086b3',
      '#8a8a5c',
      '#248f24',
      '#ff661a',
      '#206020',
      '#3939ac',
      '#70db70',
      '#961d1d',
      '#4da6ff',
      '#994d00',
      ' #47d147',
      '#ff884d',
      '#bf4080',
      '#7575a3',
      '39ac73',
      '#00ace6',
      '#999966',
      '#33cc33',
      '#ff884d',
      '#2d862d',
      '#5353c6',
    ];

    const graphArry = [] as AmCharts.AmGraph[];
    let showlegend: boolean = true;

    if (showAll) {
      for (let i = 0; i < nutrient.length; i++) {
        const graph = 'AmGraph-' + (i + 1);
        const index = this.selectedLegends.indexOf(graph);
        if (index < 0) this.selectedLegends.push(graph);
      }
      this.flag = 1;
    } else {
      if (this.selectedLegends.length && this.flag === 1) {
        this.selectedLegends.length = 0;
        this.flag = 0;
        for (let i = 0; i <= 4; i++) {
          const graph = 'AmGraph-' + (i + 1);
          this.selectedLegends.push(graph);
        }
      } else if (!this.selectedLegends.length) {
        for (let i = 0; i <= 4; i++) {
          const graph = 'AmGraph-' + (i + 1);
          const index = this.selectedLegends.indexOf(graph);
          if (index < 0) this.selectedLegends.push(graph);
        }
      }
    }

    for (let i = 0; i < nutrient.length; i++) {
      showlegend = true;
      const graph = {} as AmCharts.AmGraph;
      graph.balloonText = nutrient[i] + ':[[' + nutrient[i] + ']]';
      graph.id = 'AmGraph-' + (i + 1);

      graph.valueField = nutrient[i];
      if (i <= 14) {
        graph.bullet = 'round';
        graph.lineThickness = 2;
        graph.bulletSize = 6;
        graph.type = 'line';
        graph.fillAlphas = 0.05;
        graph.valueAxis = 'ValueAxis-1' as any;
        graph.title = nutrient[i];
      } else {
        graph.fillAlphas = 0.8;
        graph.type = 'column';
        graph.lineAlpha = 0.2;
        graph.valueAxis = 'ValueAxis-2' as any;
        graph.title = nutrienttitles[i];
      }
      if (this.selectedLegends?.length) {
        const index = this.selectedLegends.indexOf(graph.id);
        if (index >= 0) {
          showlegend = false;
        } else {
          showlegend = true;
        }
      }

      graph.hidden = showlegend;
      graph.fillColors = nutrientgraphcolor[i];
      graphArry.push(graph);
    }

    return AmCharts.makeChart(chartName, {
      type: 'serial',
      theme: 'light',
      marginTop: 10,
      marginRight: 100,
      columnSpacing: 10,
      dataProvider: this.chartDataProvider,
      valueAxes: [
        {
          id: 'ValueAxis-1',
          axisAlpha: 0,
          position: 'left',
          minimum: 0,
          maximum: 100,
          title: this._languageService.instant('NUTR.PROF.CUMULATED_ELEMENT'),
        },
        {
          id: 'ValueAxis-2',
          dashLength: 1,
          position: 'right',
          title: this._languageService.instant('NUTR.PROF.PHASE_WEIGHTAGE'),
        },
      ],
      graphs: graphArry,
      chartCursor: {
        categoryBalloonDateFormat: 'YYYY',
        cursorAlpha: 0,
        valueLineEnabled: true,
        valueLineBalloonEnabled: true,
        valueLineAlpha: 0.5,
        fullWidth: true,
      },
      dataDateFormat: 'YYYY',
      categoryField: xAxisField,
      categoryAxis: [
        {
          minPeriod: 'YYYY',
          parseDates: false,
          position: 'top',
          minorGridAlpha: 0.1,
          minorGridEnabled: false,
          title: this._languageService.instant('NUTR.PROF.NUTRIENT_UPTAKE_PHASES'),
        },
      ],
      legend: {
        useGraphSettings: true,
        position: 'bottom',
        clickMarker: this.legendclick.bind(this),
        clickLabel: this.legendclick.bind(this),
      },
    }) as AmCharts.AmSerialChart;
  }
}

angular.module('fuse').service('ProfileService', ProfileService);
