import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { CommonHelper } from '@common/helpers/CommonHelper';

export class AccountsService {
  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _ngFileUpload: any;
  private _languageService: LanguageService;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    Upload: any,
    LanguageService: LanguageService,
  ) {
    this._q = $q;
    this._http = $http;
    this._ngFileUpload = Upload;
    this._languageService = LanguageService;
  }

  public getAllAccounts(): angular.IPromise<fuse.ApplicationAccountBasic[]> {
    const defer = this._q.defer<fuse.ApplicationAccountBasic[]>();

    this._http
      .get<fuse.ApplicationAccountBasic[]>(CommonHelper.getApiUrl('accounts'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

    // Gets the list of account users
  public getAccountUsers(): angular.IPromise<fuse.AccountUser[]> {
    const defer = this._q.defer<fuse.AccountUser[]>();

    this._http
      .get(CommonHelper.getApiUrl('accounts/users'))
      .then((result) => {
        defer.resolve(result.data as fuse.AccountUser[]);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  // POST - create
  public createAccountUser(user: fuse.AccountUser): angular.IPromise<fuse.httpResultDto> {
    const defer = this._q.defer<fuse.httpResultDto>();

    this._http.post(CommonHelper.getApiUrl('accounts/users'), user).then(
      (result) => {
        defer.resolve(result.data as fuse.httpResultDto);
      },
      (error) => {
        defer.reject(error);
      },
    );

    return defer.promise;
  }

  // PUT - update
  public updateAccountUser(user: fuse.AccountUser): angular.IPromise<fuse.AccountUser> {
    const defer = this._q.defer<fuse.AccountUser>();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/' + user.AuthAccountId + '/users'), user)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  // DELETE
  public removeUserFromAccount(user: fuse.AccountUser): angular.IPromise<fuse.httpResultDto> {
    const defer = this._q.defer<fuse.httpResultDto>();

    this._http
      .delete(CommonHelper.getApiUrl('accounts/users/' + user.Id))
      .then((res) => {
        defer.resolve(res.data as fuse.httpResultDto);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getAccountEndpoint<T>(address: string, params: {} = null) {
    const defer = this._q.defer<T>();

    this._http
      .get<any>(CommonHelper.getApiUrl('accounts/' + address), params)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public postAccountEndpoint(address: string, params: {} = null) {
    const defer = this._q.defer();

    this._http
      .post<any>(CommonHelper.getApiUrl('accounts/' + address), params)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public deleteAccountEndpoint(address: string, params: {} = null) {
    const defer = this._q.defer();

    this._http
      .delete<any>(CommonHelper.getApiUrl('accounts/' + address), params)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getCustomUnits(): angular.IPromise<fuse.customUnitDto[]> {
    return this.getAccountEndpoint<fuse.customUnitDto[]>('customUnits');
  }

  public getUnitContexts(): angular.IPromise<fuse.unitBaseContext[]> {
    return this.getAccountEndpoint<fuse.unitBaseContext[]>('unitContexts');
  }

  public saveCustomUnits(unit: fuse.customUnitDto) {
    return this.postAccountEndpoint('saveCustomUnits', unit);
  }

  public deleteCustomUnit(unit: fuse.customUnitDto): angular.IPromise<any> {
    return this.deleteAccountEndpoint(`deleteCustomUnits/${unit.unitId}`, null);
  }

  public downloadLogs(
    accountId: number,
    dateFrom: string,
    dateTo: string,
    minSeverity: number,
    maxRecords: number,
  ): angular.IPromise<any> {
    const inputParam = {
      accountId: accountId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      minSeverity: minSeverity,
      maxRecords: maxRecords,
    };
    const config = {
      params: inputParam,
      responseType: 'arraybuffer',
    };

    const defer = this._q.defer<Swan.SysMessageLogCSV[]>();

    this._http
      .get<Swan.SysMessageLogCSV[]>(CommonHelper.getApiUrl('accounts/DownloadLogs/'), config)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getLogs(
    accountId: number,
    dateFrom: string,
    dateTo: string,
    minSeverity: number,
    maxRecords: number,
  ): angular.IPromise<Swan.SysMessageLog[]> {
    const inputParam = {
      accountId: accountId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      minSeverity: minSeverity,
      maxRecords: maxRecords,
    };
    const config = {
      params: inputParam,
    };

    const defer = this._q.defer<Swan.SysMessageLog[]>();

    this._http
      .get<Swan.SysMessageLog[]>(CommonHelper.getApiUrl('accounts/Logs/'), config)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getAllSystemAccounts(): angular.IPromise<fuse.accountDetail[]> {
    const defer = this._q.defer<fuse.accountDetail[]>();

    this._http
      .get<fuse.accountDetail[]>(CommonHelper.getApiUrl('accounts/systemaccounts'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getAllCountries(): angular.IPromise<string[]> {
    const defer = this._q.defer<string[]>();

    this._http
      .get<string[]>(CommonHelper.getApiUrl('accounts/countries'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getApis(): angular.IPromise<string[]> {
    const defer = this._q.defer<string[]>();

    this._http
      .get<string[]>(CommonHelper.getApiUrl('accounts/apis'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getApiPartners(): angular.IPromise<string[]> {
    const defer = this._q.defer<string[]>();

    this._http
      .get<string[]>(CommonHelper.getApiUrl('accounts/apipartners'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getPermissionTypes(): angular.IPromise<string[]> {
    const defer = this._q.defer<string[]>();

    this._http
      .get<string[]>(CommonHelper.getApiUrl('accounts/apipermissiontypes'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getStylingCategories(): angular.IPromise<fuse.stylingCategory[]> {
    const defer = this._q.defer<fuse.stylingCategory[]>();

    this._http
      .get<fuse.stylingCategory[]>(CommonHelper.getApiUrl('accounts/stylingCategories'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getAllTimeZones(): angular.IPromise<fuse.SystemTimeZone[]> {
    const defer = this._q.defer<fuse.SystemTimeZone[]>();

    this._http
      .get<fuse.SystemTimeZone[]>(CommonHelper.getApiUrl('accounts/timezones'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getAllSubscriptions(): angular.IPromise<fuse.subscriptionDto[]> {
    const defer = this._q.defer<fuse.subscriptionDto[]>();

    this._http
      .get<fuse.subscriptionDto[]>(CommonHelper.getApiUrl('accounts/subscriptions'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getSystemAccountById(id: number): angular.IPromise<fuse.accountDetail> {
    const defer = this._q.defer<fuse.accountDetail>();

    this._http
      .get<fuse.accountDetail>(CommonHelper.getApiUrl('accounts/systemaccounts/' + id))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getSiteIrrigationInfo(
    siteId: number,
    cancellationPromise?: angular.IPromise<any>,
  ): angular.IPromise<fuse.sitePlanProfileDto> {
    const defer = this._q.defer<fuse.sitePlanProfileDto>();

    this._http
      .get<fuse.sitePlanProfileDto>(CommonHelper.getApiUrl('site/getSiteIrrigationPlanInfo/' + siteId), { timeout: cancellationPromise })
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getLogo(accountId: number): angular.IPromise<fuse.accountLogo> {
    const defer = this._q.defer<fuse.accountLogo>();

    this._http
      .get<any>(CommonHelper.getApiUrl('accounts/' + accountId + '/logo/'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public uploadLogo(accountId: number, attachmentFile: any): angular.IPromise<any> {
    const defer = this._q.defer();

    this._ngFileUpload
      .upload({
        url: CommonHelper.getApiUrl('accounts/' + accountId + '/logo/'),
        data: { file: attachmentFile },
      })
      .then(() => {
        defer.resolve();
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public removeLogo(accountId: number): angular.IPromise<any> {
    const defer = this._q.defer();

    this._http
      .delete<any>(CommonHelper.getApiUrl('accounts/' + accountId + '/logo/'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public createSystemAccount(account: fuse.accountDetail): angular.IPromise<any> {
    const defer = this._q.defer<fuse.accountDetail>();

    this._http
      .post<any>(CommonHelper.getApiUrl('accounts/systemaccounts'), account)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        if (error.status == 409) {
          if (error.data == 'There is an existing short name.') {
            this._languageService.error('ADM.ACCOUNTS.SHORT_NAME_TAKEN');
          } else if (error.data == 'There is an existing account name.') {
            this._languageService.error('ADM.ACCOUNTS.NAME_TAKEN');
          }
        }
        defer.reject(error);
      });

    return defer.promise;
  }

  public updateTimings(id: number, accountTiming: fuse.AccountTimings): angular.IPromise<any> {
    const defer = this._q.defer<fuse.AccountTimings>();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/timings/' + id), accountTiming)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public updateSystemAccount(account: fuse.accountDetail): angular.IPromise<any> {
    const defer = this._q.defer<fuse.accountDetail>();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/systemaccounts'), account)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public addExternalApi(externalApiDto: fuse.externalApiDto): angular.IPromise<fuse.httpResultDto> {
    const defer = this._q.defer<fuse.httpResultDto>();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/addexternalapi'), externalApiDto)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getExternalApiList(accountId: number): angular.IPromise<fuse.externalApiDto[]> {
    const defer = this._q.defer<fuse.externalApiDto[]>();

    this._http
      .get<any>(CommonHelper.getApiUrl('accounts/getexternalapilist/' + accountId))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public deleteExternalApi(externalApiId: number): angular.IPromise<fuse.httpResultDto> {
    const defer = this._q.defer<fuse.httpResultDto>();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/deleteexternalapi'), externalApiId)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getScheduledSites(accountId: number): angular.IPromise<fuse.ScheduledSite[]> {
    const defer = this._q.defer<fuse.ScheduledSite[]>();

    this._http
      .get<any>(CommonHelper.getApiUrl('accounts/getscheduledsites/' + accountId))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public regenerateAccessKey(apiPermissionId: number): angular.IPromise<fuse.httpResultDto> {
    const defer = this._q.defer<fuse.httpResultDto>();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/regenerateaccesskey'), apiPermissionId)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public irrigationOptimise(model: fuse.irrigationOptimiseDto): angular.IPromise<fuse.irrigationOptimiseDto> {
    const defer = this._q.defer<fuse.irrigationOptimiseDto>();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/irrigationOptimise'), model)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getIrrigationOptimiseProgress(model: fuse.irrigationOptimiseProgressDto): angular.IPromise<fuse.optimisingProgressDetails> {
    const defer = this._q.defer<fuse.optimisingProgressDetails>();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/getIrrigationOptimiseProgress'), model)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getPrivileges(): angular.IPromise<fuse.privilegeDto[]> {
    const defer = this._q.defer<fuse.privilegeDto[]>();

    this._http
      .get<any>(CommonHelper.getApiUrl('accounts/getPrivileges/'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getRolesPrivileges(): angular.IPromise<fuse.rolePrivilegeDto[]> {
    const defer = this._q.defer<fuse.rolePrivilegeDto[]>();

    this._http
      .get<any>(CommonHelper.getApiUrl('accounts/getRolesPrivileges/'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public updateRolePrivileges(rolePrivilege: fuse.rolePrivilegeDto): angular.IPromise<any> {
    const defer = this._q.defer();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/updateRolePrivileges'), rolePrivilege)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getSubscriptionsPrivileges(): angular.IPromise<fuse.subscriptionPrivilegeDto[]> {
    const defer = this._q.defer<fuse.subscriptionPrivilegeDto[]>();

    this._http
      .get<any>(CommonHelper.getApiUrl('accounts/getSubscriptionsPrivileges/'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public updateSubscriptionPrivileges(subscriptionPrivilege: fuse.subscriptionPrivilegeDto): angular.IPromise<any> {
    const defer = this._q.defer();

    this._http
      .put<any>(CommonHelper.getApiUrl('accounts/updateSubscriptionPrivileges'), subscriptionPrivilege)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public recalculateSMB(accountId: number, recalculateSMBDetail: fuse.recalculateSMBDetailDto): angular.IPromise<any> {
    const defer = this._q.defer<fuse.accountDetail>();

    this._http
      .post<any>(CommonHelper.getApiUrl('accounts/' + accountId + '/recalculatesmb'), recalculateSMBDetail)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getActiveSitesStartDate(accountId: number): angular.IPromise<any> {
    const defer = this._q.defer<fuse.accountDetail>();

    this._http
      .get<any>(CommonHelper.getApiUrl('accounts/' + accountId + '/getActiveSiteStartDate'))
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }
}

angular.module('fuse').service('AccountsService', AccountsService);
