import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { SiteSettingsCrop } from 'src/app/_DBContext/SiteSettingsCrop';
import { SiteSettingsNutrients } from 'src/app/_DBContext/SiteSettingsNutrients';
import { SiteSettingsWater } from 'src/app/_DBContext/SiteSettingsWater';

export class IdName {
  Id: number;
  Name: string;
}

export class IdNameArea {
  Id: number;
  Name: string;
  Area: number;
}

// 5912 -Information on WaterBudgets
export class WaterBudgetInfo {
  Id: number;
  Name: string;
  DayNumber: number;
  AppliedToSitesWhen: Date;
}

export class SiteSettingService {
  public currentAssetId: number;
  public waterBudget: WaterBudgetInfo;
  public haveWeather: boolean = false;
  public isGroupSetting: boolean = false;
  // public siteSoil: SiteSettingsSoil; - moved to GroupSettingService, should do same for props below
  public siteCrop: SiteSettingsCrop;
  public siteWater: SiteSettingsWater;
  public siteNoot: SiteSettingsNutrients;
  public selectedSamplePoints: number[] = [];
  public SampleTotPerce: number;

  private _$http: angular.IHttpService;
  private _q: angular.IQService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;

  private _entityManager: breeze.EntityManager;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
  ) {
    this._$http = $http;
    this._q = $q;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;

    this.haveWeather = false;

    this._entityManager = DataEntityService.manager;
  }

  // Search an array and return the index of the row in effect at the given day - Array must be sorted by EffectiveDate
  public findEffective(atDate: Date, ssArray: any): number {
    for (let idx = ssArray.length - 1; idx >= 0; idx--) {
      if (ssArray[idx].localDate <= atDate) {
        return idx;
      }
    }

    // not found - return the oldest one
    return 0;
  }

  public getEffectiveNutrientArea(dayNumber: number, siteSettingsNutrients: SiteSettingsNutrients[]) {
    if (!siteSettingsNutrients?.length) {
      return null;
    }

    // Ensure sorted in reverse order
    const siteSettingsNutrientsSorted = ArrayUtils.sortByNumber(siteSettingsNutrients, (x) => x.DayNumber, 'desc');
    // Select first with DayNumber(=DayEffectiveFrom) before day of interest
    const effectiveSettinig = siteSettingsNutrientsSorted.find((a) => a.DayNumber <= dayNumber);
    let nootArea: number;
    if (effectiveSettinig) {
      nootArea = effectiveSettinig.CropNutrientArea;
      if (nootArea == null || nootArea <= 0) {
        nootArea = effectiveSettinig.Asset.Site.Area;
      }
    }
    return nootArea;
  }

  // Search an array and return the index of the row with the given id
  // will also work on an IdName array
  public findById(id: number, ssArray: any): number {
    if (ssArray == undefined) {
      return undefined;
    }

    for (let idx = ssArray.length - 1; idx >= 0; idx--) {
      if (ssArray[idx].Id == id) {
        return idx;
      }
    }

    // not found - return the oldest one
    return 0;
  }

  // Copy properties from one object into a new object - props is an array of property name strings
  public copyProps(obj, props: string[]): any {
    const copy = {};
    if (null == obj || 'object' != typeof obj) return obj;

    for (const idx in props) {
      const attr = props[idx];
      if (attr in obj) {
        copy[attr] = obj[attr];
      }
    }
    return copy;
  }

  public loadWaterBudget(pAccountId: number, pSiteId: number, cancellationPromise?: angular.IPromise<any>): void {
    // for any given site, the WaterBudgets are already defined
    // and applied from the WaterBudget pages
    const config = {
      params: { accountId: pAccountId, siteId: pSiteId },
      timeout: cancellationPromise,
    };

    // ;
    this._$http
      .get<WaterBudgetInfo>(CommonHelper.getApiUrl('user/GetSiteWaterBudgetInfo'), config)
      .then((result) => {
        this.waterBudget = result.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public getReportNutrientBudget(budgetId: number): angular.IPromise<fuse.reportNutrientBudgetDto[]> {
    const config = {
      params: { budgetID: budgetId },
    };

    const defer = this._q.defer<fuse.reportNutrientBudgetDto[]>();

    this._$http
      .get<fuse.reportNutrientBudgetDto[]>(CommonHelper.getApiUrl('user/ReportNutrientBudget/'), config)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  addRequiredSiteSettings(assetId: number, settingType: string): angular.IPromise<any> {
    const defer = this._q.defer();

    const thisDateNow: Date = new Date();

    switch (settingType) {
      case 'Crop':
      case 'Nutrients':
      case 'Soil':
      case 'Water':
        const successCallbackCrop = (data: breeze.QueryResult) => {
          if (data.inlineCount === 0) {
            this._languageService.info('AC.SITE.CREATING', 'COMMON.INFORMATION', { type: settingType });
            const initialValues = this._dataEntityService.getDefault('SiteSettings' + settingType, thisDateNow);
            const entityType = this._entityManager.metadataStore.getEntityType('SiteSettings' + settingType) as breeze.EntityType;
            initialValues.AssetId = assetId;
            const newSetting = entityType.createEntity(initialValues);
            this._entityManager.addEntity(newSetting);
            this._entityManager.saveChanges([newSetting]);

            defer.resolve(newSetting);
          }
        };

        // getSiteSettingsCrop
        // getSiteSettingsNutrient
        // getSiteSettingsSoil
        // getSiteSettingsWater
        breeze.EntityQuery.from('getSiteSettings' + settingType)
          .withParameters({ assetId: assetId })
          .take(0)
          .inlineCount(true)
          .using(this._entityManager)
          .execute()
          .then(successCallbackCrop, null);

        break;

      default:
        break;
    }

    return defer.promise;
  }
}

angular.module('fuse').service('SiteSettingService', SiteSettingService);
