import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { IContextData, LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { AccountInfo } from '@common/models/interfaces';

export class LocalStorageService {
  dataEntryDays: number;

  private _isDebuggingEnabled: boolean = false;
  private _debugColour: string = 'color: #409030;';
  private _log: angular.ILogService;

  constructor(
    $log: angular.ILogService,
  ) {
    this._log = $log;

    LocalStorageUtils.configureLogging((message: string): void => {
      if (this._isDebuggingEnabled) {
        this._log.debug('%c' + LocalStorageService.name + ' ' + message, this._debugColour);
      }
    });
  }

  get accountList(): AccountInfo[] {
    return this.get('accountList');
  }

  private get contextData(): IContextData {
    return LocalStorageUtils.contextData;
  }

  set(path: string, value: any): void {
    LocalStorageUtils.set(path, value);
  }

  get(path: string): any {
    return LocalStorageUtils.get(path);
  }

  remove(path: string): void {
    LocalStorageUtils.remove(path);
  }

  clear(): void {
    LocalStorageUtils.clear();
  }

  hasPendingRequests(): boolean {
    return this.get('spinner') ?? false;
  }

  initAccountList() {
    const context = this.contextData;
    const accountId = context.accountId;
    const accountList = this.accountList ?? [];

    // Sets 'accounList' in local storage if it does not yet exist.
    const account = accountList.find((x) => x.accountId === accountId);

    if (!account) {
      accountList.push({
        accountId: accountId,
        logoBase64: '',
        groupId: 0,
        mapLatitude: null,
        mapLongitude: null,
        mapZoom: null,
        waterGroupId: 0,
      });

      this.set('accountList', ArrayUtils.sortByNumber(accountList, (x) => x.accountId));
    }

    // Update context data so that the call HttpContextHelper.GetGroupId() will return the correct groupId.
    context.groupId = account?.groupId ?? 0;

    this.set('contextData', context);
  }

  hasGroupChanged(groupId: number) {
    return this.contextData?.groupId !== groupId || this.getGroupId() !== groupId;
  }

  getAccount(): AccountInfo | undefined {
    const account = this.accountList?.find((x) => x.accountId === this.contextData?.accountId);

    return account;
  }

  getAccountLogo(): string {
    return this.getAccount()?.logoBase64 ?? '';
  }

  getGroupId(): number {
    return this.getAccount()?.groupId ?? 0;
  }

  getWsmGroupId(): number {
    return this.getAccount()?.waterGroupId ?? 0;
  }

  setGroupId(groupId: number): void {
    const contextData = this.contextData;

    if (contextData) {
      contextData.groupId = groupId;

      this.set('contextData', contextData);
    }

    this.updateAccountList({ groupId });
  }

  setWsmGroupId(waterGroupId: number): void {
    this.updateAccountList({ waterGroupId });
  }

  updateAccountLogo(logoBase64: string): void {
    this.initAccountList();
    this.updateAccountList({ logoBase64 })
  }

  private updateAccountList(props: object): void {
    const account = this.getAccount();

    if (!account) {
      return;
    }

    // Exclude account being updated.
    const accountList = this.accountList.filter(x => x.accountId !== account.accountId);

    Object.keys(props).forEach((key) => {
      account[key] = props[key];
    });

    accountList.push(account);

    this.set('accountList', ArrayUtils.sortByNumber(accountList, (x) => x.accountId));
  }
}

angular.module('fuse').service('LocalStorageService', LocalStorageService);
