import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { SWANConstants } from '@common/SWANConstants';
import { AuthZeroService } from '@services/auth-zero.service';
import { LocalStorageService } from '@services/local-storage.service';

export class AuthInterceptorService {
  public request: typeof this._request = this._request.bind(this);
  public response: typeof this._response  = this._response.bind(this);
  public responseError: typeof this._responseError = this._responseError.bind(this);

  private _injector: angular.auto.IInjectorService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _localStorageService: LocalStorageService;

  private _count: number = 0;

  constructor(
    $injector: angular.auto.IInjectorService,
    $q: angular.IQService,
    $state: angular.ui.IStateService,
    LocalStorageService: LocalStorageService,
  ) {
    this._injector = $injector;
    this._q = $q;
    this._state = $state;
    this._localStorageService = LocalStorageService;
  }

  private _response(response) {
    this.checkProgress();

    // if response data is null, it maybe is an error
    if (response !== null) {
      this.checkVersion(response.headers('SWANversion'));
    }

    return response;
  }

  private checkProgress() {
    this._count--;
    if (this._count <= 0) {
      this._localStorageService.set('spinner', false);
    }
  }

  private checkVersion(version) {
    if (version != null && AppSettings.SWANversion !== version) {
      this._state.go('app.auth.login', { isWrongVersion: true });
    }
  }

  private _request(config) {
    if (this._count <= 0) {
      this._localStorageService.set('spinner', true);
    }

    this._count++;

    config.headers = config.headers || {};

    const authZeroService: AuthZeroService = this._injector.get('AuthZeroService');

    if (!authZeroService.requiresToken(config.url)) {
      return config;
    }

    const accessToken = authZeroService.getAccessToken();
    const context = LocalStorageUtils.contextData;

    if (accessToken) {
      config.headers.Authorization = 'Bearer ' + accessToken;

      config.headers.Xswan = JSON.stringify(context);
    } else {
      // for no authorized call
      //config.headers.Xswan = JSON.stringify(info);
    }

    return config;
  }

  private _responseError(rejection) {
    this.checkProgress();

    const defer = this._q.defer();

    if (rejection.status === SWANConstants.HttpCodes.UNAUTHORIZED) {
      const authZeroService: AuthZeroService = this._injector.get('AuthZeroService');

      if (authZeroService.isTokenValid()) {
        authZeroService.refreshToken().then(
          (res) => {
            // If we get unauthorised, try to get a token, if that works, retry the original HTTP request.
            if (res) {
              this.retryHttpRequest(rejection.config, defer);
            } else {
              authZeroService.logout();
            }
          },
          () => {
            authZeroService.logout();
          },
        );
      } else {
        authZeroService.logout();
      }
    } else {
      defer.reject(rejection);
    }

    return defer.promise;
  }

  private retryHttpRequest(config, defer: angular.IDeferred<unknown>): void {
    const http: angular.IHttpService = this._injector.get('angular.IHttpService');

    http(config).then(
      (response) => {
        defer.resolve(response);
      },
      (error) => {
        defer.reject(error);
      },
    );
  }
}

angular.module('fuse').service('AuthInterceptorService', AuthInterceptorService);
