import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
export enum MetricStatus {
  NotApplic = 'NotApplic',
  Incomplete = 'Incomplete',
  BelowTarget = 'BelowTarget',
  OnTarget = 'OnTarget',
  AboveTarget = 'AboveTarget',
}

export interface TargetColour {
  color: string;
  icon: string;
}
export interface TargetLegend {
  key: string;
  color: string;
  icon: string;
  title: string;
}

export class CropService {
  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _ngFileUpload: any;

  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;
  public targetDisplay = {};

  constructor(
    $q: angular.IQService,
    $http: angular.IHttpService,
    LanguageService: LanguageService,
    UnitOfMeasureService: UnitOfMeasureService,
    Upload: any,
  ) {
    this._q = $q;
    this._http = $http;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;
    this._ngFileUpload = Upload;

    this.targetDisplay[MetricStatus.OnTarget] = {
      color: '#4CAF50',
      icon: 'icon-checkbox-marked-circle green-500-fg',
    } as TargetColour;

    this.targetDisplay[MetricStatus.BelowTarget] = {
      color: '#FF9800',
      icon: 'icon-arrow-down-bold-circle orange-500-fg',
    } as TargetColour;

    this.targetDisplay[MetricStatus.AboveTarget] = {
      color: '#2196F3',
      icon: 'icon-arrow-up-bold-circle blue-500-fg',
    } as TargetColour;

    this.targetDisplay[MetricStatus.NotApplic] = { color: '#9E9E9E', icon: 'icon-minus-circle grey-500-fg' } as TargetColour;
    this.targetDisplay[MetricStatus.Incomplete] = { color: '#616161', icon: 'icon-cancel grey-700-fg' } as TargetColour;
  }

  public getDetailCrop(CropId: number): angular.IPromise<Swan.Crop> {
    const defer = this._q.defer<Swan.Crop>();
    const inputParam = { cropId: CropId };
    const config = {
      params: inputParam,
    };

    this._http
      .get<Swan.Crop>(CommonHelper.getApiUrl('Crops/Detail/'), config)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getLocalCrop(AccountID: number): angular.IPromise<Swan.Crop[]> {
    const config = { params: { accountID: AccountID } };

    return this.getCropArray('GetLocalCrop', config);
  }

  public getSharedCrop(AccountID: number): angular.IPromise<Swan.Crop[]> {
    const config = { params: { accountID: AccountID } };

    return this.getCropArray('GetSharedCrop', config);
  }

  public downloadCrops(cropIDs: number[], scale: fuse.unitScaleDto): angular.IPromise<any> {
    const config = {
      params: { cropIDs: cropIDs, unit_id: scale.unitId },
      responseType: 'arraybuffer',
    };

    return this.getCropArray('DownloadCrops', config);
  }

  public downloadCSVTemplate(): angular.IPromise<any> {
    const config = {
      responseType: 'arraybuffer',
    };

    return this.getCropArray('DownloadCSVTemplate', config);
  }

  private getCropArray(route: string, params: object): angular.IPromise<any> {
    const defer = this._q.defer<Swan.Crop[]>();

    this._http
      .get<Swan.Crop[]>(CommonHelper.getApiUrl('Crops/' + route), params)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public uploadCSV(
    accountId: number,
    csvFile: any,
    validateLocalCropAlreadyExists: boolean,
    scale: fuse.unitScaleDto,
  ): angular.IPromise<any> {
    const defer = this._q.defer();

    this._ngFileUpload
      .upload({
        url: CommonHelper.getApiUrl('Crops/uploadCropCSV/'),
        data: { file: csvFile },
        params: { accountId: accountId, ValidateLocalCropAlreadyExists: validateLocalCropAlreadyExists, unitId: scale.unitId },
      })
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public generateCropFromSiteSettings(settings: fuse.generateSiteCropDTO): angular.IPromise<Swan.Crop> {
    return this.postCrop('GenerateCropFromSiteSettings', settings);
  }

  public addNewCrop(newCrop: Swan.Crop): angular.IPromise<any> {
    return this.postCrop('AddNew', newCrop);
  }

  public udpateCrop(cropDetail: Swan.Crop): angular.IPromise<any> {
    return this.postCrop('Update', cropDetail);
  }

  public rolloutCrop(rolloutDto: fuse.SiteCropKcRolloutDto): angular.IPromise<any> {
    return this.postCrop('RolloutToSites', rolloutDto);
  }

  private postCrop(endpoint: string, settings: object): angular.IPromise<Swan.Crop> {
    const defer = this._q.defer<Swan.Crop>();

    this._http
      .post<any>(CommonHelper.getApiUrl('Crops/' + endpoint), settings)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  // For display in list as metric1, metric2, etc
  public getMetricsString(metrics) {
    return metrics
      .filter((m) => !m.metric?.default)
      .map((m) => this._languageService.instant('DB_VALUES.CROP_METRICS.' + m.name))
      .join(', ');
  }

  public pctTopTag = 'Percentage Top Grade'; // default metric DB name
  public yieldAreaTag = 'Total Yield'; // default metric DB name, actually Yield/Area
  public yieldWeight = 'YieldWeight'; // shown on results dialogue instead of Yield/Area
  public priceTag = 'Price'; // shown in cycles table only

  // Calculates default metrics (Yield/Area, Percent top grade), and related Yield total and total Price
  public calcDerived(cycle: fuse.siteCropCycle) {
    let yieldTotal = 0;
    let topGradeTotal = 0;
    let priceTotal = 0;

    cycle.harvests.forEach((harvest) => {
      harvest.yields.forEach((yld) => {
        const weight = Number(yld.weight);

        if (!Number.isNaN(weight)) {
          yieldTotal += weight;
          priceTotal += weight * yld.priceValue;

          if (yld.gradeSequence == 1) {
            topGradeTotal += weight;
          }
        }
      });
    });

    let pctTopGrade = (topGradeTotal / yieldTotal) * 100;

    if (Number.isNaN(pctTopGrade)) {
      pctTopGrade = 0;
    }

    cycle[this.yieldWeight] = yieldTotal;
    cycle[this.yieldAreaTag] = yieldTotal / cycle.siteArea;
    cycle[this.pctTopTag] = pctTopGrade;
    cycle[this.priceTag] = priceTotal;
  }

  private statusesUsed = {};

  public setMetricStatus(cycles: EntityList<fuse.siteCropCycle>, selectedMetric: fuse.metricDto): TargetLegend[] {
    const filtered = cycles.filterEntities();

    this.statusesUsed = {};

    if (!selectedMetric.id) {
      this.statusesUsed = {};
      filtered.forEach((cycle) => (cycle['metricStatus'] = null));

      return;
    }

    filtered.forEach((cycle) => {
      const status = this.metricStatus(cycle, selectedMetric);
      this.statusesUsed[status] = true;
    });

    return this.compileLegend();
  }

  private metricStatus(cycle: fuse.siteCropCycle, selectedMetric: fuse.metricDto): MetricStatus {
    let totalYield = 0;
    let weightedMetrics = 0;
    let weightedValue: number;
    let status: MetricStatus;
    let hasMetricValues = false;

    const target = cycle.cropTargets.metricTargets.find((mt) => mt.metricId == selectedMetric.id);

    if (!cycle.harvests.length) {
      cycle['metricTarget'] = 'N/A';
      status = MetricStatus.Incomplete;
    }

    if (selectedMetric.derived) {
      this.calcDerived(cycle);
      weightedValue = cycle[selectedMetric.name];
      hasMetricValues = true;
    } else {
      cycle.harvests.forEach((harvest) => {
        harvest.yields.forEach((yld) => {
          const ym = yld.metrics.find((m) => m.metricId == selectedMetric.id);

          // Metric considered incomplete if any value missing (unless yield also == 0)
          if (ym?.value == null && yld.weight !== 0) {
            cycle['metricTarget'] = !!target ? this.targetText(target, selectedMetric) : null;
            status = MetricStatus.Incomplete;
          }

          if (ym?.value != null) {
            hasMetricValues = true;
            totalYield += yld.weight;

            switch (selectedMetric.aggregationType) {
              case 'AVG':
                weightedMetrics += yld.weight * ym.value;
                break;
              case 'SUM':
                weightedMetrics += ym.value;
                break;
            }
          }
        });
      });

      switch (selectedMetric.aggregationType) {
        case 'AVG':
          if (totalYield !== 0) {
            weightedValue = weightedMetrics / totalYield;
          } else {
            weightedValue = null;
          }

          break;
        case 'SUM':
          weightedValue = weightedMetrics;

          break;
      }
    }

    if (!hasMetricValues) {
       weightedValue = null;
    }

    // Metric status == NA if no harvest data, or metric not in crop metrics set
    if (!target || target.op === 'na') {
      cycle['metricTarget'] = 'N/A';
      status = MetricStatus.NotApplic;
    }

    if (status !== MetricStatus.Incomplete && status !== MetricStatus.NotApplic) {
      switch (target.op) {
        case 'bw':
          if (weightedValue < target.value) {
            status = MetricStatus.BelowTarget;
          } else if (weightedValue < target.valueTo) {
            status = MetricStatus.OnTarget;
          } else {
            status = MetricStatus.AboveTarget;
          }

          break;
        case 'gt':
          status = (weightedValue >= target.value) ? MetricStatus.OnTarget : MetricStatus.BelowTarget;

          break;
        case 'lt':
          status = (weightedValue <= target.value) ? MetricStatus.OnTarget : MetricStatus.AboveTarget;

          break;
        default:
            status = MetricStatus.NotApplic;
            
            break;
      }
    }

    cycle['metricValue'] = weightedValue;
    cycle['metricStatus'] = status;
    return status;
  }

  public compileLegend(): TargetLegend[] {
    if (Object.keys(this.statusesUsed)?.length <= 0) return null;

    // List based on metricColours object so colours always in same order, even if some excluded
    const legendData = Object.keys(this.targetDisplay)
      .filter((key) => !!this.statusesUsed[key])
      .map((key) => {
        return {
          key: key,
          title: this._languageService.instant('CROPS.TARGET_STATUS.' + key),
          color: this.targetDisplay[key].color,
          icon: this.targetDisplay[key].icon,
        } as TargetLegend;
      });

    return legendData;
  }

  public targetText(target: fuse.cropMetricTargetsDto, metric: fuse.metricDto) {
    const value = this._unitOfMeasureService.convertFromBase(metric.unitBaseClass, metric.scaleId, target.value);
    const valueTo = this._unitOfMeasureService.convertFromBase(metric.unitBaseClass, metric.scaleId, target.valueTo);

    switch (target.op) {
      case 'bw':
        return `${value} < [value] < ${valueTo}`;
      case 'gt':
        return ` > ${value}`;
      case 'lt':
        return ` < ${value}`;
    }

    return 'N/A';
  }
}

angular.module('fuse').service('CropService', CropService);
