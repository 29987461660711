import * as angular from 'angular';
import * as moment from 'moment';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { SQLErrorCodes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { _RegistrationHelper } from '../_DBContext/_RegistrationHelper';

export class logLevel {
  name: string;
  level: number;
}

export class DataEntityService {
  public manager: breeze.EntityManager;
  public hasMetadata: boolean;

  private _languageService: LanguageService;

  // some component is detached from breeze data service,
  // the following parameter is being used to perform validation when user change to different state
  private _hasDirtyCustomForm: boolean = false;

  /* use installCustomChanges to set, and installCustomChanges(null,null,null) to clear */
  /* better if this was a list, rather than singleton */
  private saveCustomChanges: Function;
  private canCustomChanges: Function;
  private hasCustomChanges: Function;

  constructor(
    breeze: any,
    LanguageService: LanguageService,
  ) {
    this._languageService = LanguageService;

    // Convert properties between server-side PascalCase and client-side camelCase
    // $breeze.NamingConvention.camelCase.setAsDefault();

    //create a new entity manager and get access to the metadata store for extend entities
    this.manager = new breeze.EntityManager(CommonHelper.getApiUrl('data')); // breeze Web API controller
    this.hasMetadata = false;
    //register custom classes
    _RegistrationHelper.register(this.manager.metadataStore);
  }

  public get hasDataChanges(): boolean {
    const hasCustomChanges = this.hasCustomChanges?.();
    let managerHasChanges = false;

    if (!hasCustomChanges) {
      // Check the manager changes only if there are no custom changes, as checking breeze state changes might be costly and should be avoided unnecessarily.
      managerHasChanges = this.manager.hasChanges();
    }

    const hasChanges = hasCustomChanges || managerHasChanges;

    return hasChanges;
  }

  public get hasDirtyCustomForm(): boolean {
    return this._hasDirtyCustomForm;
  }

  public set hasDirtyCustomForm(value: boolean) {
    this._hasDirtyCustomForm = value;
  }

  public get hasFormOrDataChanges(): boolean {
    return this.hasDirtyCustomForm || this.hasDataChanges;
  }

  public installCustomChanges(detectFn: Function, saveFn: Function, canFn: Function) {
    this.hasCustomChanges = detectFn;
    this.saveCustomChanges = saveFn;
    this.canCustomChanges = canFn;
  }

  public async loadMetadata(): Promise<void> {
    await this.manager.fetchMetadata();

    this.hasMetadata = true;
  }

  public getDefault(strEntityName: string, strDate: Date) {
    const context = LocalStorageUtils.contextData;

    const opts = {
      DateTime: Date.now().toString(),
      AuthAccountId: context.accountId,
      WorkAgentId: 3 /* WebClient-SWAN todo !!!*/,
      AssetId: null,
    };
    let attrs = {};

    // change soil, crop, water & nuts effective date to strDate

    switch (strEntityName) {
      case 'SiteSettingsCrop':
        attrs = {
          EffectiveFromYMD: moment(strDate).format('YYYY-MM-DD'),
          CropId: 1,
          CropGrowthPhase: 0,
          TolWetSoil: 1,
          TolDrySoil: 1,
          TolLowTemp: 1,
          TolHighTemp: 1,
        };
        break;
      case 'SiteSettingsNutrients':
        attrs = {
          EffectiveFromYMD: moment(strDate).format('YYYY-MM-DD'),
        };
        break;

      case 'SiteSettingsSoil':
        attrs = {
          EffectiveFromYMD: moment(strDate).format('YYYY-MM-DD'),
          WorkingSoilDepthBottom_mm: 500,
          MinPenetratingRainfall_24Hours_mm: 0,
          MaxPenetratingRainfall_24Hours_mm: 50,
          DrainageCoefficient: 0.5,
          WiltingPoint_pct: 10,
          FieldCapacity_pct: 20,
          Saturation_pct: 40,
          SoilMoistureUpperTarget_percent: 20,
          SoilMoistureLowerTarget_percent: 15,
        };
        break;

      case 'SiteSettingsWater':
        attrs = {
          EffectiveFromYMD: moment(strDate).format('YYYY-MM-DD'),
          SystemEfficiencyCoefficient: 1.0,
          IrrigationApplicationOneHour_mm: 50,

          SprinklerLossConstantA: 1.1,
          SprinklerLossConstantB: 0,

          WaterBudgetLowerTarget_percent: 70,
          WaterBudgetUpperTarget_percent: 100,

          WaterMonday: true,
          WaterTuesday: true,
          WaterWednesday: true,
          WaterThursday: true,
          WaterFriday: true,
          WaterSaturday: true,
          WaterSunday: true,
          CropWettedArea_perc: 100,
          WaterIntervalDays: null,
          WaterIntervalFromDayNumber: null,
          IrrigationDays: 'D',
        };
        break;

      case 'ObsHealthIndex':
        attrs = {
          AssetId: context.assetId,
        };
        break;

      case 'Crop':
        attrs = {
          CropClassId: 1,
        };
        break;

      case 'SysMessageLog':
        attrs = {
          Level: 5,
        };

        break;
      default:
      // alert("Unknown entity Type: " + strEntityName); // programmer error
    }

    for (const attrname in attrs) {
      opts[attrname] = attrs[attrname];
    }

    return opts;
  }

  public addEntity(entityTypeName: string, initialValues: any): breeze.Entity {
    const entityType: any = this.manager.metadataStore.getEntityType(entityTypeName);
    const newEntity: any = entityType.createEntity(initialValues);

    return this.manager.addEntity(newEntity);
  }

  public clear(strEntityName?: string) {
    this.manager.getEntities(strEntityName).forEach((entity) => {
      this.manager.detachEntity(entity);
    });
  }

  public reset() {
    this.manager.clear();
  }

  // apply all outstanding edits
  public saveChanges(showToaster: boolean = true, saveOptions?: fuse.swanSaveOptions): Promise<breeze.SaveResult> {
    if (showToaster) {
      this._languageService.info('COMMON.SAVING_CHANGES');
    }

    this.saveCustomChanges?.();

    const breezeSaveOptions =
      saveOptions
        ? new breeze.SaveOptions({ tag: saveOptions })
        : null;

    return this.manager.saveChanges(this.manager.getChanges(), breezeSaveOptions);
  }

  // cancel outstanding edits
  public rejectChanges() {
    if (this.canCustomChanges != null) {
      this.canCustomChanges();
    }

    this.manager.rejectChanges();
  }

  public saveNewWaterBudget(): Promise<breeze.SaveResult> {
    return this.manager.saveChanges(this.manager.getChanges('WaterBudget'));
  }

  public saveWaterBudgetSites(): Promise<breeze.SaveResult> {
    return this.manager.saveChanges(this.manager.getChanges('WaterBudgetSite'));
  }

  public saveWaterBudgetMonths(): Promise<breeze.SaveResult> {
    return this.manager.saveChanges(this.manager.getChanges('WaterBudgetMonth'));
  }

  public getSaveFailedMessage(saveFailed, defaultMessage: string): string {
    let errorMessage = '';
    if (saveFailed.httpResponse.data.Errors) {
      saveFailed.httpResponse.data.Errors.map((error) => {
        if (error.ErrorNumber == SQLErrorCodes.DuplicateKeyError) {
          errorMessage += 'Data already exists for the date. Please check and try again \n';
        }
      });
    }
    if (errorMessage == '') {
      errorMessage = defaultMessage;
    }
    return errorMessage;
  }
}

angular.module('fuse').service('DataEntityService', DataEntityService);
