import * as angular from 'angular';
import * as auth0 from 'auth0-js';
import { AppSettings } from '@indicina/swan-shared/AppSettings';

class Auth0IdTokenPayload {
  email: string;
  nickName: string;
  sub: string;
  exp: number;
}

export class AuthZeroService {
  public isSsoUser = false;

  private _q: angular.IQService;
  private _jwtHelper: any;
  private _auth0: auth0.WebAuth;

  private _accessToken?: string;
  private _expireAt: number;
  private _email: string;

  constructor(
    $q: angular.IQService,
    jwtHelper: any,
) {
    this._q = $q;
    this._jwtHelper = jwtHelper;
    this.processTokenFromLocalStorage();

    this._auth0 = new auth0.WebAuth({
      audience: AppSettings.auth0.audience,
      clientID: AppSettings.auth0.clientId,
      cookieDomain: AppSettings.auth0.cookieDomain,
      domain: AppSettings.auth0.domain,
      redirectUri: AppSettings.app.clientUrl,
      responseType: 'token',
      scope: 'openid profile email',
    });
  }

  public login(): void {
    this._auth0.authorize();
  }

  public logout(): void {
    localStorage.removeItem('token');
    this._accessToken = '';
    this._expireAt = 0;

    this._auth0.logout({
      returnTo: AppSettings.auth0.logoutUrl,
    });
  }

  public getAccessToken(): string | null {
    if (!this.isValid(this._accessToken)) {
      this._accessToken = null;
    }
    return this._accessToken;
  }

  public getEmail(): string {
    return this._email;
  }

  public getExpireAt(): number {
    return this._expireAt;
  }

  public isTokenValid(): boolean {
    return this.getAccessToken() && Date.now() < this.getExpireAt();
  }

  public refreshToken(): angular.IPromise<boolean> {
    const defer = this._q.defer<boolean>();
    this._auth0.checkSession({}, (err, authResult) => {
      if (err) {
        // the error may be "login required"
        defer.reject(false);
      } else {
        localStorage.setItem('token', authResult.accessToken);
        this.processTokenFromLocalStorage();
        defer.resolve(true);
      }
    });
    return defer.promise;
  }

  public requiresToken(url: string): boolean {
    return (
      !url.includes('auth/getVersion') && !url.includes('data/Metadata') && url.startsWith(AppSettings.app.apiBaseUrl)
    );
  }

  private isValid(token: string): boolean {
    return token && !this._jwtHelper.isTokenExpired(token);
  }

  private processTokenFromLocalStorage(): void {
    const accessToken = localStorage.getItem('token');
    if (this.isValid(accessToken)) {
      this._accessToken = accessToken;
      const tokenPayload = this._jwtHelper.decodeToken(this._accessToken) as Auth0IdTokenPayload;
      this._expireAt = tokenPayload.exp * 1000;
      this._email = tokenPayload.email;

      if (tokenPayload.sub.includes('auth0|')) {
        this.isSsoUser = false;
      } else {
        this.isSsoUser = true;
      }
    }
  }
}

angular.module('fuse').service('AuthZeroService', AuthZeroService);
