import * as angular from 'angular';

export class WaterService {
  private _isKeepFilter: boolean;
  private _nameFilter: string;
  private _cropFilter: string;
  private _activeState: boolean;
  private _archivedState: boolean;
  private _suspendedState: boolean;

  constructor() {
    this._isKeepFilter = false;
    this._nameFilter = '';
    this._cropFilter = '';
    this._activeState = true;
    this._archivedState = false;
    this._suspendedState = false;
  }

  public getKeepFilter(): boolean {
    return this._isKeepFilter;
  }

  public setKeepFilter(isKeepFilter: boolean): void {
    this._isKeepFilter = isKeepFilter;
  }

  public getNameFilter(): string {
    return this._nameFilter;
  }

  public setNameFilter(nameFilter: string): void {
    this._nameFilter = nameFilter;
  }

  public getCropFilter(): string {
    return this._cropFilter;
  }

  public setCropFilter(cropFilter: string): void {
    this._cropFilter = cropFilter;
  }

  public getActiveState(): boolean {
    return this._activeState;
  }

  public setActiveState(state: boolean): void {
    this._activeState = state;
  }

  public getArchivedState(): boolean {
    return this._archivedState;
  }

  public setArchivedState(state: boolean): void {
    this._archivedState = state;
  }

  public getSuspendedState(): boolean {
    return this._suspendedState;
  }

  public setSuspendedState(state: boolean): void {
    this._suspendedState = state;
  }
}

angular.module('fuse').service('WaterService', WaterService);
