import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';

export const NotifyEvents = {
  App: {
    SaveChanges: {
      AssetGroup: 'App.SaveChanges.AssetGroup',
      Equipment:  'App.SaveChanges.Equipment',
      Group: 'App.SaveChanges.Group',
      Infrastructure: 'App.SaveChanges.Infrastructure',
      Profile: 'App.SaveChanges.Profile',
      Site: 'App.SaveChanges.Site',
      WaterAllocation: 'App.SaveChanges.WaterAllocation',
      WaterBudget: 'App.SaveChanges.WaterBudget',
      WaterIrrigationOverride: 'App.SaveChanges.WaterIrrigationOverride',
      WaterIrrigationPlan: 'App.SaveChanges.WaterIrrigationPlan',
    },
  },
  Admin: {
    AccountUpdate: 'Admin.AccountUpdate',
    UserUpdate: 'Admin.UserUpdate',
  },
  Group: {
    NutrientApplication: 'Groups.NutrientApplication',
    Settings: {
      Crop: {
        Base: 'Group.Settings.Crop',
        DataChanges: 'Group.Settings.Crop.DataChanges',
      },
      Soil: {
        Base: 'Group.Settings.Soil',
        DataChanges: 'Group.Settings.Soil.DataChanges',
      },
      Water: {
        Base: 'Group.Settings.Water',
        DataChanges: 'Group.Settings.Water.DataChanges',
      }
    },
  },
  Infrastructure: {
    Refresh: {
      DepthSetting: 'Infrastructure.Refresh.DepthSetting',
      Observations: 'Infrastructure.Refresh.Observations',
    },
  },
  Map: {
    AssetClassChanged: 'Map.AssetClassChanged',
    DrawingModeChanged: 'Map.DrawingModeChanged',
    DrawingModeSelected: 'Map.DrawingModeSelected',
    EditModeChanged: 'Map.EditModeChanged',
    EquipmentReset: 'Map.EquipmentReset',
    FullScreenModeChanged: 'Map.FullScreenModeChanged',
    MapDataUploaded: 'Map.MapDataUploaded',
    MapTypeChanged: 'Map.MapTypeChanged',
    MarkerSelected: 'Map.MarkerSelected',
    TotalAreaChanged: 'Map.TotalAreaChanged',
    UploadMapData: 'Map.UploadMapData',
  },
  Nutrients: {
    Profiles: {
      ElementWeightingChanged: 'Nutrients.Profiles.ElementWeightingChanged',
    },
  },
  Site: {
    Settings: {
      Crop: {
        Base: 'Site.Settings.Crop',
        DataChanges: 'Site.Settings.Crop.DataChanges',
      },
      Nutirients: {
        Base: 'Site.Settings.Nutirients',
        DataChanges: 'Site.Settings.Nutirients.DataChanges',
      },
      Soil: {
        Base: 'Site.Settings.Soil',
        DataChanges: 'Site.Settings.Soil.DataChanges',
      },
      Water: {
        Base: 'Site.Settings.Water',
        DataChanges: 'Site.Settings.Water.DataChanges',
      }
    },
  },
} as const;

export class NotifyingService {
  private _rootScope: angular.IRootScopeService;

  constructor($rootScope: angular.IRootScopeService) {
    this._rootScope = $rootScope;
  }

  public subscribe(eventNames: string | string[], scope: angular.IScope, callback: (event: angular.IAngularEvent, ...args: any[]) => any): void {
    const events = ArrayUtils.toArray(eventNames);

    events.forEach((eventName) => {
      const handler = this._rootScope.$on(eventName, callback);

      scope.$on('$destroy', handler);
    })
  }

  public notify(eventName: string, data?: any): void {
    this._rootScope.$emit(eventName, data);
  }
}

angular.module('fuse').service('NotifyingService', NotifyingService);
