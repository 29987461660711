import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';

angular.module('fuse').filter('currencyName', () => {
  return (code: string): string => {
    if (code == null || code == undefined) return null;
    const name = SWANConstants.currencyCodes.find((a) => a.code == code);
    const output = `${name?.name} (${code})`;
    return output;
  };
});
