import * as angular from 'angular';

export class DecimalDayService {
  public HHmmToDecimalDays(timeStr: string) {
    const re = /^(\d*\d):(\d\d)$/;
    const parts = re.exec(timeStr);

    if (parts !== null && parts.length == 3) {
      const hrs = parseInt(parts[1]);
      const mins = parseInt(parts[2]);
      const totalMins = hrs * 60 + mins;
      return totalMins / 1440;
    }
    return 0;
  }

  public daysToHHmm(decdays: number): string {
    if (decdays == null) {
      return '00:00';
    }
    const dechours = decdays * 24;
    const decMinutes = Math.round(dechours * 60);
    let hrs = Math.floor(decMinutes / 60).toString();
    hrs = hrs.length == 1 ? '0' + hrs : hrs;
    let mins = (decMinutes % 60).toString();
    mins = mins.length == 1 ? '0' + mins : mins;
    return hrs + ':' + mins;
  }
}

angular.module('fuse').service('DecimalDayService', DecimalDayService);
