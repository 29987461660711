import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { Nutrient } from '@common/nutrients.interface';
import { Percentage_to_PartsPerThousand } from '@common/ratios';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { FertiliserService, PriceUnit } from '@services/nutrients/fertiliser.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { FertiliserPrice } from 'src/app/_DBContext/FertiliserPrice';
import { NutrientFields } from 'src/app/_DBContext/NutrientFields';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';
import { SiteWeeklyFertiliser } from 'src/app/_DBContext/SiteWeeklyFertiliser';

/**
 * This report servic is used for generating BudgetVsActual Report( for Site and Group) and TankMix Report( for Site and Group)
 * Any change in this service method will affect both pages and make sure you browse both report pages after making any change.
 */
export class ReportService {
  public groupName: string;
  public groupId: number;

  private _q: angular.IQService;
  private _http: angular.IHttpService;
  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;

  private _entityManager: breeze.EntityManager;

  constructor(
    $q: angular.IQService,
    $http: angular.IHttpService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    FertiliserService: FertiliserService,
  ) {
    this._q = $q;
    this._http = $http;
    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;

    this._entityManager = DataEntityService.manager;
  }

  // accumulate the quantity * proportion to build a total kg
  private setNutrientRowValues(nootRow: Nutrient, myNutrients: NutrientFields, totalWeight: number) {
    nootRow.NO3_N += myNutrients.NO3_N * totalWeight;
    nootRow.OtherN += myNutrients.NH4_N * totalWeight;
    nootRow.TotalN += (myNutrients.NO3_N + myNutrients.NH4_N) * totalWeight;
    nootRow.P += myNutrients.P * totalWeight;
    nootRow.K += myNutrients.K * totalWeight;
    nootRow.Ca += myNutrients.Ca * totalWeight;
    nootRow.Mg += myNutrients.Mg * totalWeight;
    nootRow.S += myNutrients.S * totalWeight;
    nootRow.Na += myNutrients.Na * totalWeight;
    nootRow.Cl += myNutrients.Cl * totalWeight;
    nootRow.Cu += myNutrients.Cu * totalWeight;
    nootRow.Fe += myNutrients.Fe * totalWeight;
    nootRow.Mn += myNutrients.Mn * totalWeight;
    nootRow.Zn += myNutrients.Zn * totalWeight;
    nootRow.B += myNutrients.B * totalWeight;
    nootRow.Mo += myNutrients.Mo * totalWeight;
  }

  public createReportStructure(noots: Nutrient[]) {
    this.createNewReportRow('COMMON.BUDGETED', noots);
    this.createNewReportRow('COMMON.APPLIED', noots);
    this.createNewReportRow('AC.GROUP.RPT.SURPLUS_SHORTFALL', noots);
  }

  private createNewReportRow(RowName: string, noots: Nutrient[]) {
    const nootRow: Nutrient = {} as Nutrient;
    nootRow.RowName = RowName;
    this.initNutfields(nootRow);
    noots.push(nootRow);
  }

  private initNutfields(NutDistPhase: Nutrient) {
    NutDistPhase.NO3_N = 0;
    NutDistPhase.OtherN = 0;
    NutDistPhase.TotalN = 0;
    NutDistPhase.P = 0;
    NutDistPhase.K = 0;
    NutDistPhase.Ca = 0;
    NutDistPhase.Mg = 0;
    NutDistPhase.S = 0;
    NutDistPhase.Na = 0;
    NutDistPhase.Cl = 0;
    NutDistPhase.Cu = 0;
    NutDistPhase.Fe = 0;
    NutDistPhase.Mn = 0;
    NutDistPhase.B = 0;
    NutDistPhase.Mo = 0;
    NutDistPhase.Zn = 0;
  }

  public applyConversions(data, unit: uomUnit) {
    const nutrients = ['NO3_N', 'NH4_N', 'P', 'K', 'Ca', 'Mg', 'S', 'Na', 'Cl', 'Cu', 'Fe', 'Mn', 'Zn', 'B', 'Mo'];
    const sections = ['FertBudget', 'FertActual', 'WaterBudget', 'WaterActual', 'TotalBudget', 'TotalActual', 'Surplus'];

    sections.forEach((section) => {
      nutrients.forEach((noot) => {
        data[section][noot] = unit.fromBase(data[section][noot]);
      });
    });
  }

  public generateBudgetVsActualReport(
    assetId: number,
    startDate: Date,
    endDate: Date,
    noots: Nutrient[],
    newnoots: Nutrient[],
    myDetails: mySiteDetails[],
    mycsvDetails: any[],
    chartId: string,
    unit: uomUnit,
  ) {
    const data = {
      AssetId: assetId,
      dfr: startDate.toString('yyyy-MM-dd'),
      dto: endDate.toString('yyyy-MM-dd'),
    };

    this._http
      .get(CommonHelper.getApiUrl('user/GetNutrientReportNew'), { params: data })
      .then((response) => {
        if (response.data) {
          // either Site or Group
          const data: any = response.data;

          this.createReportStructure(noots); // initialise the accumulation
          // Nutrients are shown in table, csv and graph, but aren't editable in the report, so just convert everything up front
          this.applyConversions(data.MainNutrientFields, unit);

          // First row is the Nutrient Budget or Planned
          this.setNutrientRowValues(noots[0], data.MainNutrientFields.TotalBudget, 1);
          // Second row is the Nutrient Actual
          this.setNutrientRowValues(noots[1], data.MainNutrientFields.TotalActual, 1);
          // Third row is the Nutrient Surplus that is Actual minus Budget
          this.setNutrientRowValues(noots[2], data.MainNutrientFields.Surplus, 1);

          //angular.copy(noots, newnoots);
          //var newnoots = [];

          for (var i = 0; i < noots.length; i++) {
            var ns = noots[i];
            Object.keys(ns).forEach((key) => {
              const transKey = key == 'RowName' ? key : this._languageService.instant('NUTR.CHEM.' + key);
              newnoots[i][transKey] = ns[key];
            });
          }

          // update chart
          const points = this.convertToPoints(newnoots, mycsvDetails);
          this.updateChart(points, chartId);

          // sort sites display alphabetically
          myDetails = ArrayUtils.sortByString(myDetails, (x) => x.siteName);
          mycsvDetails = ArrayUtils.sortByString(mycsvDetails, (x) => x.Name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public fetchAllTankMixes(accountId: number): angular.IPromise<Fertiliser[]> {
    const defer = this._q.defer<Fertiliser[]>();
    const pred1 = new breeze.Predicate('Fertiliser', breeze.FilterQueryOp.NotEquals, null).and(
      'Fertiliser.Type',
      breeze.FilterQueryOp.Equals,
      'Tank Mix',
    );
    breeze.EntityQuery.from('AccountSharedAssets')
      .expand('Fertiliser')
      .withParameters({ accountId: accountId })
      .where(pred1)
      .using(this._entityManager)
      .execute()
      .then((data) => {
        const tankMixes = data.results as Fertiliser[];
        defer.resolve(tankMixes);
      });

    return defer.promise;
  }

  private uniqueSWFIds(obj: SiteWeeklyFertiliser, index, self: SiteWeeklyFertiliser[]) {
    return (
      self
        .map((swf) => {
          return swf.FertiliserId;
        })
        .indexOf(obj.FertiliserId) === index
    );
  }

  public reportTankMixNootFields = {};

  /**
   * This method has been used in Group tankMix report as well as in Site TankMix report. Any change to this method will affect both.
   * after making any chnage to this method please browse both report and make sure it works as expected.
   */
  public generateTankMixReportArrays(
    accountId: number,
    siteWeeklyFertilisersByGroup: SiteWeeklyFertiliser[],
    tankMixes: tankMix[],
    siteTankMixes: tankMix[],
    mycsvDetails: any[],
    volUnit: uomUnit,
    weightUnit: uomUnit,
  ) {
    let sumBudgetActual: number = 0;
    const onDate: Date = new Date();
    const yyyy = onDate.getFullYear();
    const mm = (onDate.getMonth() + 1).toString().padStart(2, '0');
    const dd = onDate.getDate().toString().padStart(2, '0');
    const sDate = `${yyyy}-${mm}-${dd}`;
    const promises: angular.IPromise<void>[] = [];
    const newPromises: angular.IPromise<void>[] = [];
    const fertPricePromises: angular.IPromise<any>[] = [];

    const deferFertPrices = this._q.defer();

    const uniqueFertiliserIds = siteWeeklyFertilisersByGroup.filter(this.uniqueSWFIds).map((swf) => {
      return swf.FertiliserId;
    });

    uniqueFertiliserIds.forEach((fertiliserId) => {
      fertPricePromises.push(this._fertiliserService.getTankMixPrices(accountId, fertiliserId));
    });

    this._q.all(fertPricePromises).then((data: breeze.QueryResult[]) => {
      deferFertPrices.resolve(data);
    });

    deferFertPrices.promise.then((data: breeze.QueryResult[][]) => {
      let tankMixPrices: FertiliserPrice[] = [];

      const correctPrices = {};

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          const specificRawFertPrices = data[i][j].results as FertiliserPrice[];
          const pricePerUnits = specificRawFertPrices.map((fp) => {
            return fp.PricePerUnit;
          });

          if (specificRawFertPrices?.[i]?.Asset?.ParentAssets) {
            const correctParentId = specificRawFertPrices[i].Asset.ParentAssets.filter((pa) => {
              return uniqueFertiliserIds.indexOf(pa.ParentAssetId) != -1;
            })[0].ParentAssetId;

            // assign child fertiliser with lowest price, highest price, and the parent id
            correctPrices[specificRawFertPrices[i].AssetId] = {
              ParentId: correctParentId,
              ChildId: specificRawFertPrices[i].AssetId,
              LowestPrice: Math.min(...pricePerUnits),
              HighestPrice: Math.max(...pricePerUnits),
            } as any;
            tankMixPrices = tankMixPrices.concat(data[i][j].results as any);
          }
        }
      }

      const cpKeys = Object.keys(correctPrices);
      const arrCorrectPrices = cpKeys.map((key) => {
        return correctPrices[key];
      });

      // First Array for the report generate here: Unique Tankmixes from SiteWeekly fertiliser ie..Summary Report (this.tankmix)
      for (let i = 0; i < siteWeeklyFertilisersByGroup.length; i++) {
        const currentRow = siteWeeklyFertilisersByGroup[i];
        const previousRow = siteWeeklyFertilisersByGroup[i - 1];

        if (i > 0 && currentRow.FertiliserId !== previousRow.FertiliserId) {
          let tankmix: tankMix = {} as tankMix;

          let totalLowestPrice = 0;
          let totalHighestPrice = 0;
          // calculate tank mix total highest and lowest prices
          const correctTankMixPrices = arrCorrectPrices
            .filter((cp) => {
              return cp.ParentId === previousRow.FertiliserId;
            })
            .forEach((ctmp) => {
              const coefficient = (
                this._entityManager.getEntities('SiteAsset').filter((sa: SiteAsset) => {
                  return sa.ChildAssetId === ctmp.ChildId && sa.ParentAssetId === ctmp.ParentId;
                })[0] as SiteAsset
              ).Coefficient;

              totalLowestPrice += this._fertiliserService.reCalculateMinMaxValues(
                coefficient,
                ctmp.LowestPrice,
                previousRow.Fertiliser.Fertiliser.TotVolMixInLiters,
                PriceUnit.PricePer1000L,
              );

              totalHighestPrice += this._fertiliserService.reCalculateMinMaxValues(
                coefficient,
                ctmp.HighestPrice,
                previousRow.Fertiliser.Fertiliser.TotVolMixInLiters,
                PriceUnit.PricePer1000L,
              );
            });

          tankmix.highestPrice = totalHighestPrice;
          tankmix.lowestPrice = totalLowestPrice;
          tankmix = this.createNewTankMix(accountId, tankmix, previousRow, newPromises);

          tankmix.totbudgtedActual = sumBudgetActual;
          tankMixes.push(tankmix);

          sumBudgetActual = 0;
          sumBudgetActual += currentRow.FertiliserActual;
        } else {
          sumBudgetActual += currentRow.FertiliserActual;
        }

        if (i === siteWeeklyFertilisersByGroup.length - 1) {
          let tankmix: tankMix = {} as tankMix;

          let totalLowestPrice = 0;
          let totalHighestPrice = 0;
          // calculate tank mix total highest and lowest prices
          const correctTankMixPrices = arrCorrectPrices
            .filter((cp) => {
              return cp.ParentId === currentRow.FertiliserId;
            })
            .forEach((ctmp) => {
              const coefficient = (
                this._entityManager.getEntities('SiteAsset').filter((sa: SiteAsset) => {
                  return sa.ChildAssetId === ctmp.ChildId && sa.ParentAssetId === ctmp.ParentId;
                })[0] as SiteAsset
              ).Coefficient;

              totalLowestPrice += this._fertiliserService.reCalculateMinMaxValues(
                coefficient,
                ctmp.LowestPrice,
                currentRow.Fertiliser.Fertiliser.TotVolMixInLiters,
                PriceUnit.PricePer1000L,
              );

              totalHighestPrice += this._fertiliserService.reCalculateMinMaxValues(
                coefficient,
                ctmp.HighestPrice,
                currentRow.Fertiliser.Fertiliser.TotVolMixInLiters,
                PriceUnit.PricePer1000L,
              );
            });

          tankmix.highestPrice = totalHighestPrice;
          tankmix.lowestPrice = totalLowestPrice;
          tankmix = this.createNewTankMix(accountId, tankmix, currentRow, newPromises);
          tankmix.totbudgtedActual = sumBudgetActual;
          tankMixes.push(tankmix);
          sumBudgetActual = 0;
        }
      }

      /**
       * Second array for the report generate here, ie detailed report based on Site (this.sitetankmixes)
       */
      sumBudgetActual = 0;
      const sortedArray: SiteWeeklyFertiliser[] = ArrayUtils.sortByNumber(siteWeeklyFertilisersByGroup, (x) => x.AssetId);

      for (let i = 0; i < sortedArray.length; i++) {
        const currentRow = siteWeeklyFertilisersByGroup[i];
        const previousRow = siteWeeklyFertilisersByGroup[i - 1];

        if (i > 0 && currentRow.AssetId !== previousRow.AssetId) {
          sumBudgetActual += currentRow.FertiliserActual;
          let tankmix: tankMix = {} as tankMix;

          tankmix = this.createNewTankMix(accountId, tankmix, previousRow, newPromises);

          tankmix.totbudgtedActual = sumBudgetActual;
          siteTankMixes.push(tankmix);
          sumBudgetActual = 0;
        } else {
          if (i > 0 && currentRow.FertiliserId !== previousRow.FertiliserId) {
            sumBudgetActual += currentRow.FertiliserActual;
            let tankmix: tankMix = {} as tankMix;

            tankmix = this.createNewTankMix(accountId, tankmix, previousRow, newPromises);

            tankmix.totbudgtedActual = sumBudgetActual;
            siteTankMixes.push(tankmix);
            sumBudgetActual = 0;
          } else {
            //siteId equal and fertiliser id not equal
            sumBudgetActual += currentRow.FertiliserActual;
          }
        }
        if (i === sortedArray.length - 1) {
          let tankmix: tankMix = {} as tankMix;

          tankmix = this.createNewTankMix(accountId, tankmix, currentRow, newPromises);
          tankmix.totbudgtedActual = sumBudgetActual;
          siteTankMixes.push(tankmix);

          sumBudgetActual = 0;
        }
      }

      /**
       * Also the report requires a detailed view of Nutrient Weightage for the tankmix, so this will do that
       */
      angular.forEach(tankMixes, (tankmix) => {
        const data = {
          AssetId: tankmix.tankmixId,
          Appln: '1',
          dto: sDate,
        };
        promises.push(
          //Based on fertiliser Id get the Nutrient structure
          this.fetchObsNutrient(tankmix).then(
            (data) => {
              if (data) {
                this.reportTankMixNootFields[tankmix.tankmixId.toString()] = data;

                const lstArray: tankMix[] = [];
                lstArray.push(tankmix);

                siteTankMixes.filter((tm) => tm.tankmixId === tankmix.tankmixId)
                  ?.forEach((site) => {
                    lstArray.push(site);
                  });
              }
            },
            (error) => {
              console.log(error);
            },
          ),
        );
      });

      /**
       * csv upload array generates here(this.mycsvDetails)
       */
      let k = 0;
      this._q.all(newPromises).then((data) => {
        for (let i = 0; i < siteTankMixes.length; i++) {
          const site = siteTankMixes[i];
          let tankmixname = site.Name;
          let SiteName: string;
          k = 0;
          site.fert.Asset.ChildAssets.forEach((child) => {
            this._fertiliserService.setUnits(child, weightUnit, volUnit);

            if (k === 0) {
              tankmixname = site.Name;
              SiteName = site.siteName;
            } else {
              tankmixname = '';
              SiteName = '';
            }

            const s = {
              sitename: SiteName,
              name: tankmixname,
              fertname: child.ChildAsset.Name,
              volume: volUnit.fromBase(site.fert.TotVolMixInLiters),
              unit: child.unit.name,
              unit1000: this._fertiliserService.concPer1000FertVol(
                child.Coefficient,
                site.fert.TotVolMixInLiters,
                child.unit,
                volUnit,
              ),
              unitbatch: child.unit.fromBase(child.Coefficient),
            };
            mycsvDetails.push(s);
            k++;
          });
        }
      });
    });
  }

  private createNewTankMix(
    accountId: number,
    tankmix: tankMix,
    currentRow: SiteWeeklyFertiliser,
    newPromises: angular.IPromise<void>[],
  ): tankMix {
    let newvalue = 0;
    tankmix.tankmixId = currentRow.FertiliserId;
    tankmix.Name = currentRow.Fertiliser.Name;
    tankmix.siteId = currentRow.AssetId;
    tankmix.fert = currentRow.Fertiliser.Fertiliser;
    tankmix.type = 'Tank Mix';
    //tankmix.highestPrice = tankmix.highestPrice * tankmix.fert.TotVolMixInLiters / 1000; // from per L to per 1000L
    //tankmix.lowestPrice = tankmix.lowestPrice * tankmix.fert.TotVolMixInLiters / 1000; // from per L to per 1000L
    tankmix.Noot = {} as Nutrient;

    const tankMixSiteEntity = this._entityManager.getEntityByKey('Asset', currentRow.AssetId) as Asset;
    if (tankMixSiteEntity) {
      tankmix.siteName = tankMixSiteEntity.Name;
    }
    newPromises.push(
      this.fetchAllSiteAsset(accountId, currentRow.Fertiliser.Fertiliser.AssetId, newPromises).then((data) => {
        tankmix.totWeightage = this.calcWeightageOfFertInTankMix(data);
        angular.forEach(data, (siteasset) => {
          newvalue += (siteasset.Coefficient / tankmix.fert.TotVolMixInLiters) * 1000;
        });
        tankmix.UnitsTotal = newvalue;
      }),
    );
    return tankmix;
  }

  public calculateAnalysisKg(element: string, assetId: string): number {
    let value = 0;
    const assetNutrientFields: NutrientFields = this.reportTankMixNootFields[assetId];

    switch (element) {
      case 'NO3_N':
        value = assetNutrientFields.NO3_N;
        break;
      case 'NH4_N':
        value = assetNutrientFields.NH4_N;
        break;
      case 'TotalN':
        value = assetNutrientFields.NO3_N + assetNutrientFields.NH4_N;
        break;
      default:
        value = assetNutrientFields[element];
        break;
    }
    return value * Percentage_to_PartsPerThousand;
  }

  private fetchObsNutrient(tankmix: tankMix): angular.IPromise<NutrientFields> {
    const onDate: Date = new Date();
    const yyyy = onDate.getFullYear();
    const mm = onDate.getMonth() < 9 ? '0' + (onDate.getMonth() + 1) : onDate.getMonth() + 1;
    const dd = onDate.getDate() < 10 ? '0' + onDate.getDate() : onDate.getDate();
    const sDate: string = yyyy + '-' + mm + '-' + dd;

    const data = {
      AssetId: tankmix.tankmixId,
      Appln: '1',
      dto: sDate,
    };

    const defer = this._q.defer<NutrientFields>();

    this._http.get(CommonHelper.getApiUrl('user/getNutrients'), { params: data }).then(
      (response) => {
        if (response.data) {
          defer.resolve(response.data[0] as NutrientFields);
        }
      },
      (error) => {
        console.log(error);
      },
    );
    return defer.promise;
  }

  private calcWeightageOfFertInTankMix(siteassets: SiteAsset[]): number {
    let sumofWeightage = 0;
    angular.forEach(siteassets, (siteasset) => {
      sumofWeightage += siteasset.Coefficient;
    });
    return sumofWeightage;
  }

  public fetchSiteWeeklyFertiliserTankMix(
    siteId,
    assetType: string,
    selectedTankMixIds: number[] = null,
    startDayNumber: number = null,
    endDayNumber: number = null,
  ): angular.IPromise<SiteWeeklyFertiliser[]> {
    const defer = this._q.defer<SiteWeeklyFertiliser[]>();

    breeze.EntityQuery.from('SiteWeeklyFertilisers')
      .withParameters({
        assetId: siteId,
        type: assetType,
        fertIds: selectedTankMixIds,
        startDayNum: startDayNumber,
        endDayNum: endDayNumber,
      })
      .expand('Fertiliser, Fertiliser.Fertiliser, Asset.Site')
      .orderBy('FertiliserId', true)
      .using(this._entityManager)
      .execute()
      .then((data) => {
        defer.resolve(data.results as SiteWeeklyFertiliser[]);
      });
    return defer.promise;
  }

  private fetchAllSiteAsset(
    accountId: number,
    assetId: number,
    newPromises: angular.IPromise<void>[],
  ): angular.IPromise<SiteAsset[]> {
    const defer = this._q.defer<SiteAsset[]>();
    const pred = new breeze.Predicate('ParentAssetId', breeze.FilterQueryOp.Equals, assetId);

    const promise = breeze.EntityQuery.from('SiteAssets')
      .expand('ChildAsset.Fertiliser')
      .withParameters({ accountId: accountId })
      .where(pred)
      .using(this._entityManager)
      .execute()
      .then((data) => {
        if (data.results.length >= 1) {
          const siteAssets = data.results as SiteAsset[];
          defer.resolve(siteAssets);
        }
      });
    newPromises.push(promise as angular.IPromise<any>);

    return defer.promise;
  }

  private convertToPoints(newnoots: Nutrient[], csvDetails): MyClusteredChart[] {
    const myPoints: MyClusteredChart[] = [];
    let nutrients: string[] = [
      'NO3_N',
      'OtherN',
      'TotalN',
      'P',
      'K',
      'Ca',
      'Mg',
      'S',
      'Na',
      'Cl',
      'Cu',
      'Fe',
      'Mn',
      'Zn',
      'B',
      'Mo',
    ];
    nutrients = nutrients.map((n) => this._languageService.instant('NUTR.CHEM.' + n));

    for (const nutrient of nutrients) {
      const myPoint = {} as MyClusteredChart;
      myPoint.Name = nutrient;
      myPoint.Budgted = newnoots[0][nutrient];
      myPoint.Actual = newnoots[1][nutrient];
      myPoint.SurPlus = newnoots[2][nutrient];
      myPoint.percentage = myPoint.Budgted == 0 ? 0 : (myPoint.Actual / myPoint.Budgted) * 100;
      myPoints.push(myPoint);
      csvDetails.push([nutrient, myPoint.Budgted, myPoint.Actual, myPoint.SurPlus, myPoint.percentage]);
    }

    return myPoints;
  }

  private updateChart(points: MyClusteredChart[], chartId: string) {
    AmCharts.makeChart(chartId, {
      type: 'serial',
      theme: 'light',
      categoryField: 'Name',
      rotate: false,
      startDuration: 0,
      categoryAxis: {
        gridPosition: 'start',
        position: 'left',
      },
      graphs: [
        {
          fillAlphas: 0.8,
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.BUDGETED'),
          type: 'column',
          valueField: 'Budgted',
          fillColors: '#67b7dc',
          lineColor: '#67b7dc',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = (graphDataItem as any).values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.BUDGETED') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(graphDataItem.category) === null
                    ? 3
                    : this._fertiliserService.nootDP(graphDataItem.category) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
        {
          fillAlphas: 0.8,
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.APPLIED'),
          type: 'column',
          valueField: 'Actual',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = (graphDataItem as any).values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.APPLIED') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(graphDataItem.category) === null
                    ? 3
                    : this._fertiliserService.nootDP(graphDataItem.category) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
        {
          fillAlphas: 0.8,
          lineAlpha: 0.2,
          title: `${this._languageService.instant('COMMON.SURPLUS')}/${this._languageService.instant('COMMON.SHORTFALL')}`,
          type: 'column',
          valueField: 'SurPlus',
          fillColors: '#84b761',
          lineColor: '#84b761',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = (graphDataItem as any).values.value;
            if (val) {
              return (
                this._languageService.instant('COMMON.SURPLUS') +
                ': <b>' +
                val.toFixed(
                  this._fertiliserService.nootDP(graphDataItem.category) === null
                    ? 3
                    : this._fertiliserService.nootDP(graphDataItem.category) + 1,
                ) +
                '</b>'
              );
            } else {
              return '';
            }
          },
        },
      ],
      valueAxes: [
        {
          position: 'top',
          axisAlpha: 0,
        },
      ],
      legend: {
        useGraphSettings: true,
        position: 'top',
      },
      dataProvider: points,
    } as AmCharts.AmSerialChart);
  }
}

export interface siteweeklyfert {
  fertiliserId: number;
  Budgted: number;
  Planned: number;
  Actual: number;
  nutrients: NutrientFields;
}

export interface mySiteDetails {
  siteName: string;
  mypoints: MyClusteredChart[];
}

export interface MyClusteredChart {
  SiteId: number;
  Name: string;
  Budgted: number;
  Actual: number;
  SurPlus: number;
  percentage: number;
}

export interface tankMix {
  tankmixId: number;
  siteId: number;
  siteName: string;
  Name: string;
  type: string;
  lowestPrice: number;
  highestPrice: number;
  fert: Fertiliser;
  Noot: Nutrient;
  totWeightage: number;
  totbudgtedActual: number;
  UnitsTotal: number;
}

export interface chkdState {
  tankMixId: number;
  checked: boolean;
}

angular.module('fuse').service('ReportService', ReportService);
