import * as angular from 'angular';
import * as i18next from 'i18next';
import { DateUtils, IsoWeekday } from '@indicina/swan-shared/utils/DateUtils';

export interface DialogParams {
  title: string;
  content: string;
  label: string;
  ok: string;
  cancel: string;
}

const validTranslationPrefixes = [
  'AC',
  'ADM',
  'ALLOCATION',
  'AUTH',
  'COLLECTOR',
  'COMMON',
  'CONTROLLERS',
  'CROPS',
  'DB_VALUES',
  'LOCK',
  'LOGIN',
  'MENU',
  'NUTR',
  'PROJ',
  'PROPERTIES',
  'TERMS',
  'TOOLBAR',
  'WATER',
  'WATER_BUDGETS',
];

export class TranslatedAlert implements angular.material.IAlertDialog {
  /** Wrapper class for alert dialog, applying translations to text inputs
   *  Instantiate with languageService.alert()
   *  Show with mdDialog.show(translatedAlert.dialog) or languageService.show(translatedAlert)
   */
  public _languageService: LanguageService;

  public dialog: angular.material.IAlertDialog;

  constructor(
    $mdDialog: angular.material.IDialogService,
    LanguageService: LanguageService,
  ) {
    this.dialog = $mdDialog.alert();
    this._languageService = LanguageService;
  }

  ariaLabel(ariaLabel: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.ariaLabel(this._languageService.instant(ariaLabel, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  title(title: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.title(this._languageService.instant(title, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  textContent(textContent: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.textContent(this._languageService.instant(textContent, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  htmlContent(htmlContent: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.htmlContent(this._languageService.instant(htmlContent, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  ok(ok: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.ok(this._languageService.instant(ok, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  multiple(multiple: boolean) {
    this.dialog.multiple(multiple);

    return this;
  }

  theme(theme: string) {
    this.dialog.theme(theme);

    return this;
  }

  templateUrl(templateUrl?: string) {
    this.dialog.templateUrl(templateUrl);

    return this;
  }

  template(template?: string) {
    this.dialog.template(template);

    return this;
  }

  targetEvent(targetEvent?: MouseEvent) {
    this.dialog.targetEvent();

    return this;
  }

  scope(scope?: angular.IScope) {
    this.dialog.scope();

    return this;
  }

  preserveScope(preserveScope?: boolean) {
    this.dialog.preserveScope();

    return this;
  }

  disableParentScroll(disableParentScroll?: boolean) {
    this.dialog.disableParentScroll();

    return this;
  }

  hasBackdrop(hasBackdrop?: boolean) {
    this.dialog.hasBackdrop();

    return this;
  }

  clickOutsideToClose(clickOutsideToClose?: boolean) {
    this.dialog.clickOutsideToClose(clickOutsideToClose);

    return this;
  }

  escapeToClose(escapeToClose?: boolean) {
    this.dialog.escapeToClose(escapeToClose);

    return this;
  }

  focusOnOpen(focusOnOpen?: boolean) {
    this.dialog.focusOnOpen(focusOnOpen);

    return this;
  }

  controller(controller?: string | any, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.controller(this._languageService.instant(controller, undefined, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  locals(locals?: { [index: string]: any }) {
    this.dialog.locals(locals);

    return this;
  }

  bindToController(bindToController?: boolean) {
    this.dialog.bindToController(bindToController);

    return this;
  }

  controllerAs(controllerAs?: string) {
    this.dialog.controllerAs(controllerAs);

    return this;
  }

  parent(parent?: string | JQuery | Element) {
    this.dialog.parent(parent);

    return this;
  }

  resolve(resolve?: { [index: string]: any }) {
    this.dialog.resolve(resolve);

    return this;
  }

  openFrom(from: any) {
    this.dialog.openFrom(from);

    return this;
  }

  closeTo(to: any) {
    this.dialog.closeTo(to);

    return this;
  }
}

/** As for TranslatedAlert
*/
export class TranslatedConfirm implements angular.material.IConfirmDialog {
  private _languageService: LanguageService;

  public dialog: angular.material.IConfirmDialog;

  constructor(
    $mdDialog: angular.material.IDialogService,
    LanguageService: LanguageService,
  ) {
    this.dialog = $mdDialog.confirm();
    this._languageService = LanguageService;
  }

  ariaLabel(ariaLabel: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.ariaLabel(this._languageService.instant(ariaLabel, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  cancel(cancel: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.cancel(this._languageService.instant(cancel, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  title(title: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.title(this._languageService.instant(title, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  textContent(textContent: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.textContent(this._languageService.instant(textContent, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  htmlContent(htmlContent: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.htmlContent(this._languageService.instant(htmlContent, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  ok(ok: string, params = {}, shouldInterpolationBeHTMLEscaped = true) {
    this.dialog.ok(this._languageService.instant(ok, params, shouldInterpolationBeHTMLEscaped));

    return this;
  }

  multiple(multiple: boolean) {
    this.dialog.multiple(multiple);

    return this;
  }

  theme(theme: string) {
    this.dialog.theme(theme);

    return this;
  }

  templateUrl(templateUrl?: string) {
    this.dialog.templateUrl(templateUrl);

    return this;
  }

  template(template?: string) {
    this.dialog.template(template);

    return this;
  }

  targetEvent(targetEvent?: MouseEvent) {
    this.dialog.targetEvent();

    return this;
  }

  scope(scope?: angular.IScope) {
    this.dialog.scope();

    return this;
  }

  preserveScope(preserveScope?: boolean) {
    this.dialog.preserveScope();

    return this;
  }

  disableParentScroll(disableParentScroll?: boolean) {
    this.dialog.disableParentScroll();

    return this;
  }

  hasBackdrop(hasBackdrop?: boolean) {
    this.dialog.hasBackdrop();

    return this;
  }

  clickOutsideToClose(clickOutsideToClose?: boolean) {
    this.dialog.clickOutsideToClose(clickOutsideToClose);

    return this;
  }

  escapeToClose(escapeToClose?: boolean) {
    this.dialog.escapeToClose(escapeToClose);

    return this;
  }

  focusOnOpen(focusOnOpen?: boolean) {
    this.dialog.focusOnOpen(focusOnOpen);

    return this;
  }

  controller(controller?: string) {
    this.dialog.controller(controller);

    return this;
  }

  locals(locals?: { [index: string]: any }) {
    this.dialog.locals(locals);

    return this;
  }

  bindToController(bindToController?: boolean) {
    this.dialog.bindToController(bindToController);

    return this;
  }

  controllerAs(controllerAs?: string) {
    this.dialog.controllerAs(controllerAs);

    return this;
  }

  parent(parent?: string | JQuery | Element) {
    this.dialog.parent(parent);

    return this;
  }

  resolve(resolve?: { [index: string]: any }) {
    this.dialog.resolve(resolve);

    return this;
  }

  openFrom(from: any) {
    this.dialog.openFrom(from);

    return this;
  }

  closeTo(to: any) {
    this.dialog.closeTo(to);

    return this;
  }
}

export class LanguageService {
  private _mdDateLocale: angular.material.IDateLocaleProvider;
  private _mdDialog: angular.material.IDialogService;
  private _toastr: Toastr;

  private _i18n: i18next.i18n;
  private _translate: typeof i18next.t;

  constructor(
    $mdDateLocale: angular.material.IDateLocaleProvider,
    $mdDialog: angular.material.IDialogService,
    toastr: Toastr,
  ) {
    this._mdDateLocale = $mdDateLocale;
    this._mdDialog = $mdDialog;
    this._toastr = toastr;

    this._i18n = (window as any).i18next as i18next.i18n;
    this._translate = this._i18n.t;
  }

  public setLanguage(languageCode: string): void {
    this._i18n.changeLanguage(languageCode);
  }

  public setLocale(languageCode: string, locale: string): void {
    const value = locale ?? navigator.language;

    // NOTE: the 'setLocale' must be called before the configurations below are executed.
    DateUtils.Locale.setLocale(value.startsWith(languageCode) ? value : languageCode);

    this._mdDateLocale.days = DateUtils.Locale.getWeekdayNamesLong(IsoWeekday.Sunday); // NOTE: _mdDateLocale requires Sunday as the first element of the array.
    this._mdDateLocale.months = DateUtils.Locale.getMonthNamesLong();
    this._mdDateLocale.shortDays = DateUtils.Locale.getWeekdayNamesShort(IsoWeekday.Sunday); // NOTE: _mdDateLocale requires Sunday as the first element of the array.
    this._mdDateLocale.shortMonths = DateUtils.Locale.getMonthNamesShort();
  }

  public setStartOfWeek(startOfWeek: IsoWeekday): void {
    const value = startOfWeek ?? IsoWeekday.Monday;

    DateUtils.Locale.setStartOfWeek(value);

    if (this._mdDateLocale.firstDayOfWeek !== value) {
      this._mdDateLocale.firstDayOfWeek = value;
    }
  }

  public confirm(): any {
    const dialog = new TranslatedConfirm(this._mdDialog, this);

    return dialog;
  }

  public alert(): any {
    const dialog = new TranslatedAlert(this._mdDialog, this).title('COMMON.ALERT').ok('COMMON.CLOSE');

    return dialog;
  }

  public show(dialog) {
    return this._mdDialog.show(dialog?.dialog ?? dialog);
  }

  public hide(resp = null) {
    return this._mdDialog.hide(resp);
  }

  public handleError(error, defaultError = null) {
    if (error?.data?.Message) {
      this.error(error.data.Message);
    } else if (defaultError) {
      this.error(defaultError);
    } else {
      this.whoops();
    }
  }

  public instant(input: any, params: object = {}, shouldInterpolationBeHTMLEscaped = true): string {
    if (!input) {
      return '';
    }

    let msg = '';

    if (typeof input === 'string') {
      msg = input;
    } else if (typeof input === 'object' && input.Message) {
      msg = input.Message.toString();
    } else {
      msg = input.toString();
    }

    if (msg.indexOf('MessageObjectDto') > -1) {
      try {
        const messageObj = JSON.parse(msg) as fuse.messageObjectDto;

        msg = this._translate(messageObj.tag, messageObj.parameters);
      } catch (error) {
        console.warn(`Could not translate: ${msg}`);
      }
    } else {
      const keyValidation = this.validateTranslationKey(msg);

      if (!keyValidation.isValid) {
        console.warn(`${keyValidation.reason}: [${msg}]`);

        return msg;
      }

      msg = this._translate(msg, {
        ...(params as any),
        // defaultValue: '--NO TRANSLATION--',
        interpolation: {
          escapeValue: shouldInterpolationBeHTMLEscaped,
        },
      }).toString();
    }

    return msg;
  }

  public normInstant(input: string): string {
    if (input == null) {
      return null;
    }

    const arr = input.split(',');
    const msg = arr[0].replace(/ /g, '_').replace(/"/g, '').toUpperCase();

    arr[0] = this._translate(msg);

    return arr.join(',');
  }

  public success(tag: string, title: string = 'COMMON.SUCCESS', params: object = {}) {
    const message = this.instant(tag, params);

    this.showToast('success', title, message);
  }

  public successMessage(message: string, title: string = 'COMMON.SUCCESS') {
    this.showToast('success', title, message);
  }

  public info(tag: string, title: string = 'COMMON.INFORMATION', params: object = {}) {
    const message = this.instant(tag, params);

    this.showToast('info', title, message);
  }

  public infoMessage(message: string, title: string = 'COMMON.INFORMATION') {
    this.showToast('info', title, message);
  }

  public warning(tag: string, title: string = 'COMMON.WARNING', params: object = {}) {
    const message = this.instant(tag, params);

    this.showToast('warning', title, message);
  }

  public warningMessage(message: string, title: string = 'COMMON.WARNING') {
    this.showToast('warning', title, message);
  }

  public error(tag: string, title: string = 'COMMON.ERROR', params: object = {}) {
    const message = this.instant(tag, params);

    this.showToast('error', title, message);
  }

  public errorMessage(message: string, title: string = 'COMMON.ERROR') {
    this.showToast('error', title, message);
  }

  public clearToaster() {
    this._toastr.clear();
  }

  public whoops() {
    this.error('COMMON.WHOOPS');
  }

  public basicAlert(params: DialogParams) {
    let alert = this._mdDialog.alert();

    if (params.title) {
      alert = alert.title(this.validateAndTranslate(params.title));

      if (!params.label) {
        alert = alert.ariaLabel(this.validateAndTranslate(params.title));
      }
    }

    if (params.content) {
      alert = alert.htmlContent(this.validateAndTranslate(params.content));
    }

    if (params.label) {
      alert = alert.ariaLabel(this.validateAndTranslate(params.label));
    }

    if (params.ok) {
      alert = alert.ok(this.validateAndTranslate(params.ok));
    }

    return alert;
  }

  public closeDialog(
    title: string = 'COMMON.CLOSE',
    content: string = 'COMMON.UNSAVED_CHANGES',
    label: string = 'COMMON.CONFIRM',
    okButton: string = 'COMMON.DISCARD_CHANGES',
    cancelButton: string = 'COMMON.NO_GO_BACK',
  ) {
    const confirm = this._mdDialog
      .confirm()
      .title(this.validateAndTranslate(title))
      .htmlContent(this.validateAndTranslate(content))
      .multiple(true)
      .ariaLabel(this.validateAndTranslate(label))
      .ok(this.validateAndTranslate(okButton))
      .cancel(this.validateAndTranslate(cancelButton));

    return confirm;
  }

  public warningDialog(warning: string) {
    const confirm = this._mdDialog
      .alert()
      .title(this.validateAndTranslate('COMMON.WARNING'))
      .htmlContent(this.validateAndTranslate(warning))
      .ok(this.validateAndTranslate('COMMON.OK'));

    return confirm;
  }

  public fixErrorsDialog() {
    const alert = this._mdDialog
      .alert()
      .title(this.validateAndTranslate('COMMON.ALERT'))
      .htmlContent(this.validateAndTranslate('COMMON.CORRECT_ERRORS'))
      .parent(angular.element(document.body))
      .ok(this.validateAndTranslate('COMMON.CLOSE'))
      .targetEvent();

    return alert;
  }

  public symbolKey(
    title: string = 'COMMON.SYMBOL_KEY',
    okButton: string = 'COMMON.CLOSE',
    label: string = 'COMMON.MAP_LAYER_INFO',
  ) {
    let content = '';

    content += `<table class='gridtable' style='width:585px;'><tr><td>${this.validateAndTranslate('COMMON.SYMBOL')}</td><td>`;
    content += `${this.validateAndTranslate('COMMON.DESCRIPTION')}</td></tr><tr><td> < </td><td>`;
    content += `${this.validateAndTranslate('COMMON.SELECT')} ${this.validateAndTranslate('COMMON.SYMBOL_LESS_THAN')}`;
    content += `</td></tr><tr><td>${this.validateAndTranslate('COMMON.N/A')}</td><td>`;
    content += `${this.validateAndTranslate('COMMON.SELECT')} “${this.validateAndTranslate('COMMON.N/A')}” `;
    content += `${this.validateAndTranslate('COMMON.SYMBOL_N/A')}</td></tr><tr><td> = </td><td>`;
    content += `${this.validateAndTranslate('COMMON.SELECT')} ${this.validateAndTranslate('COMMON.SYMBOL_EQUALS')}</td></tr></table>`;

    this._mdDialog.show(
      this._mdDialog
        .alert()
        .clickOutsideToClose(true)
        .parent(angular.element(document.body))
        .title(this.validateAndTranslate(title))
        .htmlContent(content)
        .ariaLabel(this.validateAndTranslate(label))
        .ok(this.validateAndTranslate(okButton))
        .targetEvent(),
    );
  }

  public showSaveSuccess() {
    this.success('COMMON.CHANGES_SAVED');
  }

  public showSaveFailure(translationInput: any = null, params = {}) {
    const errorMessage = this.instant('COMMON.CHANGES_NOT_SAVED');
    const extraMessage = this.instant(translationInput, params);
    const message = (`${errorMessage} ${extraMessage}`).trim();

    this.warningMessage(message);
  }

  public showSaveFailureMessage(extraMessage: string) {
    const errorMessage = this.instant('COMMON.CHANGES_NOT_SAVED');
    const message = (`${errorMessage} ${extraMessage}`).trim();

    this.warningMessage(message);
  }

  private validateTranslationKey(value: string): { isValid: boolean, reason: string } {
    const attemptedValue = value ?? '';

    if (!attemptedValue) {
      return { isValid: false, reason: 'Unspecified translation key' };
    }

    if (!attemptedValue.includes('.')) {
      return { isValid: false, reason: 'Unexpected translation key format' };
    }

    if (!validTranslationPrefixes.some((x) => attemptedValue.startsWith(`${x}.`))) {
      return { isValid: false, reason: 'Invalid translation key' };
    };

    if (attemptedValue.endsWith('.') || attemptedValue.includes('.undefined')) {
      return { isValid: false, reason: 'Incomplete translation key' };
    }

    return { isValid: true, reason: null };
  }

  private validateAndTranslate(value: string): string {
    const keyValidation = this.validateTranslationKey(value);

    if (!keyValidation.isValid) {
      //console.warn(`${keyValidation.reason}: [${key}]`);

      return value;
    }

    return this._translate(value);
  }

  private showToast(type: 'success' | 'info' | 'warning' | 'error', title: string, message: string) {
    switch (type) {
      case 'success':
        this._toastr.success(message, this.validateAndTranslate(title));
        break;

      case 'info':
        this._toastr.info(message, this.validateAndTranslate(title));
        break;

      case 'warning':
        this._toastr.warning(message, this.validateAndTranslate(title));
        break;

      case 'error':
        this._toastr.error(message, this.validateAndTranslate(title));
        break;
    }
  }
}

angular.module('fuse').service('LanguageService', LanguageService);
