import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from './data-entity.service';
import { LanguageService } from './language.service';
import { UnitOfMeasureService } from './unit-of-measure.service';
import { PropertyValue } from '../_DBContext/PropertyValue';

export class SensorPropertyService {
  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _unitOfMeasureService: UnitOfMeasureService;
  private _languageService: LanguageService;

  private _entityManager: breeze.EntityManager;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    DataEntityService: DataEntityService,
    UnitOfMeasureService: UnitOfMeasureService,
    LanguageService: LanguageService,
  ) {
    this._http = $http;
    this._q = $q;
    this._unitOfMeasureService = UnitOfMeasureService;
    this._languageService = LanguageService;

    this._entityManager = DataEntityService.manager;
  }

  public collectorProperties: PropertyValue[];

  // WorkAgentCollector Properties and Asset PropertyValues have to be loaded separately, as values will not exist when WorkAgentCollector first assigned/changed.
  // PropertyValues are also loaded as breeze objects by Equipment controller and as fuse.property objects by DataCollectorProfile, while Properties are fuse objects for both,
  // so associating everything gets complicated.
  private buildProperties(
    properties: fuse.property[],
    propVals: PropertyValue[] | fuse.propertyValue[],
    assetId: number,
    saveAsBreeze = true,
  ) {
    const propValType = this._entityManager.metadataStore.getEntityType('PropertyValue') as breeze.EntityType;
    const collectorProperties = [] as PropertyValue[];

    properties.forEach((prop) => {
      let val = (propVals as PropertyValue[])?.find((pv) => pv.PropertyId == prop.id);

      if (!val) {
        val = propValType.createEntity({ AssetId: assetId, PropertyId: prop.id }) as PropertyValue;

        const fuseVal = (propVals as fuse.propertyValue[])?.find((pv) => pv.propertyId == prop.id);
        if (fuseVal) {
          val.Id = fuseVal.id;
          val.Value = fuseVal.value;
        }
        // Data collection profile uses breeze objects for validation/consistency, but doesn't use breeze to save, so add only if necessary.
        if (saveAsBreeze) this._entityManager.addEntity(val);
      }

      val.setProperties(prop, this._languageService, this._unitOfMeasureService); // Property should be assignable to val.property, but we hit compatability issues between breeze/non-breeze loaded objects, so workaround

      collectorProperties.push(val);
    });

    // If is type PropertyValue[] (request came from equipment, not account settings), check for any old properties that need deleting because we've changed the collector
    if (propVals?.[0]?.hasOwnProperty('entityAspect')) {
      this.deleteOldProperties(properties, propVals as PropertyValue[]);
    }

    return collectorProperties;
  }

  public deleteOldProperties(properties: fuse.property[], propVals: PropertyValue[]) {
    const ids = properties.map((p) => p.id);

    propVals.filter((pv) => !ids.includes(pv.PropertyId)).forEach((pv) => {
      pv.entityAspect.setDeleted();
    });
  }

  public loadCollectorProperties(
    workAgent: fuse.dataCollectorProfileDto,
    propVals: PropertyValue[],
    assetId: number,
    isAsset = true,
  ) {
    const defer = this._q.defer();
    const params = {
      workAgentCollectorId: workAgent.workAgentCollectorId,
      isAsset: isAsset,
    };

    this._http
      .get(CommonHelper.getApiUrl('administration/getWorkAgentCollectorProperties/'), { params: params })
      .then((response) => {
        const metadata = response.data as fuse.property[];
        const properties = this.buildProperties(metadata, propVals, assetId, isAsset);

        defer.resolve(properties);
      })
      .catch(() => {
        this._languageService.whoops();

        defer.reject();
      });

    return defer.promise;
  }
}

angular.module('fuse').service('SensorPropertyService', SensorPropertyService);
