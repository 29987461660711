import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { ObjectUtils } from '@indicina/swan-shared/utils/ObjectUtils';
import { ApiService } from './api.service';

class PropertyErrorDialogController {
  title: string;
  offlineErrors: object;
  controllerErrors: object;
  assetErrors: object;

  private _mdDialog: angular.material.IDialogService;
  private _errors: any;

  constructor(
    $mdDialog: angular.material.IDialogService,
    errors
  ) {
    this._mdDialog = $mdDialog;
    this._errors = errors;

    this.title = `CONTROLLERS.${!errors['offline'] ? 'INVALID_CONFIG' : 'OFFLINE'}`;
    this.offlineErrors = errors['offline'];
    this.controllerErrors = this._errors['controller'];
    this.assetErrors = ObjectUtils.getObjectWithExcludedKeys(this._errors, ['controller', 'offline']);
  }

  public closeDialog() {
    this._mdDialog.hide();
  };
}

export class IrrigationControllerService {
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _apiService: ApiService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $state: angular.ui.IStateService,
    ApiService: ApiService,
  ) {
    this._mdDialog = $mdDialog;
    this._state = $state;
    this._apiService = ApiService;
  }

  public checkAndGotoController(assetId: number, afterFail: Function = null) {
    const handleResponse = (result) => {
      if (result) {
        const alert = {
          locals: {
            errors: result,
          },
          templateUrl: 'src/app/pages/water/irrigation-controller/property-error-dialog.html',
          controller: PropertyErrorDialogController,
          controllerAs: 'vm',
          parent: angular.element(document.body),
        };

        this._mdDialog.show(alert).then(() => {
          this._mdDialog.hide();

          afterFail?.();
        });

        if (result['offline']) {
          this.gotoDetail(assetId);
        }
      } else {
        this.gotoDetail(assetId);
      }
    }

    this._apiService.getGeneric(
      'controllers/validateController',
      { controllerId: assetId },
      handleResponse,
      'CONTROLLERS.DATA_ERROR',
    );
  }

  public gotoDetail(assetId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.assetId = assetId;
    });

    this._state.go('app.water.irrigation-controllers.detail', { id: assetId });
  }
}

angular.module('fuse').service('IrrigationControllerService', IrrigationControllerService);
