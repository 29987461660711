import * as angular from 'angular';
import { StatusFilter } from './entity-list.service';

export class FilterService {
  public filters = {};
  public statusFilters = FilterService.DefaultStatusFilter;

  private _keepFilter = false;

  constructor() {}

  public static get DefaultStatusFilter() {
    return { Active: true, Archived: false, Suspended: false } as StatusFilter;
  }

    public static get AllStatusesFilter() {
    return { Active: true, Archived: true, Suspended: true } as StatusFilter;
  }

  get keepFilter(): boolean {
    return this._keepFilter;
  }

  public setKeepFilter(value: boolean): void {
    this._keepFilter = value;
  }

  public saveFilters(filters: any, statusFilters: StatusFilter): void {
    this.filters = filters;
    this.statusFilters = statusFilters;
  }

  public clearFilters(): void {
    this.filters = {};
    this.statusFilters = FilterService.DefaultStatusFilter;
  }
}

angular.module('fuse').service('FilterService', FilterService);
