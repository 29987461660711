import * as angular from 'angular';
import { DataEntityService } from './data-entity.service';

export class AssetClassService {
  private _q: angular.IQService;

  private _entityManager: breeze.EntityManager;

  constructor(
    $q: angular.IQService,
    DataEntityService: DataEntityService,
  ) {
    this._q = $q;

    this._entityManager = DataEntityService.manager;
  }

  public _fetchAssetClass(assetClassName: string): angular.IPromise<breeze.QueryResult> {
    const pred = breeze.Predicate.create('Name', breeze.FilterQueryOp.Equals, assetClassName);
    const defer = this._q.defer();

    const successCallback = (data) => {
      defer.resolve(data);
    };
    const failCallback = () => {
      defer.reject('Fail');
    };

    breeze.EntityQuery.from('AssetClass').where(pred).using(this._entityManager).execute().then(successCallback, failCallback);

    return defer.promise as angular.IPromise<breeze.QueryResult>;
  }

  public _fetchAllAssetClasses(): angular.IPromise<breeze.QueryResult> {
    const defer = this._q.defer();

    breeze.EntityQuery.from('AssetClass')
      .using(this._entityManager)
      .execute()
      .then(
        (data) => {
          defer.resolve(data);
        },
        () => {
          defer.reject('Fail');
        },
      );

    return defer.promise as angular.IPromise<breeze.QueryResult>;
  }
}

angular.module('fuse').service('AssetClassService', AssetClassService);
