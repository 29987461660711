import * as angular from 'angular';
import { IIdNameItem } from '@common/models/interfaces';

angular.module('fuse').filter('providerName', () => {
  return (input: string | number, values: IIdNameItem[]): string | number => {
    if (input == null || input == undefined) return null;
    const output = values.find((a) => a.id == input).name;
    return output;
  };
});
