import * as angular from 'angular';

export class ColourSetService {
  private colorSet = [
    '#dd0000',
    '#0000ff',
    '#f27600',
    '#d000b9',
    '#00aeff',
    '#ff006e',
    '#00dcc0',
    '#9b00de',
    '#00ff00',
    '#0066ff',
    '#e29700',
    '#00c6ff',
    '#f00093',
    '#7d8e00',
    '#0090ff',
    '#fc4f00',
    '#cab500',
    '#000000',
  ];
  private gradiants = [1, 0.6, 0.4, 0.2];
  private currentIndex: number;
  private gradianrIndex: number;

  constructor() {
    this.currentIndex = 0;
    this.gradianrIndex = 0;
  }

  public reset(): void {
    this.currentIndex = 0;
  }

  public resetGradiant(): void {
    this.gradianrIndex = 0;
  }

  public getColor(): string {
    if (this.currentIndex >= this.colorSet.length - 1) this.currentIndex = this.colorSet.length - 1;
    const currentColor = this.colorSet[this.currentIndex];
    this.currentIndex++;
    return currentColor;
  }

  public getColorByIndex(index: number): string {
    if (index >= this.colorSet.length - 1) index = this.colorSet.length - 1;
    return this.colorSet[index];
  }

  public getColorInLoop(index: number): string {
    return this.colorSet[index % this.colorSet.length];
  }

  public getGradiant(): number {
    if (this.gradianrIndex >= this.gradiants.length - 1) this.gradianrIndex = this.gradiants.length - 1;
    const gradiant = this.gradiants[this.gradianrIndex];
    this.gradianrIndex++;
    return gradiant;
  }

  public getGradiantByIndex(index: number): number {
    if (index >= this.gradiants.length - 1) index = this.gradiants.length - 1;
    return this.gradiants[index];
  }
}

angular.module('fuse').service('ColourSetService', ColourSetService);
