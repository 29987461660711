import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { FertDistribution, ApplicationInstance, TargetRequirement } from '@common/nutrients.interface';
import { CalculatedResults } from '../../pages/nutrients/sampling/sample.detail.controller';
import { Budget } from 'src/app/_DBContext/Budget';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { NutrientSetting } from 'src/app/_DBContext/NutrientSetting';
import { SiteSettingsNutrients } from 'src/app/_DBContext/SiteSettingsNutrients';
import { DayNumberService } from '@services/day-number.service';

export interface SelectedSamplePointRow {
  Percentage: number;
  SamplePoint: Asset;
  sampleBlend: CalculatedResults;
}

export class ProgramService {
  public programName: string;
  public waterKLPerHa: number;
  public startDate: Date;
  public endDate: Date;
  public saturdayEndDate: Date;
  public numOfWeeks: number = 0;
  public selectedGroups: Asset[] = [];
  public selectedNutrientProfile: Asset;
  public budgetNutrientProfile: Asset = null;
  public nutrientSettings: NutrientSetting[] = [];
  public selectedSamplePoints: Asset[] = [];
  public selectedSamplePointRows: SelectedSamplePointRow[] = [];
  public plannedApplicationSites: Asset[] = [];
  public nutVariation: NutrientVariations[] = [];
  public fertPrices: IFertiliserPrices[] = [];
  public existingSiteSettingsNutrients: SiteSettingsNutrients[] = [];

  public applicationInstances: ApplicationInstance[] = [];
  public hasDistributionProfilePageLoaded: boolean = false;

  public budgetToReload: Budget;
  public selectedFerts: Fertiliser[] = [];
  public reloadNutrientSettingsCounter: number = 0;
  public targetFertRequirements: TargetRequirement;
  public totalNutrientTarget: TargetRequirement;
  public nutrientsInWater: TargetRequirement;
  public fertDistributions: FertDistribution[] = [];
  public reloadedFromBudget: boolean = false;

  private _dayNumberService: DayNumberService;

  constructor(
    DayNumberService: DayNumberService,
  ) {
    this._dayNumberService = DayNumberService;
  }

  public validDistributionProfile(): boolean {
    const result = this.nutrientSettings.length <= this.numOfWeeks;

    return result;
  }

  public getLastSiteNutrientSetting(assetId: number, effectiveStartDate: Date): SiteSettingsNutrients {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(effectiveStartDate);
    const ds = this.existingSiteSettingsNutrients.filter((x) => x.AssetId == assetId);
    const lastSiteNutrientSetting = ds.find((a) => a.EffectiveFromDay <= dayNumber && a.EffectiveToDay >= dayNumber);
    return lastSiteNutrientSetting;
  }
}

export interface NutrientVariations {
  NutrientName: string;
  IsOriginal: boolean;
}
export interface IFertiliserPrices {
  FertiliserId: number;
  LowestPriceSupplier: string;
  HighestPriceSupplier: string;
  LowPrice: number;
  HighPrice: number;
}

angular.module('fuse').service('ProgramService', ProgramService);
