import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { FilterService } from './filter.service';
import { LanguageService } from './language.service';

export class GroupSiteService extends FilterService {
  private _q: angular.IQService;
  private _http: angular.IHttpService;
  private _state: angular.ui.IStateService;
  private _languageService: LanguageService;
  private _isKeepFilter: boolean;
  private _nameFilter: string;
  private _typeFilter: string;
  private _activeState: boolean;
  private _archivedState: boolean;
  private _suspendedState: boolean;

  public groupClassFilter = 'assetClass:='; // need an exact match on class, not just any class with 'GROUP' in text

  constructor(
    $q: angular.IQService,
    $http: angular.IHttpService,
    $state: angular.ui.IStateService,
    LanguageService: LanguageService,
  ) {
    super();
    this._q = $q;
    this._http = $http;
    this._state = $state;
    this._languageService = LanguageService;

    this._isKeepFilter = false;
    this._nameFilter = '';
    this._typeFilter = '';
    this._activeState = true;
    this._archivedState = false;
    this._suspendedState = false;
  }

  public RolloverSiteKcSettings(rolloverDto: fuse.siteSettingsRolloverDto): angular.IPromise<any> {
    const defer = this._q.defer();
    this._http
      .post<any>(CommonHelper.getApiUrl('data/sites/RolloverSiteSettings/'), rolloverDto)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  public getKeepFilter(): boolean {
    return this._isKeepFilter;
  }

  public setKeepFilter(isKeepFilter: boolean): void {
    this._isKeepFilter = isKeepFilter;
  }

  public getNameFilter(): string {
    return this._nameFilter;
  }

  public setNameFilter(nameFilter: string): void {
    this._nameFilter = nameFilter;
  }

  public getTypeFilter(): string {
    return this._typeFilter;
  }

  public setTypeFilter(typeFilter: string): void {
    this._typeFilter = typeFilter;
  }

  public getActiveState(): boolean {
    return this._activeState;
  }

  public setActiveState(state: boolean): void {
    this._activeState = state;
  }

  public getArchivedState(): boolean {
    return this._archivedState;
  }

  public setArchivedState(state: boolean): void {
    this._archivedState = state;
  }

  public getSuspendedState(): boolean {
    return this._suspendedState;
  }

  public setSuspendedState(state: boolean): void {
    this._suspendedState = state;
  }

  public gotoGroups() {
    this.setKeepFilter(true);
    this._state.go('app.account.groups');
  }

  public gotoGroupDetail(group: fuse.groupListDto) {
    LocalStorageUtils.updateContextData((context) => {
      context.groupId = group.id;
      context.assetId = group.id;
    });

    this.setKeepFilter(true);

    this._state.go(group.isSiteGroup ? 'app.account.groups.detail' : 'app.account.assetgroups.detail', { id: group.id });
  }

  public getAssetNameWithDetails(asset: fuse.groupAssetDto) {
    let typeSuffix = this._languageService.instant('COMMON.ASSET_CLASS.' + asset.assetClassCode);

    if (asset.status != null && asset.status !== 'Active') {
      typeSuffix += ', ' + this._languageService.instant('COMMON.' + asset.status);
    }

    return `${asset.name} (${typeSuffix})`;
  }
}

angular.module('fuse').service('GroupSiteService', GroupSiteService);
