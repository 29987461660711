import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from './language.service';
import { DupeHandlerService } from './dupe-handler.service';

export class ApiService {
  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _languageService: LanguageService;
  private _dupeHandlerService: DupeHandlerService;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    LanguageService: LanguageService,
    DupeHandlerService: DupeHandlerService,
  ) {
    this._http = $http;
    this._q = $q;
    this._languageService = LanguageService;
    this._dupeHandlerService = DupeHandlerService;
  }

  public get(route: string): any {
    return this._http.get(CommonHelper.getApiUrl(route));
  }

  public post(route: string, data: any): any {
    return this._http.post(CommonHelper.getApiUrl(route), data);
  }

  /**
   * Generic GET request
   *
   * @param route API endpoint - eg. "api/data/assetNames"
   * @param params Request parameters - eg. { assetId: 1, accountId: 2}
   * @param successFunc Function to run on successful request - usually to assign result to local variable, etc. Takes response.data as input.
   * @param errorFunc Function to run or string to display with toastr pop-up if error encountered.
   * @param httpCanceller Request queue object - can be used to cancel previous requests.
   */
  public getGeneric(
    route: string,
    params = null,
    successCallback: Function | string = null,
    failCallback: Function | string | boolean = null,
    httpCanceller: angular.IDeferred<any> = null,
  ): any {
    if (httpCanceller) {
      httpCanceller.resolve('Request cancelled');
    }

    httpCanceller = this._q.defer();

    this._http
      .get(CommonHelper.getApiUrl(route), { params: params, timeout: httpCanceller.promise })
      .then((response) => {
        if (successCallback) {
          this.handleResponse(false, response.data, successCallback);
        }

        httpCanceller.resolve();
      })
      .catch((reason) => {
        if (reason?.config?.timeout?.$$state?.value == 'Request cancelled') {
          return;
        }

        this.handleResponse(true, reason, failCallback);
        httpCanceller.reject(reason);
      });

    return httpCanceller;
  }

  /**
   * Generic POST request
   *
   * @param route API endpoint - eg. "api/data/assetNames"
   * @param body Request body - eg. { assetId: 1, accountId: 2}
   * @param successFunc Function to run on successful request - usually to assign result to local variable, etc. Takes response.data as input.
   * @param errorFunc Function to run or string to display with toastr pop-up if error encountered.
   */
  public postGeneric(
    route: string,
    body = null,
    successCallback: Function | string = null,
    failCallback: Function | string | boolean = null,
  ): any {
    const defer = this._q.defer<void>();

    this._http
      .post(CommonHelper.getApiUrl(route), body)
      .then((response) => {
        if (successCallback) {
          this.handleResponse(false, response.data, successCallback);
        }

        defer.resolve();
      })
      .catch((reason) => {
        this.handleResponse(true, reason, failCallback);
        defer.reject(reason);
      });

    return defer.promise;
  }

  public postHandleDupes(route: string, body = null, successCallback: Function | string = null, assetType: string) {
    this._dupeHandlerService.setDuplicateType(assetType);
    this.postGeneric(route, body, successCallback, this._dupeHandlerService.showBadRequest);
  }

  private handleResponse(error = false, payload: any, action: Function | string | boolean = null) {
    if (!action) {
      return;
    }

    if (action === true) {
      // REVIEW: This assumes specific object shape, however `data` property might not always exist.
      action = payload.data?.Message as string;
    }

    if (typeof action == 'string') {
      if (error) {
        this._languageService.error(action);
      } else {
        this._languageService.success(action);
      }
    }

    if (typeof action == 'function') {
      action(payload);
    }
  }
}

angular.module('fuse').service('ApiService', ApiService);
