import * as angular from 'angular';
import Bugsnag, { Client as BugSnagClient } from '@bugsnag/js'
import { init as initFullStory } from '@fullstory/browser';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { IsoWeekday } from '@indicina/swan-shared/utils/DateUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { AccountInfo, IContextData } from '@common/models/interfaces';
import { AuthZeroService } from '@services/auth-zero.service';
import { ColourSchemeService } from '@services/colour-scheme.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService, AccountModel } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';

export class InitialisationService {
  public static BugSnagClient: BugSnagClient;

  public isInitialised: boolean;

  private static userInitData: fuse.userInitialiseDto;

  private _isReInitialising: boolean;
  private _isBrowserSupported: boolean;

  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _rootScope: angular.IRootScopeService;
  private _sce: angular.ISCEService;
  private _http: angular.IHttpService;
  private _location: angular.ILocationService;
  private _timeout: angular.ITimeoutService;
  private _authZeroService: AuthZeroService;
  private _colourSchemeService: ColourSchemeService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _permissionService: PermissionService;
  private _unitOfMeasureService: UnitOfMeasureService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $state: angular.ui.IStateService,
    $rootScope: angular.IRootScopeService,
    $sce: angular.ISCEService,
    $http: angular.IHttpService,
    $location: angular.ILocationService,
    $timeout: angular.ITimeoutService,
    AuthZeroService: AuthZeroService,
    ColourSchemeService: ColourSchemeService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    this._mdDialog = $mdDialog;
    this._state = $state;
    this._rootScope = $rootScope;
    this._sce = $sce;
    this._http = $http;
    this._location = $location;
    this._timeout = $timeout;
    this._authZeroService = AuthZeroService;
    this._colourSchemeService = ColourSchemeService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._permissionService = PermissionService;
    this._unitOfMeasureService = UnitOfMeasureService;
  }

  public static get selectedLanguageCode(): string {
    return InitialisationService.userInitData?.languageCode;
  }

  public static get selectedLocale(): string {
    return InitialisationService.userInitData?.locale;
  }

  public static get selectedStartOfWeek(): IsoWeekday {
    return InitialisationService.userInitData?.startOfWeek;
  }

  public async init(isBrowserSupported = this._isBrowserSupported): Promise<void> {
    this.isInitialised = false;
    this._isBrowserSupported = isBrowserSupported;

    if (!LocalStorageUtils.contextData) {
      // Create an empty 'contextData' object in the local storage if it does not exist.
      LocalStorageUtils.setContextData({} as unknown as IContextData);
    }

    try {
      const { data: userInitData } = await this._http.get<fuse.userInitialiseDto>(CommonHelper.getApiUrl('users/initialise'));

      await this.initialise(userInitData);
    } catch (err) {
      console.error(err);

      const stateParams =
        err.data == null
          ? { isApiError: true }
          : { isOrphanUser: true };

      this.redirectToLogin(stateParams);
    }
  }

  public async reInit() {
    this._isReInitialising = true;

    await this.init();

    this._isReInitialising = false;
  }

  public updateUserInitData(updateFn: (x: fuse.userInitialiseDto) => void): void {
    if (!InitialisationService.userInitData) {
      return;
    }

    updateFn(InitialisationService.userInitData);
  }

  public setLanguage(languageCode: string): void {
    // Sync 'languageCode' to cached 'userInitData' in the service instance.
    this.updateUserInitData(x => x.languageCode = languageCode);

    // Switch to a new language.
    this._languageService.setLanguage(languageCode);
  }

  public setLocale(locale: string): void {
    const context = LocalStorageUtils.contextData;
    const value = locale ?? context.locale ?? navigator.language;

    if (context.locale == null || context.locale !== value) {
      LocalStorageUtils.updateContextData((context) => {
        context.locale = value;
      });
    }

    // Sync 'locale' to cached 'userInitData' in the service instance.
    this.updateUserInitData(x => x.locale = value);

    // Switch to a new locale.
    this._languageService.setLocale(InitialisationService.userInitData.languageCode, value);
  }

  public setStartOfWeek(startOfWeek: IsoWeekday): void {
    const context = LocalStorageUtils.contextData;
    const value = startOfWeek ?? context.startOfWeek ?? IsoWeekday.Monday;

    if (context.startOfWeek == null || context.startOfWeek !== value) {
      LocalStorageUtils.updateContextData((context) => {
        context.startOfWeek = value;
      });
    }

    // Sync 'startOfWeek' to cached 'userInitData' in the service instance.
    this.updateUserInitData(x => x.startOfWeek = value);

    // Switch to a new start of week.
    this._languageService.setStartOfWeek(value);
  }

  private async initialise(userInitData: fuse.userInitialiseDto): Promise<void> {
    const cleanLocalStorage = (): void => {
      const cleanLists = () => {
        const legacyGroupList = this._localStorageService.get('groupList') as AccountInfo[];

        if (!legacyGroupList) {
          return;
        }

        const cleanAccountList = legacyGroupList?.map((x) => ({
          // Convert any legacy property names to current.
          accountId: x['acct'] ?? x.accountId,
          groupId: x['groupID'] ?? x.groupId,
          logoBase64: x.logoBase64,
          mapLatitude: x.mapLatitude,
          mapLongitude: x.mapLongitude,
          mapZoom: x.mapZoom,
          waterGroupId: x['waterGroupID'] ?? x.waterGroupId,
        }) as AccountInfo);

        const uniqueAndSortedAccountList = ArrayUtils.sortByNumber(
          ArrayUtils.distinct(cleanAccountList, (x, y) => x.accountId === y.accountId),
          x => x.accountId
        );

        this._localStorageService.set('accountList', uniqueAndSortedAccountList);
      };

      const cleanObsoleteKeys = () => {
        const obsoleteKeys = [
          'Acknowledgements',
          'SWANversion',
          'announceDismissed',
          'currentAccount',
          'currentAccountId',
          'groupList',
          'selectedLanguage',
          'userInitData',
        ];

        const cleanIfApplicable = (key: string) => {
          if (this._localStorageService.get(key)) {
            this._localStorageService.remove(key);
          }
        };

        obsoleteKeys.forEach((key) => cleanIfApplicable(key));
      };

      cleanLists();
      cleanObsoleteKeys();
    };

    const hasUserAnyPermissions = (): boolean => {
      const hasPermissions = !!userInitData.permissions.length;

      if (!hasPermissions) {
        this.redirectToLogin({ isOrphanUser: true });
      }

      return hasPermissions;
    };

    const hasTermsAndConditionsAcceptance = (): boolean => {
      if (!this._isReInitialising && !userInitData.termsAcceptanceDate) {
        this.redirectToLogin({ isUnacceptedTC: true, route: CommonHelper.getRoutePath() });

        return false;
      }

      return true;
    };

    const configureAppForUser = (): void => {
      const setConstants = (): void => {
        SWANConstants.alertRefreshMinutes = userInitData.swanSettings.alertRefreshMinutes;
        SWANConstants.alertTypes = userInitData.alertTypes;
        SWANConstants.assetClasses = userInitData.assetClasses;
        SWANConstants.currencyCodes = userInitData.currencyCodes;
        SWANConstants.dataInputAssetClasses = userInitData.dataInputAssetClasses;
        SWANConstants.dataInputs = userInitData.dataInputs;
        SWANConstants.dataProviders = userInitData.dataProviders;
        SWANConstants.units = userInitData.units;
        SWANConstants.uomPreferences = userInitData.uomPreferences;
      };

      const configureAncilaryServices = (): void => {
        const setupDelightSurvey = (): void => {
          if (this._permissionService.isNonProdEnvironment) {
            return;
          }

          const survey = {
            createdAt: new Date(),
            email: this._permissionService.email,
            name: this._permissionService.fullName,
            properties: {
              locale: InitialisationService.selectedLanguageCode,
            },
          };

          window['delighted'].survey(survey);
        };

        const setupFullStory = (): void => {
          if (this._permissionService.isLocalEnvironment) {
            return;
          }

          initFullStory({ orgId: 'o-1RWZBZ-na1' });
        };

        const setupBugSnag = (): void => {
          if (this._permissionService.isLocalEnvironment) {
            return;
          }

          InitialisationService.BugSnagClient = Bugsnag.start({
            apiKey: 'fc77c777788bc91e077de5f555e5cf60',
            appVersion: AppSettings.SWANversion,
            redactedKeys: [
              'access_token', // exact match: "access_token"
              /^password$/i,  // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
              /^cc_/          // prefix match: "cc_number" "cc_cvv" "cc_expiry"
            ],
            releaseStage: AppSettings.app.envCode,
            user: {
              email: this._permissionService.email,
              id: this._permissionService.email,
              name: this._permissionService.fullName,
            },
            onError: async (event) => {
              const fs = window['FS'];
              const sesionUrl = await fs('getSessionAsync');

              if (sesionUrl) {
                event.addMetadata('fullstory', {
                  urlAtTime: sesionUrl,
                })
              }
            }
          });
        };

        this._permissionService.fullName = userInitData.fullName;
        this._permissionService.email = this._authZeroService.getEmail();
        this._permissionService.isSuperUser = userInitData.isSuperUser;

        setupDelightSurvey();
        setupFullStory();
        setupBugSnag();
      };

      const setUserPreferences = (): void => {
        this.setLanguage(userInitData.languageCode);
        this.setLocale(userInitData.locale);
        this.setStartOfWeek(userInitData.startOfWeek);

        this._colourSchemeService.setColourSchemes(userInitData.colourSchemes as fuse.colourSchemes);

        this._permissionService.setAvailableAccounts(userInitData.permissions as AccountModel[]);
        this._permissionService.setAccountPermissions();

        this._unitOfMeasureService.setUnits(userInitData.uomPreferences);
      };

      setConstants();
      configureAncilaryServices();
      setUserPreferences();

      this.isInitialised = true;
    };

    const checkBrowserSupport = async (): Promise<void> => {
      if (!this._isReInitialising && !this._isBrowserSupported) {
        const browserNotSupportedDialog = this._languageService.warningDialog('LOGIN.SWAN_BROWSER');

        CommonHelper.UI.SplashScreen.hide(this._rootScope);

        // Show the warning dialog and proceed with the initialisation after confirmation.
        await this._mdDialog.show(browserNotSupportedDialog);

        CommonHelper.UI.SplashScreen.show(this._rootScope);
      }
    };

    const shouldShowAnnouncementIfAny = (): boolean => {
      let announcement = userInitData.swanSettings.announcement;

      if (!announcement) {
        return false;
      }

      const shouldShowAnnouncement = (): boolean => {
        const isAnnouncementPending = (): boolean => {
          const shouldShowAnnouncements = userInitData.swanSettings.shouldShowAnnouncements ?? false;
          const fromDate = new Date(userInitData.swanSettings.displayFrom);
          const toDate = new Date(userInitData.swanSettings.displayTo);

          if (!fromDate || !toDate) {
            return false;
          }

          const isWithinDateRange = (date: Date): boolean => date > fromDate && date < toDate;
          let dismissedDate = this._localStorageService.get('announcementDismissedDate');

          if (!dismissedDate && dismissedDate < fromDate) {
            dismissedDate = null;
          }

          const wasDismissed = isWithinDateRange(dismissedDate)

          return shouldShowAnnouncements && (!dismissedDate || !wasDismissed && isWithinDateRange(new Date()));
        };

        if (!isAnnouncementPending()) {
          return false;
        }

        const announceDates = announcement.match(/{{(.*?)}}/g);
        let shouldShow = false;

        if (announceDates?.length) {
          announceDates.forEach((announceDate) => {
            const dateString = announceDate.replace(/{|}/g, '');
            const date = new Date(dateString + 'Z');

            if (date && Number.isFinite(date.getTime())) {
              announcement = announcement.replace(announceDate, date.toString('hh:mm tt dd-MMM-yyyy'));
              shouldShow = true;
            }
          });
        } else {
          shouldShow = true;
        }

        return shouldShow;
      };


      if (shouldShowAnnouncement()) {
        this.redirectToLogin({ announcement: this._sce.trustAsHtml(announcement), route: CommonHelper.getRoutePath() });

        return true;
      }

      return false;
    };

    const goToTargetRoute = (): void => {
      const isRedirectFromAuthentication = CommonHelper.isRedirectFromAuthentication();
      const isBaseRoute = CommonHelper.isBaseRoute();

      if (isRedirectFromAuthentication || isBaseRoute) {
        this._state.go('app.account.dashboard');
      } else {
        // Return the user to the page they were on if they hit refresh.
        const route = CommonHelper.getRoutePath();

        this._location.path(`/${route}`);
      }
    };

    InitialisationService.userInitData = userInitData;

    cleanLocalStorage();

    if (!hasUserAnyPermissions()) {
      return;
    }

    if (!hasTermsAndConditionsAcceptance()) {
      return;
    }

    configureAppForUser();

    await checkBrowserSupport();

    if (shouldShowAnnouncementIfAny()) {
      return; // Announcement shown, so the code flow proceeds from that page.
    }

    CommonHelper.UI.SplashScreen.remove(this._rootScope);

    goToTargetRoute();
  }

  private redirectToLogin(params: Record<string, unknown>): void {
    this._timeout(() => {
      this._state.go('app.auth.login', params);
    }, 2000); // Delay the redirection in attempt to avoid state transition clash with active state transition.
  };
}

angular.module('fuse').service('InitialisationService', InitialisationService);