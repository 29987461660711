import * as angular from 'angular';
import { StatusEnum } from '@indicina/swan-shared/enums/StatusEnum';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LanguageService } from './language.service';
import { UnitOfMeasureService, uomUnit } from './unit-of-measure.service';
import { DayNumberService } from './day-number.service';
import { AmGraphDataValues, UnitTypes, unitSizes } from '@common/enums';
import { ApiService } from './api.service';
import { DataEntityService } from './data-entity.service';

export interface SubAllocation extends fuse.subAllocationDto {
  effectiveFromDate: Date;
  effectiveToDate: Date;
  selected: boolean;
}

export interface Transaction extends fuse.obsAllocationDto {
  date: Date;
  subTotal: number;
}

export interface IUsageSummaryChartItem {
  date: string;
  dayNumber: number;
  yearMonth: string;
  budget: number;
  runningBudget: number;
  used: number;
  runningUsed: number;
  allocationVolume: number;
}

export interface IUsageSubChartItem {
  usedOver: number;
  usedUnder: number;
  allocationVolume: number;
}

export class AllocationService {
  public volumeLargeUnit: uomUnit;
  public volumeHugeUnit: uomUnit;

  private _apiService: ApiService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  constructor(
    ApiService: ApiService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    this._apiService = ApiService;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.initUnits();
  }

  public initUnits() {
    this.volumeLargeUnit = this._unitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.large);
    this.volumeHugeUnit = this._unitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.huge);
  }

  public getAllocations(after: Function): angular.IPromise<void> {
    const after1 = (res: fuse.allocationDto[]) => {
      res.forEach((alloc) => this.setAllocationDates(alloc));
      after(res);
    };

    return this._apiService.getGeneric('allocation/allocations', null, after1).promise;
  }

  public getAllocation(assetId: number, after: Function): angular.IPromise<void> {
    const after2 = (res: fuse.allocationDto) => {
      this.setAllocationDates(res);
      this.setRelatedDates(res);

      after(res);
    };

    return this._apiService.getGeneric('allocation/allocation', { assetId }, after2).promise;
  }

  public dayToDate(dayNumber: number) {
    return this._dayNumberService.convertDayNumberToLocaleDate(dayNumber);
  }

  public dateToDay(date: Date) {
    return this._dayNumberService.convertLocaleDateToLocaleDayNumber(date);
  }

  public setRelatedDates(alloc: fuse.allocationDto) {
    (alloc.obsAllocations as Transaction[]).forEach((transaction) => {
      transaction.date = this.dayToDate(transaction.dayNumber);
    });

    (alloc.monthBudgets as SubAllocation[]).forEach((month) => {
      month.effectiveFromDate = this.dayToDate(month.fromDayNumber);
    });

    (alloc.subAllocations as SubAllocation[]).forEach((sub) => {
      sub.effectiveFromDate = this.dayToDate(sub.fromDayNumber);
      sub.effectiveToDate = this.dayToDate(sub.toDayNumber);
    });
  }

  public updateRelatedDayNumbers(alloc: fuse.allocationDto) {
    (alloc.subAllocations as SubAllocation[]).forEach((sub) => {
      sub.fromDayNumber = this.dateToDay(sub.effectiveFromDate);
      sub.toDayNumber = this.dateToDay(sub.effectiveToDate);
    });
    (alloc.obsAllocations as Transaction[]).forEach((t) => {
      t.dayNumber = this.dateToDay(t.date);
    });
  }

  // Set alloc from/to dates from day numbers
  public setAllocationDates(alloc: fuse.allocationDto) {
    alloc.effectiveFromDate = this.dayToDate(alloc.effectiveFromDay);
    alloc.effectiveToDate = this.dayToDate(alloc.effectiveToDay);
  }

  // Set alloc from/to day numbers from dates
  public setAllocFromToDays(allocation: fuse.allocationDto) {
    allocation.effectiveFromDay = this.dateToDay(allocation.effectiveFromDate);
    allocation.effectiveToDay = this.dateToDay(allocation.effectiveToDate);
  }

  public checkAllocDates(form: angular.IFormController, allocation: fuse.allocationDto): boolean {
    this.setAllocFromToDays(allocation);
    allocation.maximumDate = allocation.effectiveFromDate.clone().addYears(1).addDays(-1);

    if (allocation.effectiveToDate == null) {
      return true;
    }
    const valid = allocation.effectiveFromDate <= allocation.effectiveToDate;
    this.setError(form.fromDate, 'valid_date', valid);

    const max_date_error = valid ? allocation.effectiveToDate <= allocation.maximumDate : true;
    this.setError(form.fromDate, 'max_date_error', max_date_error);

    const anyError = max_date_error && valid;
    this.setError(form.toDate, 'inv', anyError);

    return anyError;
  }

  public setError(control: angular.INgModelController, error: string, state: boolean) {
    if (control.$error[error] != state) {
      control.$setTouched();
    }
    control.$setValidity(error, state);
  }

  public checkWaterSources(allocation: fuse.allocationDto, allocations: fuse.allocationDto[], checkInactive = false): string {
    // Don't need to check sources if allocation inactive
    if (allocation.status != StatusEnum.Active && !checkInactive) {
      return null;
    }

    const sourceIdsForThisAlloc = allocation.waterSources.map((a) => a.id);
    const overlappingSources = {};

    const withDateOverlap = allocations.filter(
      (a) =>
        a.status == StatusEnum.Active &&
        a.id != allocation.id &&
        !(
          allocation.effectiveToDate.getTime() < a.effectiveFromDate.getTime() ||
          allocation.effectiveFromDate.getTime() > a.effectiveToDate.getTime()
        ),
    );

    withDateOverlap.forEach((existAllocation) => {
      const waterSources = existAllocation.waterSources.filter((a) => sourceIdsForThisAlloc.includes(a.id));

      if (waterSources.length) {
        overlappingSources[existAllocation.name] = waterSources.map((w) => w.name).join(', ');
      }
    });

    const overlappingAllocs = Object.keys(overlappingSources);

    if (overlappingAllocs.length) {
      const overlapList = `<ul>${overlappingAllocs
        .map((alloc) => `<li><b>${alloc}:</b> ${overlappingSources[alloc]}</li>`)
        .join('')}</ul>`;
      const content = this._languageService.instant('ALLOCATION.ERROR_OVERLAP') + overlapList;

      return content;
    }

    return null;
  }

  private createDataProvider(
    useByMonth: SubAllocation[] | fuse.subAllocUsageDto[],
    staticBudget = null,
  ): IUsageSummaryChartItem[] {
    const dataProvider = [] as IUsageSummaryChartItem[];
    let runningBudget = 0;
    useByMonth.forEach((month) => {
      const dataItem = {} as IUsageSummaryChartItem;
      dataItem.date = DateUtils.Locale.asDateMonthAndYearShort(this.dayToDate(month.fromDayNumber));
      dataItem.used = this.volumeHugeUnit.fromBaseRounded(month.usedKL);
      dataItem.runningUsed = this.volumeHugeUnit.fromBaseRounded(month.runningTotalKL);
      dataItem.allocationVolume = this.volumeHugeUnit.fromBaseRounded(month.allocationTotal);

      if (month.budgetKL != null) {
        runningBudget += month.budgetKL;
        dataItem.budget = this.volumeHugeUnit.fromBaseRounded(month.budgetKL);
        dataItem.runningBudget = this.volumeHugeUnit.fromBaseRounded(runningBudget);
      } else {
        dataItem.allocationVolume = this.volumeHugeUnit.fromBaseRounded(staticBudget);
      }
      dataProvider.push(dataItem);
    });

    return dataProvider;
  }

  CreateSubAllocDataProvider(subAllocation: SubAllocation | fuse.subAllocationDto, staticBudget: number): IUsageSubChartItem[] {
    const dataItem = {} as IUsageSubChartItem;
    dataItem.allocationVolume = this.volumeHugeUnit.fromBaseRounded(staticBudget);
    const used = this.volumeHugeUnit.fromBaseRounded(subAllocation.usedKL);

    if (used > dataItem.allocationVolume) {
      dataItem.usedOver = used;
      dataItem.usedUnder = 0;
    } else {
      dataItem.usedUnder = used;
      dataItem.usedOver = 0;
    }

    const singleArray = [] as IUsageSubChartItem[];
    singleArray.push(dataItem);

    return singleArray;
  }

  private balloonFunc(title) {
    return (graphDataItem, graph) => {
      const val = (graphDataItem.values as AmGraphDataValues).value;

      if (val) {
        return `${this._languageService.instant(title)}: ${val.toFixed(2)}${this.volumeHugeUnit.name}`;
      }

      return '';
    };
  }

  private plot(type, bullet, axis, valueField, title, lineColor) {
    const fillAlphas = type == 'column' ? 0.8 : 0;

    return {
      valueField,
      title: this._languageService.instant(title),
      valueAxis: axis,
      type,
      bullet,
      bulletAlpha: 1,
      lineColor,
      columnWidth: 0.5,
      alphaField: 'true',
      fillAlphas,
      balloonFunction: this.balloonFunc(title),
    } as AmCharts.AmGraph;
  }

  public displayUsageSubAllocSummaryChart(
    subAllocation: SubAllocation | fuse.subAllocationDto,
    staticBudget: number,
    allocationName: string,
  ) {
    const dataProvider = this.CreateSubAllocDataProvider(subAllocation, staticBudget);
    const div = 'sub-allocation-chart';

    const dateRange = `
      ${this._languageService.instant('ALLOCATION.SUB_ALLOCATION')} ${DateUtils.Locale.asDateMedium(this.dayToDate(subAllocation.fromDayNumber))}
      ${this._languageService.instant('COMMON.TO')} ${DateUtils.Locale.asDateMedium(this.dayToDate(subAllocation.toDayNumber))}\n(${allocationName})
    `;

    const volumeAxis = {
      id: 'volumeAxis',
      dashLength: 1,
      position: 'left',
      title: `${this._languageService.instant('COMMON.TOTAL')} (${this.volumeHugeUnit.name})`,
      minimum: 0,
    } as AmCharts.ValueAxis;

    const barPlot = (valueField, title, lineColor) => {
      return this.plot('column', null, volumeAxis, valueField, title, lineColor);
    };

    const usedOvergraph = barPlot('usedOver', 'ALLOCATION.OVER_SUB_ALLOCATION', '#5f5f5f');
    const allocationGraph = barPlot('allocationVolume', 'ALLOCATION.SUB_ALLOCATION', '#5f5f5f');
    const usedUnderGraph = barPlot('usedUnder', 'ALLOCATION.WITHIN_SUB_ALLOCATION', '#5f5f5f');
    usedOvergraph.clustered = false;
    allocationGraph.clustered = false;
    usedUnderGraph.clustered = false;
    usedOvergraph.fillAlphas = 1;
    usedUnderGraph.fillAlphas = 1;
    allocationGraph.fillAlphas = 1;
    usedOvergraph.fillColors = '#e57373';
    allocationGraph.fillColors = '#30398a';
    usedUnderGraph.fillColors = '#00c853';
    allocationGraph.columnWidth = 0.4;
    usedUnderGraph.columnWidth = 0.25;
    usedOvergraph.columnWidth = 0.25;
    const graphs = [];

    graphs.push(allocationGraph); // Allocation
    graphs.push(usedOvergraph); // Actual
    graphs.push(usedUnderGraph); // Actual

    const usageSummaryChartOption = {
      theme: 'light',
      type: 'serial',
      dataProvider,
      legend: {
        useGraphSettings: true,
        position: 'top',
        valueWidth: 0,
      },
      valueAxes: [volumeAxis],
      startDuration: 0,
      graphs,
      categoryField: '',
      categoryAxis: {
        gridPosition: 'center',
        gridAlpha: 0,
        labelRotation: 0,
        labelsEnabled: false,
        dashLength: 1,
        title: dateRange,
        titleFontSize: 10,
      } as any as AmCharts.CategoryAxis,
      export: {
        enabled: false,
      },
    };
    const usageSummaryChart = AmCharts.makeChart(div, usageSummaryChartOption) as AmCharts.AmSerialChart;
    usageSummaryChart.validateNow();
  }

  public displayUsageSummaryChart(
    useByMonth: SubAllocation[] | fuse.subAllocUsageDto[],
    includeBudgets = false,
    staticBudget: number = null,
  ) {
    const dataProvider = this.createDataProvider(useByMonth, staticBudget);

    const subAllocChart = staticBudget != null;
    const div = subAllocChart ? 'sub-allocation-chart' : 'usage-summary-chart';

    const volumeAxis = {
      id: 'volumeAxis',
      dashLength: 1,
      position: 'left',
      title: `${this._languageService.instant('ALLOCATION.MONTH_TOTALS')} (${this.volumeHugeUnit.name})`,
      minimum: 0,
    } as AmCharts.ValueAxis;
    const runningAxis = {
      id: 'runningAxis',
      dashLength: 1,
      position: 'right',
      title: `${this._languageService.instant('ALLOCATION.RUNNING_TOTALS')} (${this.volumeHugeUnit.name})`,
      minimum: 0,
    } as AmCharts.ValueAxis;

    const bullet = dataProvider.length == 1 ? 'round' : null;

    const linePlot = (valueField, title, lineColor) => {
      return this.plot('line', bullet, runningAxis, valueField, title, lineColor);
    };

    const barPlot = (valueField, title, lineColor) => {
      return this.plot('column', null, volumeAxis, valueField, title, lineColor);
    };

    const allocationGraph = linePlot('allocationVolume', 'ALLOCATION.ALLOCATION', '#edaf14');
    const budgetGraph = barPlot('budget', 'COMMON.BUDGET', '#27AAE0');
    const runningBudgetGraph = linePlot('runningBudget', 'COMMON.RUNNING_BUDGET', '#27AAE0');
    const usedGraph = barPlot('used', 'COMMON.ACTUAL', '#3c398c');
    const runningUsedGraph = linePlot('runningUsed', 'COMMON.RUNNING_ACTUAL', '#3c398c');

    const graphs = [];

    if (includeBudgets && !subAllocChart) {
      graphs.push(budgetGraph); // Budget
    }
    graphs.push(usedGraph); // Actual

    if (includeBudgets && !subAllocChart) {
      graphs.push(runningBudgetGraph); // Running budget
    }
    graphs.push(runningUsedGraph); // running actual
    graphs.push(allocationGraph); // allocation

    const labelRotation = dataProvider.length > 11 ? 15 : 0;
    const usageSummaryChartOption = {
      theme: 'light',
      type: 'serial',
      dataProvider,
      legend: {
        useGraphSettings: true,
        position: 'top',
        valueWidth: 0,
      },
      valueAxes: [volumeAxis, runningAxis],
      startDuration: 0,
      graphs,
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'center',
        gridAlpha: 0,
        labelRotation,
        dashLength: 1,
      } as AmCharts.CategoryAxis,
      export: {
        enabled: false,
      },
    };
    const usageSummaryChart = AmCharts.makeChart(div, usageSummaryChartOption) as AmCharts.AmSerialChart;
    usageSummaryChart.validateNow();
  }

  public checkName(allocation: fuse.allocationDto, allocations: fuse.allocationDto[], form: angular.IFormController) {
    if (!allocation.name) {
      return;
    }

    const lcName = allocation.name.toLowerCase();
    const v = !allocations.some((a) => a.id != allocation.id && a.name.toLowerCase() == lcName);

    form.name.$setValidity('valid', v);
    form.name.$setTouched();

    this._dataEntityService.hasDirtyCustomForm = true;

    return v;
  }

  public setNoChanges() {
    this._dataEntityService.hasDirtyCustomForm = false;
  }
}

angular.module('fuse').service('AllocationService', AllocationService);
