import * as angular from 'angular';
import { LanguageService } from './language.service';
import { SQLErrorCodes } from '@common/enums';

export class DupeHandlerService {
  private _languageService: LanguageService;

  private _translatedMessageMap: { [id: string]: string } = {};
  private _siteHealthKeys = ['site health point', 'site health area'];
  private _groupTypeKeys = ['group', 'planet_grp', 'wsm_group'];

  constructor(LanguageService: LanguageService) {
    this._languageService = LanguageService;

    this.showBadRequest = this.showBadRequest.bind(this);
  }

  public setDuplicateType(type: string): void {
    let msgType = '';

    if (this._groupTypeKeys.includes(type.toLowerCase())) {
      msgType = this._languageService.instant(`AC.GROUP.CLASSES.${type}`);
    } else if (this._siteHealthKeys.includes(type.toLowerCase())) {
      msgType = this._languageService.instant('COMMON.SITE_HEALTH_ASSET');
    } else {
      msgType = this._languageService.instant(`DB_VALUES.ASSETCLASS.${type}`);
    }

    const message = this._languageService.instant('COMMON.AN_ASSET_OF_TYPE', { t: msgType });

    this.setDuplicateMessage(message, true);
  }

  public setDuplicateMessage(input: string, isTranslated = false) {
    this._translatedMessageMap[SQLErrorCodes.DuplicateKeyError] = isTranslated
      ? input
      : this._languageService.instant(input);
  }

  public duplicatesOnly: fuse.swanSaveOptions = {
    swanHandledErrors: [SQLErrorCodes.DuplicateKeyError],
  } as fuse.swanSaveOptions;

  // Non-breeze version
  public showBadRequest(error) {
    if (error?.data?.length) {
      const codes = error?.data?.map((x) => x?.ErrorNumber?.toString()) || [];
      const keys = Object.keys(this._translatedMessageMap);
      const matches = codes.filter((value) => keys.includes(value));

      if (matches.length) {
        matches.forEach((x) => {
          this._languageService.showSaveFailureMessage(this._translatedMessageMap[x]);
        });
      }
    } else if (error?.data?.Message) {
      this._languageService.error(error?.data?.Message);
    }
  }

  // Breeze version
  public showError(error) {
    if (error.httpResponse) {
      const codes = error.httpResponse.data?.Errors?.map((x) => x.ErrorNumber?.toString()) || [];

      // Check for handled duplicate/etc errors
      const keys = Object.keys(this._translatedMessageMap);
      const matches = codes.filter((value) => keys.includes(value));

      if (matches.length) {
        matches.forEach((x) => {
          this._languageService.showSaveFailureMessage(this._translatedMessageMap[x]);
        });

        return;
      }

      // Check for WorkingDepthException/etc validation error
      const customErrors = error.httpResponse.data?.Errors?.filter((x) => x.SWANCustomError);

      if (customErrors.length) {
        customErrors.forEach((x) => {
          if (x.Message === 'Dupe') {
            this._languageService.showSaveFailureMessage(this._translatedMessageMap[SQLErrorCodes.DuplicateKeyError]);
          } else {
            this._languageService.error(x.Message);
          }
        });

        return;
      }

      this._languageService.showSaveFailure(error.message);
    }

    // The 'entityErrors' sometimes contains a single item with a blank attributes, which is equivalent to being empty.
    if (error.entityErrors?.find((x) => x.errorMessage)) {
      const getTranslationData = (error) => {
        const getParamTranslation = () => {
          switch (error.propertyName.toLowerCase()) {
            case 'name':
              return { name: this._languageService.instant('COMMON.NAME') };

            default:
              return null;
          }
        };

        switch (error.errorName) {
          case 'required':
            return { key: 'COMMON.INPUT_REQUIRED', params: getParamTranslation() };

          default:
            console.warn(`Could not determine translation data for the object: ${JSON.stringify(error)}`);

            return null
        }
      };

      error.entityErrors.map((error) => {
        const translationData = getTranslationData(error);

        this._languageService.showSaveFailure(translationData?.key, translationData?.params);
      });
    }
  }
}

angular.module('fuse').service('DupeHandlerService', DupeHandlerService);
