import * as angular from 'angular';

angular.module('fuse').filter('titlecase', () => {
  return (input) => {
    input = input || '';
    return input.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
  };
});
