import * as angular from 'angular';
import * as moment from 'moment';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { Asset } from '../_DBContext/Asset';
import { FilterService } from '@services/filter.service';

export class EquipmentService extends FilterService {
  private _q: angular.IQService;
  private _http: angular.IHttpService;

  private currentDataInputId = null as number;

  constructor($q: angular.IQService, $http: angular.IHttpService) {
    super();
    this._q = $q;
    this._http = $http;
  }

  public getDataInputId(): number {
    return this.currentDataInputId;
  }

  public setDataInputId(dataInputId: number): void {
    this.currentDataInputId = dataInputId;
  }

  public getValueRangeList(assetID: number): angular.IPromise<fuse.valueRange[]> {
    const defer = this._q.defer<fuse.valueRange[]>();
    const inputParam = { assetID: assetID };
    const config = {
      params: inputParam,
    };
    this._http
      .get(CommonHelper.getApiUrl('data/Assets/GetValueRangeList/'), config)
      .then((result) => {
        defer.resolve(result.data as fuse.valueRange[]);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public getHealthIndexValueRanges(assetId: number): angular.IPromise<fuse.healthIndexValueRangeDto[]> {
    const defer = this._q.defer<fuse.healthIndexValueRangeDto[]>();
    const inputParam = { assetId: assetId };
    const config = {
      params: inputParam,
    };
    this._http
      .get<fuse.healthIndexValueRangeDto[]>(CommonHelper.getApiUrl('equipment/GetHealthIndexValueRanges/'), config)
      .then((result) => {
        if (result.data) {
          result.data = result.data.map((d) => {
            d.effectiveFrom = moment(d.effectiveFrom).toDate();
            return d;
          });
        }
        defer.resolve(result.data as fuse.healthIndexValueRangeDto[]);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public isValidNewValueData(newValue: fuse.valueRange): boolean {
    const result =
      angular.isDefined(newValue.effectiveFrom) &&
      angular.isDefined(newValue.readingHigh) &&
      angular.isDefined(newValue.readingLow) &&
      newValue.readingLow >= 0 &&
      newValue.readingLow <= newValue.readingHigh &&
      newValue.readingHigh <= 1 &&
      newValue.readingHigh >= newValue.readingLow;
    return result;
  }

  public setHealthIndexValueRanges(assetId: number, valueRanges: fuse.healthIndexValueRangeDto[]): angular.IPromise<any> {
    const defer = this._q.defer();
    this._http
      .post<any>(CommonHelper.getApiUrl('equipment/' + assetId + '/SetHealthIndexValueRanges/'), valueRanges)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  public getSiteHealthAssets(accountId: number): angular.IPromise<Asset[]> {
    const defer = this._q.defer<Asset[]>();
    const inputParam = { accountId: accountId };
    const config = {
      params: inputParam,
    };
    this._http
      .get(CommonHelper.getApiUrl('data/Assets/GetAccountSiteHealthAssets/'), config)
      .then((result) => {
        defer.resolve(result.data as Asset[]);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }
}

angular.module('fuse').service('EquipmentService', EquipmentService);
