import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';

angular.module('fuse').filter('logLevel', () => {
  return (input: number) => {
    if (input == null || input == undefined) return null;
    const output = SWANConstants.LogLevels.find((a) => a.id == input).name;
    return output;
  };
});
