import * as angular from 'angular';
import * as $ from 'jquery';
import { Loader as GoogleMapsJSApiLoader } from '@googlemaps/js-api-loader';
import { CommonHelper } from '@common/helpers/CommonHelper';

export class MapService {
  public isGoogleMapsJSApiLoaded: angular.IPromise<boolean>;
  public searchMarker = {
    position: {
      latitude: -31.9522,
      longitude: 115.8589,
    },
    id: 1,
    options: {
      title: 'Place Search Marker',
      visible: false,
    },
  };

  private _q: angular.IQService;
  private _http: angular.IHttpService;
  private _elevationService: google.maps.ElevationService;
  private _googleMapsJSLoader: GoogleMapsJSApiLoader;

  constructor(
    $q: angular.IQService,
    $http: angular.IHttpService,
  ) {
    this._q = $q;
    this._http = $http;

    this._googleMapsJSLoader = new GoogleMapsJSApiLoader({
      apiKey: 'AIzaSyCNRJEEgbVn8Mob4h81IHQrXVJp_ckh26Q',
      libraries: ['drawing', 'geometry', 'places', 'visualization'],
      // NOTE: The 'quarterly' version is updated once per quarter automatically, and is the most predictable.
      version: 'quarterly',
    });

    this.isGoogleMapsJSApiLoaded = this.loadGoogleMapsJSApi();
  }

  // /**
  // * Returns the zoom level at which the given rectangular region fits in the map view.
  // * The zoom level is computed for the currently selected map type.
  // * @param {google.maps.Map} map
  // * @param {google.maps.LatLngBounds} bounds
  // * @return {Number} zoom level
  // **/
  public getZoomByBounds(map, bounds) {
    const MAX_ZOOM = map.mapTypes.get(map.getMapTypeId()).maxZoom || 21;
    const MIN_ZOOM = map.mapTypes.get(map.getMapTypeId()).minZoom || 0;

    const ne = map.getProjection().fromLatLngToPoint(bounds.getNorthEast());
    const sw = map.getProjection().fromLatLngToPoint(bounds.getSouthWest());

    const worldCoordWidth = Math.abs(ne.x - sw.x);
    const worldCoordHeight = Math.abs(ne.y - sw.y);

    //Fit padding in pixels
    const FIT_PAD = 40;

    for (let zoom = MAX_ZOOM; zoom >= MIN_ZOOM; --zoom) {
      if (
        worldCoordWidth * (1 << zoom) + 2 * FIT_PAD < $(map.getDiv()).width() &&
        worldCoordHeight * (1 << zoom) + 2 * FIT_PAD < $(map.getDiv()).height()
      ) {
        return zoom;
      }
    }

    return 0;
  }

  public getElevation(lat: number, lng: number) {
    const defer = this._q.defer<number>();

    this._elevationService.getElevationForLocations({ locations: [new google.maps.LatLng(lat, lng)] }, (results, status) => {
      if (status === google.maps.ElevationStatus.OK && results[0]) {
        defer.resolve(results[0].elevation);
      } else {
        defer.reject(null);
      }
    });

    return defer.promise;
  }

  public generateGeoJSONCircle(center: google.maps.LatLng, radius: number, numSides: number) {
    const points = [];
    const degreeStep = 360 / numSides;

    for (let i = 0; i < numSides; i++) {
      const gpos = google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i);
      points.push({ lat: gpos.lat(), lng: gpos.lng() });
    }

    // Duplicate the last point to close the geojson ring
    points.push(points[0]);

    return new google.maps.Data.Polygon([points, points]);
  }

  public getTimeZone(lat: number, lng: number) {
    const defer = this._q.defer<string>();

    const opts = {
      params: {
        lat: lat.toFixed(5),
        lng: lng.toFixed(5),
        timestamp: (0.001 * new Date().getTime()).toFixed(),
      },
    };

    this._http.get(CommonHelper.getApiUrl('tz/zoneinfo'), opts).then(
      (response) => {
        defer.resolve(JSON.stringify(response.data));
      },
      (error) => {
        defer.reject(error);
      },
    );

    return defer.promise;
  }

  private async loadGoogleMapsJSApi(): Promise<boolean> {
    try {
      await this._googleMapsJSLoader.importLibrary('maps');

      // Instantiate 'ElevationService' when Google Maps API is ready.
      this._elevationService = new google.maps.ElevationService();

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };
}

angular.module('fuse').service('MapService', MapService);
