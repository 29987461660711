import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';

type SupportedFormat =
  ''
  | 'dateMed'
  | 'dateShort'
  | 'dateTimeMed'
  | 'dayAndMonth'
  | 'dayAndMonthWithWeekday'
  | 'monthAndYearLong'
  | 'monthAndYearShort'
  | 'monthShort'
  | 'weekdayLong'
  | 'weekdayShort'
  | 'timeSimple';

angular.module('fuse').filter('dateLocale', () => {
  return (input: number | string | Date, format: SupportedFormat = ''): string => {
    if (!input) {
      return null;
    }

    if (!format) {
      return DateUtils.Locale.asDateDefault(input);
    }

    if (format === 'dateMed') {
      return DateUtils.Locale.asDateMedium(input);
    } else if (format === 'dateShort') {
      return DateUtils.Locale.asDateShort(input);
    } else if (format === 'dateTimeMed') {
      return DateUtils.Locale.asDateTimeMedium(input);
    } else if (format === 'dayAndMonth') {
      return DateUtils.Locale.asDateDayAndMonth(input);
    } else if (format === 'dayAndMonthWithWeekday') {
      return DateUtils.Locale.asDateDayAndMonthWithWeekday(input);
    } else if (format === 'monthAndYearLong') {
      return DateUtils.Locale.asDateMonthAndYearLong(input);
    } else if (format === 'monthAndYearShort') {
      return DateUtils.Locale.asDateMonthAndYearShort(input);
    } else if (format === 'monthShort') {
      return DateUtils.Locale.asDateMonthShort(input);
    } else if (format === 'weekdayLong') {
      return DateUtils.Locale.asDateWeekdayLong(input);
    } else if (format === 'weekdayShort') {
      return DateUtils.Locale.asDateWeekdayShort(input);
    } else if (format === 'timeSimple') {
      return DateUtils.Locale.asTimeSimple(input);
    } else {
      return DateUtils.Locale.asDateDefault(input);
    }
  };
});