import * as angular from 'angular';

class fuseThemingService {
  constructor(
    $mdTheming: angular.material.IThemingService,
    $cookies,
    $log,
  ) {
    const service = {
      themes: {
        list: {},
        active: {
          name: '',
          theme: {},
        },
      },
    };

    /**
     * Get registered palettes
     *
     * @returns {*}
     */
    const getRegisteredPalettes = () => {
      return $mdTheming.PALETTES;
    }

    /**
     * Get registered themes
     *
     * @returns {*}
     */
    const getRegisteredThemes = () => {
      return $mdTheming.THEMES;
    }

    /**
     * Set active theme
     *
     * @param themeName
     */
    const setActiveTheme = (themeName) => {
      // If theme does not exist, fallback to the default theme
      if (angular.isUndefined(service.themes.list[themeName])) {
        // If there is no theme called "default"...
        if (angular.isUndefined(service.themes.list['default'])) {
          $log.error('You must have at least one theme named "default"');
          return;
        }

        $log.warn('The theme "' + themeName + '" does not exist! Falling back to the "default" theme.');

        // Otherwise set theme to default theme
        service.themes.active.name = 'default';
        service.themes.active.theme = service.themes.list['default'];
        $cookies.put('selectedTheme', service.themes.active.name);

        return;
      }

      service.themes.active.name = themeName;
      service.themes.active.theme = service.themes.list[themeName];
      $cookies.put('selectedTheme', themeName);
    }

    /**
     * Set available themes list
     *
     * @param themeList
     */
    const setThemesList = (themeList) => {
      service.themes.list = themeList;
    }

    return {
      ...service,
      getRegisteredPalettes: getRegisteredPalettes,
      getRegisteredThemes: getRegisteredThemes,
      setActiveTheme: setActiveTheme,
      setThemesList: setThemesList,
    };

  }
}

angular.module('fuse').service('fuseTheming', fuseThemingService);
