import * as angular from 'angular';
import * as $ from 'jquery';

/** Time represented as integer hours and integer minutes */
export class HrsMins {
  public hours: number;
  public minutes: number;

  constructor(hours: number = 0, minutes: number = 0) {
    this.hours = hours;
    this.minutes = minutes;

    if (this.minutes >= 60) {
      this.hours += Math.floor(this.minutes / 60);
      this.minutes = this.minutes % 60;
    }
  }

  public totalMinutes() {
    return 60 * this.hours + this.minutes;
  }

  /** Convert to HH:mm format */
  public toString(): string {
    const mins = this.pad2Digits(this.minutes);
    const hrs = this.pad2Digits(this.hours);

    return hrs + ':' + mins;
  }

  /** Format string with AM/PM */
  public toAMPM(): string {
    const mins = this.pad2Digits(this.minutes);

    let hrs = this.hours;
    let ampm = 'AM';
    if (hrs > 12) {
      ampm = 'PM';
      hrs = hrs - 12;
    }
    const hrs12 = this.pad2Digits(hrs);

    return `${hrs12}:${mins} ${ampm}`;
  }

  /** Convert to timespan ('PT1H2M' format), as represented in Breeze queries from database column type 'time(7)' format */
  public toTimeSpan(): string {
    return `PT${this.hours}H${this.minutes}M`;
  }

  /** Set this object's hrs/mins from time string in HH:mm format */
  public fromString(strVal: string): HrsMins {
    const parts = strVal.toString().split(':');
    if (parts.length != 2 || !parts.every((a) => a.length == 2 && $.isNumeric(a))) {
      throw new Error(`Error: ${strVal} is not a valid time string`);
    }

    this.hours = Number(parts[0]);
    this.minutes = Number(parts[1]);
    return this;
  }

  /** Set this object's hrs/mins from time string in 'PT1H2M' format */
  public fromTimeSpan(timespan: string): HrsMins {
    const parts = RegExp('PT((\\d+)H)*((\\d+)M)*((\\d+)S)*').exec(timespan);
    if (!parts || parts.length < 5) {
      console.log(`'${timespan}' is not a valid timespan. Expected string in PT1H2M2S format.`);
      this.hours = 0;
      this.minutes = 0;
    }

    this.hours = Number(parts[2] || 0);
    this.minutes = Number(parts[4] || 0);
    return this;
  }

  private pad2Digits(num: number): string {
    let s = num.toString();
    if (num < 10) s = '0' + s;
    return s;
  }
}

/** Functions to handle formatting time of day/periods of time for exchange with db and/or user input/output*/
export class TimeService {
  constructor() {
  }

  public timeString(totalMinutes: number, AMPM = false): string {
    const obj = new HrsMins(0, totalMinutes);
    if (AMPM) return obj.toAMPM();
    return obj.toString();
  }

  /** Convert time representation from totalMinutes, 'HH:mm' or HrsMins object to database timespan 'PT01H2M' format */
  public timeSpanFormat(time: HrsMins | string | number): string {
    let input: HrsMins;
    switch (typeof time) {
      case 'string':
        input = this.parseTime(time);
        break;
      case 'number':
        input = new HrsMins(0, time);
        break;
      default:
        input = time;
    }

    return input?.toTimeSpan();
  }

  /** Convert time (in either totalminutes or timespan format) to readable 'HH:mm' format */
  public toReadable(time: number | string): string {
    let hrsMins;
    if (typeof time == 'number') hrsMins = new HrsMins(0, time);
    else hrsMins = new HrsMins().fromTimeSpan(time);

    return hrsMins.toString();
  }

  public timeSpanToReadable(timespan: string): string {
    return new HrsMins().fromTimeSpan(timespan).toString();
  }

  public parseTime(strVal: string): HrsMins {
    if (!strVal?.length) return null;
    if (strVal.length == 4 && strVal[1] == ':') {
      // format is H:mm instead of HH:mm
      strVal = '0' + strVal;
    }
    return new HrsMins().fromString(strVal);
  }

  public isPTFormat(strVal: string) {
    if (!strVal?.length || strVal.length < 2) return false;
    return strVal.slice(0, 2) == 'PT';
  }

  public parseToTotalMinutes(strVal: string): number {
    if (this.isPTFormat(strVal)) {
      const hrsMins = new HrsMins().fromTimeSpan(strVal);
      return hrsMins.totalMinutes();
    }
    return this.parseTime(strVal)?.totalMinutes();
  }
}

angular.module('fuse').service('TimeService', TimeService);
