import * as angular from 'angular';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { SWANConstants } from '@common/SWANConstants';
import { Sensor } from '../_DBContext/Sensor';
import { Asset } from '../_DBContext/Asset';
import { AssetClass } from '../_DBContext/AssetClass';
import { Contact } from '../_DBContext/Contact';
import { IrrigationPlan } from '../_DBContext/IrrigationPlan';
import { IrrigationPlanOverride } from '../_DBContext/IrrigationPlanOverride';
import { Site } from '../_DBContext/Site';
import { SiteAsset } from '../_DBContext/SiteAsset';
import { SiteSettingsNutrients } from '../_DBContext/SiteSettingsNutrients';
import { ValueRange } from '../_DBContext/ValueRange';
import { WaterBudget } from '../_DBContext/WaterBudget';
import { WaterBudgetMonth } from '../_DBContext/WaterBudgetMonth';
import { WaterBudgetSite } from '../_DBContext/WaterBudgetSite';
import { AssetClassService } from './asset-class.service';
import { DataEntityService } from './data-entity.service';

export class FetchDataService {
  private _q: angular.IQService;
  private _assetClassService: AssetClassService;

  private _entityManager: breeze.EntityManager;

  constructor(
    AssetClassService: AssetClassService,
    DataEntityService: DataEntityService,
    $q: angular.IQService,
  ) {
    this._q = $q;
    this._assetClassService = AssetClassService;

    this._entityManager = DataEntityService.manager;
  }

  public fetchAccountCMUs(accountId: number): angular.IPromise<Asset[]> {
    const defer = this._q.defer();
    const assetClass = SWANConstants.assetClasses.find((a) => a.name == AssetClassNameEnum.SiteCMU);
    const pred = breeze.Predicate.create('OwnerAccountId', breeze.FilterQueryOp.Equals, accountId).and(
      'AssetClassId',
      breeze.FilterQueryOp.Equals,
      assetClass.id,
    );

    const param = { accountId: accountId };
    const promise = breeze.EntityQuery.from('AccountAssets').withParameters(param).where(pred).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as Asset[]);
    });

    return defer.promise as angular.IPromise<Asset[]>;
  }

  public fetchAccountSites(accountId: number): angular.IPromise<Asset[]> {
    const defer = this._q.defer();
    const assetClass = SWANConstants.assetClasses.find((a) => a.name == AssetClassNameEnum.SiteIMU);
    const pred = breeze.Predicate.create('OwnerAccountId', breeze.FilterQueryOp.Equals, accountId).and(
      'AssetClassId',
      breeze.FilterQueryOp.Equals,
      assetClass.id,
    );
    const param = { accountId: accountId };

    const promise = breeze.EntityQuery.from('AccountAssets')
      .expand('Site')
      .withParameters(param)
      .where(pred)
      .using(this._entityManager)
      .execute();
    promise.then(
      (data) => {
        defer.resolve(data.results as Asset[]);
      },
      (error) => {
        console.log(error);
      },
    );

    return defer.promise as angular.IPromise<Asset[]>;
  }

  public fetchAccountImuSites(accountId: number): angular.IPromise<Site[]> {
    const defer = this._q.defer();
    const pred = breeze.Predicate.create();
    const param = { accountId: accountId };

    const promise = breeze.EntityQuery.from('AccountSites').withParameters(param).where(pred).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as Site[]);
    });

    return defer.promise as angular.IPromise<Site[]>;
  }

  public fetchCMUSiteAssets(accountId: number, cmuIds: number[]): angular.IPromise<any> {
    const defer = this._q.defer();

    const pred = breeze.Predicate.create('DataInputId', breeze.FilterQueryOp.Equals, 1);
    let pred2 = new breeze.Predicate('ParentAssetId', breeze.FilterQueryOp.Equals, cmuIds[0]);
    const pred3 = breeze.Predicate.create('ChildAsset.Status', breeze.FilterQueryOp.NotEquals, 'Archived');

    for (let i = 1; i < cmuIds.length; i++) {
      pred2 = pred2.or('ParentAssetId', breeze.FilterQueryOp.Equals, cmuIds[i]);
    }

    const allPreds = pred.and(pred2).and(pred3);
    const param = { accountId: accountId };

    const promise = breeze.EntityQuery.from('SiteAssets')
      .withParameters(param)
      .expand('ChildAsset')
      .where(allPreds)
      .using(this._entityManager)
      .execute();

    promise.then((data) => {
      defer.resolve(data.results as SiteAsset[]);
    });

    return defer.promise;
  }

  public fetchSite(accountId: number, assetId: number): angular.IPromise<Asset> {
    const defer = this._q.defer();

    const siteEntity = this._entityManager.getEntities('Asset').filter((obs: Asset) => {
      return obs.AssetId === assetId;
    }) as Asset[];

    if (siteEntity.length) {
      defer.resolve(siteEntity[0]);
    } else {
      const pred = breeze.Predicate.create('AssetId', breeze.FilterQueryOp.Equals, assetId);
      const param = { AccountId: accountId };

      const promise = breeze.EntityQuery.from('Sites').expand('Site').where(pred).using(this._entityManager).execute();

      promise.then((data) => {
        if (data.results.length) {
          defer.resolve(data.results[0] as Asset);
        } else {
          defer.resolve();
        }
      });
    }

    return defer.promise as angular.IPromise<Asset>;
  }

  public fetchSiteSettingsNutrients(assetId: number): angular.IPromise<SiteSettingsNutrients[]> {
    const defer = this._q.defer();
    const pred = breeze.Predicate.create('AssetId', breeze.FilterQueryOp.Equals, Number(assetId.toString()));
    const param = { assetId: assetId };

    const promise = breeze.EntityQuery.from('getSiteSettingsNutrients')
      .withParameters(param)
      .where(pred)
      .using(this._entityManager)
      .execute();

    promise.then((data) => {
      defer.resolve(data.results as SiteSettingsNutrients[]);
    });

    return defer.promise as angular.IPromise<SiteSettingsNutrients[]>;
  }

  public fetchAccountPlans(accountId: number): angular.IPromise<Asset[]> {
    const defer = this._q.defer();

    this._assetClassService._fetchAssetClass(AssetClassNameEnum.IrrigationPlan).then((data) => {
      const assetClass = data.results[0] as AssetClass;
      const pred = breeze.Predicate.create('OwnerAccountId', breeze.FilterQueryOp.Equals, accountId).and(
        'AssetClassId',
        breeze.FilterQueryOp.Equals,
        assetClass.Id,
      );
      const param = { accountId: accountId };

      const promise = breeze.EntityQuery.from('AccountAssets')
        .expand('Site')
        .withParameters(param)
        .where(pred)
        .using(this._entityManager)
        .execute();

      promise.then((data) => {
        defer.resolve(data.results as Asset[]);
      });
    });

    return defer.promise as angular.IPromise<Asset[]>;
  }

  public fetchThePlanSiteAssets(accountId: number, planId: number): angular.IPromise<SiteAsset[]> {
    const defer = this._q.defer();
    const param = { accountId: accountId, planId: planId };

    const promise = breeze.EntityQuery.from('PlanSiteAssets').withParameters(param).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as SiteAsset[]);
    });

    return defer.promise as angular.IPromise<SiteAsset[]>;
  }

  public fetchAccountPlanSiteAssets(accountId: number): angular.IPromise<SiteAsset[]> {
    const defer = this._q.defer();
    const param = { accountId: accountId };

    const promise = breeze.EntityQuery.from('AccountPlanSiteAssets').withParameters(param).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as SiteAsset[]);
    });

    return defer.promise as angular.IPromise<SiteAsset[]>;
  }

  public fetchPlanInfo(planId: number): angular.IPromise<IrrigationPlan[]> {
    const defer = this._q.defer();
    const param = { planId: planId };

    const promise = breeze.EntityQuery.from('IrrigationPlan').withParameters(param).expand('Asset').using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as IrrigationPlan[]);
    });

    return defer.promise as angular.IPromise<IrrigationPlan[]>;
  }

  public fetchThePlanOverrides(planId: number): angular.IPromise<IrrigationPlanOverride[]> {
    const defer = this._q.defer();
    const pred = breeze.Predicate.create();
    const param = { planId: planId };

    const promise = breeze.EntityQuery.from('IrrigationPlanOverride').withParameters(param).where(pred).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as IrrigationPlanOverride[]);
    });

    return defer.promise as angular.IPromise<IrrigationPlanOverride[]>;
  }

  public fetchAsset(assetId: number): angular.IPromise<Asset[]> {
    const defer = this._q.defer();
    const pred = breeze.Predicate.create();
    const param = { assetId: assetId };

    const promise = breeze.EntityQuery.from('Asset').withParameters(param).where(pred).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as Asset[]);
    });

    return defer.promise as angular.IPromise<Asset[]>;
  }

  public fetchAccountContacts(accountId: number): angular.IPromise<Contact[]> {
    const defer = this._q.defer();

    const param = { accountId: accountId };

    const promise = breeze.EntityQuery.from('AccountContacts').withParameters(param).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as Contact[]);
    });

    return defer.promise as angular.IPromise<Contact[]>;
  }

  public fetchValueRanges(equipmentId: number): angular.IPromise<ValueRange[]> {
    const defer = this._q.defer();
    const pred = breeze.Predicate.create();
    const param = { equipmentId: equipmentId };

    const promise = breeze.EntityQuery.from('ValueRange').withParameters(param).where(pred).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as ValueRange[]);
    });

    return defer.promise as angular.IPromise<ValueRange[]>;
  }

  public fetchWaterBudget(budgetId: number): angular.IPromise<WaterBudget[]> {
    const defer = this._q.defer();
    const pred = breeze.Predicate.create();
    const param = { budgetId: budgetId };

    const promise = breeze.EntityQuery.from('Waterbudget').withParameters(param).where(pred).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as WaterBudget[]);
    });

    return defer.promise as angular.IPromise<WaterBudget[]>;
  }

  public fetchWaterBudgetSites(budgetId: number): angular.IPromise<WaterBudgetSite[]> {
    const defer = this._q.defer();
    const pred = breeze.Predicate.create();
    const param = { budgetId: budgetId };

    const promise = breeze.EntityQuery.from('WaterbudgetSite').withParameters(param).where(pred).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as WaterBudgetSite[]);
    });

    return defer.promise as angular.IPromise<WaterBudgetSite[]>;
  }

  public fetchWaterBudgetMonths(budgetId: number): angular.IPromise<WaterBudgetMonth[]> {
    const defer = this._q.defer();
    const pred = breeze.Predicate.create();
    const param = { budgetId: budgetId };

    const promise = breeze.EntityQuery.from('WaterbudgetMonth').withParameters(param).where(pred).using(this._entityManager).execute();

    promise.then((data) => {
      defer.resolve(data.results as WaterBudgetMonth[]);
    });

    return defer.promise as angular.IPromise<WaterBudgetMonth[]>;
  }

  public fetchSensors(assetId: number): angular.IPromise<Sensor[]> {
    const defer = this._q.defer();
    const promise = breeze.EntityQuery.from('getSensor')
      .withParameters({ AssetId: assetId })
      .expand('Asset.ParentAssets, Asset.ChildAssets, Asset.WaterStore, Asset.AssetClass, Asset.PropertyValues')
      .using(this._entityManager)
      .execute();

    promise.then(
      (data) => {
        defer.resolve(data.results as Sensor[]);
      },
      (error) => {
        console.log(error);
      },
    );

    return defer.promise as angular.IPromise<Sensor[]>;
  }
}

angular.module('fuse').service('FetchDataService', FetchDataService);
