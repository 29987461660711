import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';

export interface IUsersFilter {
  AccountID: number;
  UserAccountStatus: string;
}

export class UsersService {
  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _languageService: LanguageService;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    LanguageService: LanguageService,
  ) {
    this._http = $http;
    this._q = $q;
    this._languageService = LanguageService;
  }

  // GET - gets application user by email address (username)
  public findApplicationUser(email: string): angular.IPromise<fuse.ApplicationUser> {
    const defer = this._q.defer<fuse.ApplicationUser>();

    this._http
      .get(CommonHelper.getApiUrl('users/find'), { params: { email: email } })
      .then((result) => {
        defer.resolve(result.data as fuse.ApplicationUser);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  // GET - gets the list of application users
  public getApplicationUsers(filters?: IUsersFilter): angular.IPromise<fuse.ApplicationUser[]> {
    if (filters == null) {
      filters = {} as IUsersFilter;
    }

    const defer = this._q.defer<fuse.ApplicationUser[]>();

    this._http
      .get<fuse.ApplicationUser[]>(CommonHelper.getApiUrl('users'))
      .then((result) => {
        defer.resolve(result.data); //result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    return defer.promise;
  }

  public updateApplicationUser(user: fuse.ApplicationUser): angular.IPromise<fuse.ApplicationUser> {
    const defer = this._q.defer<fuse.ApplicationUser>();

    this._http
      .put<fuse.ApplicationUser>(CommonHelper.getApiUrl('users'), user)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  public createApplicationUser(user: fuse.ApplicationUser): angular.IPromise<fuse.httpResultDto> {
    const defer = this._q.defer<fuse.httpResultDto>();

    user.UserName = user.Email;
    this._http
      .post(CommonHelper.getApiUrl('users'), user)
      .then((result) => {
        defer.resolve(result.data as fuse.httpResultDto);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  // DELETE
  public deleteUser(userId: string): angular.IPromise<fuse.httpResultDto> {
    const defer = this._q.defer<fuse.httpResultDto>();

    this._http
      .delete(CommonHelper.getApiUrl('users/' + userId))
      .then((result) => {
        defer.resolve(result.data as fuse.httpResultDto);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  // PUT - update
  public resetSystemUserPassword(user: fuse.ApplicationUser): angular.IPromise<any> {
    const defer = this._q.defer<fuse.ApplicationUser>();

    this._http
      .put<any>(CommonHelper.getApiUrl('users/resetpassword'), user)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  public emailAccountAccess(user: fuse.ApplicationUser): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.post(CommonHelper.getApiUrl('users/welcome'), user).then(
      () => {
        this._languageService.successMessage(this._languageService.instant('ADM.USER.WELCOME_SENT') + user.Email);
      },
      (error) => {
        this._languageService.error(error.data.Message);
      },
    );

    return defer.promise;
  }

  public acceptTerms(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.put(CommonHelper.getApiUrl('users/acceptTerms'), {}).then((_result) => {
      defer.resolve();
    })
    .catch((_error) => {
      defer.reject();
    });

    return defer.promise;
  }
}

angular.module('fuse').service('UsersService', UsersService);
