import * as angular from 'angular';

export class NutrientsService {
  private _isKeepFilter: boolean;
  private _nameFilter: string;
  private _createForFilter: string;
  private _typeFilter: string;
  private _samplePointFilter: string;
  private _sampledByFilter: string;
  private _laboratoryFilter: string;
  private _ownerFilter: string;
  private _manufacturerFilter: string;
  private _symbolFilter: string;
  private _subClassFilter: string;
  private _unitBaseFilter: string;
  private _measurementUnitFilter: string;
  private _sharedState: boolean;
  private _activeState: boolean;
  private _archivedState: boolean;
  private _suspendedState: boolean;

  constructor() {
    this._isKeepFilter = false;
    this._nameFilter = '';
    this._createForFilter = '';
    this._samplePointFilter = '';
    this._sampledByFilter = '';
    this._laboratoryFilter = '';
    this._ownerFilter = '';
    this._manufacturerFilter = '';
    this._sharedState = false;
    this._activeState = true;
    this._archivedState = false;
    this._suspendedState = false;
  }

  public getKeepFilter(): boolean {
    return this._isKeepFilter;
  }

  public setKeepFilter(isKeepFilter: boolean): void {
    this._isKeepFilter = isKeepFilter;
  }

  public getNameFilter(): string {
    return this._nameFilter;
  }

  public setNameFilter(nameFilter: string): void {
    this._nameFilter = nameFilter;
  }

  public getCreateForFilter(): string {
    return this._createForFilter;
  }

  public setCreateForFilter(createForFilter: string): void {
    this._createForFilter = createForFilter;
  }

  public getTypeFilter(): string {
    return this._typeFilter || '';
  }

  public setTypeFilter(typeFilter: string): void {
    this._typeFilter = typeFilter;
  }

  public getSamplePointFilter(): string {
    return this._samplePointFilter;
  }

  public setSamplePointFilter(samplePointFilter: string): void {
    this._samplePointFilter = samplePointFilter;
  }

  public getSampledByFilter(): string {
    return this._sampledByFilter;
  }

  public setSampledByFilter(sampledByFilter: string): void {
    this._sampledByFilter = sampledByFilter;
  }

  public getLaboratoryFilter(): string {
    return this._laboratoryFilter;
  }

  public setLaboratoryFilter(laboratoryFilter: string): void {
    this._laboratoryFilter = laboratoryFilter;
  }

  public getOwnerFilter(): string {
    return this._ownerFilter;
  }

  public setOwnerFilter(ownerFilter: string): void {
    this._ownerFilter = ownerFilter;
  }

  public getManufacturerFilter(): string {
    return this._manufacturerFilter;
  }

  public setManufacturerFilter(manufacturerFilter: string): void {
    this._manufacturerFilter = manufacturerFilter;
  }

  public getSymbolFilter(): string {
    return this._symbolFilter;
  }

  public setSymbolFilter(symbolFilter: string): void {
    this._symbolFilter = symbolFilter;
  }

  public getSubClassFilter(): string {
    return this._subClassFilter;
  }

  public setSubClassFilter(subClassFilter: string): void {
    this._subClassFilter = subClassFilter;
  }

  public getUnitBaseFilter(): string {
    return this._unitBaseFilter;
  }

  public setUnitBaseFilter(unitBaseFilter: string): void {
    this._unitBaseFilter = unitBaseFilter;
  }

  public getMeasurementUnitFilter(): string {
    return this._measurementUnitFilter;
  }

  public setMeasurementUnitFilter(measurementUnitFilter: string): void {
    this._measurementUnitFilter = measurementUnitFilter;
  }

  public getSharedState(): boolean {
    return this._sharedState;
  }

  public setSharedState(state: boolean): void {
    this._sharedState = state;
  }

  public getActiveState(): boolean {
    return this._activeState;
  }

  public setActiveState(state: boolean): void {
    this._activeState = state;
  }

  public getArchivedState(): boolean {
    return this._archivedState;
  }

  public setArchivedState(state: boolean): void {
    this._archivedState = state;
  }

  public getSuspendedState(): boolean {
    return this._suspendedState;
  }

  public setSuspendedState(state: boolean): void {
    this._suspendedState = state;
  }
}

angular.module('fuse').service('NutrientsService', NutrientsService);
