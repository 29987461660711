import * as angular from 'angular';
import { NH4N_to_NH4, NO3N_to_NO3 } from '@common/ratios';
import { DataEntityService } from '@services/data-entity.service';
import { DayNumberService } from '@services/day-number.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { FertiliserPrice } from 'src/app/_DBContext/FertiliserPrice';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';

export enum PriceUnit {
  PricePer1000L,
  PricePerLitre,
}

export const microNutrients = [
  'B',
  'Cu',
  'Fe',
  'Mn',
  'Mo', // Molybdenum is an annoying special case, likely to be applied in very small quantities
  'Zn',
];

export class FertiliserService {
  private _q: angular.IQService;
  private _dayNumberService: DayNumberService;

  private _entityManager: breeze.EntityManager;

  constructor(
    $q: angular.IQService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
  ) {
    this._q = $q;
    this._dayNumberService = DayNumberService;

    this._entityManager = DataEntityService.manager;
  }

  public fetchObsNutrient(assetId: number, assetAttachmentId: number): angular.IPromise<ObsNutrients> {
    const defer = this._q.defer<ObsNutrients>();

    // 1. Fetch from local Breeze entities
    const obsNutrientEntities = this._entityManager.getEntities('ObsNutrients').filter((obs: ObsNutrients) => {
      if (assetAttachmentId) return obs.AssetId === assetId && obs.AssetAttachmentId === assetAttachmentId;

      return obs.AssetId === assetId;
    }) as ObsNutrients[];

    if (obsNutrientEntities.length) {
      defer.resolve(obsNutrientEntities[0]);
    }

    // 2. Fetch from database
    else {
      let pred = breeze.Predicate.create('AssetId', breeze.FilterQueryOp.Equals, assetId);
      if (assetAttachmentId) {
        pred = pred.and('AssetAttachmentId', breeze.FilterQueryOp.Equals, assetAttachmentId);
      }
      let param = {};
      if (assetAttachmentId) {
        param = { AssetId: assetId, AssetAttachmentId: assetAttachmentId };
      } else {
        param = { AssetId: assetId };
      }
      const promise = breeze.EntityQuery.from('ObsNutrients').withParameters(param).where(pred).using(this._entityManager).execute();

      promise.then((data) => {
        if (data.results.length) {
          defer.resolve(data.results[0] as ObsNutrients);
        }

        // 3. Create default ObsNutrient
        else {
          const promise = this.setDefaultObsNutrientEntity(assetId, assetAttachmentId);
          promise.then((saveResult) => {
            defer.resolve(saveResult.entities[0] as ObsNutrients);
          });
        }
      });
    }

    return defer.promise;
  }

  private setDefaultObsNutrientEntity(assetId: number, assetAttachmentId: number): Promise<breeze.SaveResult> {
    const obsNutrientsEntityType = this._entityManager.metadataStore.getEntityType('ObsNutrients') as breeze.EntityType;
    const newObsEntity = obsNutrientsEntityType.createEntity() as ObsNutrients;

    newObsEntity.AssetId = assetId;
    newObsEntity.dayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    newObsEntity.AssetAttachmentId = assetAttachmentId;

    this._entityManager.addEntity(newObsEntity);

    const promise = this._entityManager.saveChanges([newObsEntity]);

    return promise;
  }

  public fetchTankMixAssetLinks(accountId: number, assetId: number): Promise<breeze.QueryResult> {
    const pred = breeze.Predicate.create('ParentAssetId', breeze.FilterQueryOp.Equals, assetId);

    const promise = breeze.EntityQuery.from('SiteAssets')
      .withParameters({ accountId: accountId })
      .where(pred)
      .using(this._entityManager)
      .execute();

    return promise;
  }

  public getFertiliserPrices(assetId: number): Promise<breeze.QueryResult> {
    // const pred = new breeze.Predicate('FertiliserPrice', breeze.FilterQueryOp.NotEquals, null).and(
    //   'AssetId',
    //   breeze.FilterQueryOp.Equals,
    //   assetId,
    // );

    return breeze.EntityQuery.from('FertiliserPrices')
      .expand('Supplier')
      .withParameters({ assetId: assetId })
      .using(this._entityManager)
      .execute();
  }

  public getTankMixPrices(accountId: number, assetId: number): angular.IPromise<breeze.QueryResult> {
    const defer = this._q.defer();
    const fertPricePromises = [];

    this.fetchTankMixAssetLinks(accountId, assetId).then((data) => {
      const assetLinks = data.results as SiteAsset[];
      assetLinks.forEach((link) => {
        fertPricePromises.push(this.getFertiliserPrices(link.ChildAssetId));
      });
      this._q
        .all(fertPricePromises)
        .then((data) => {
          defer.resolve(data);
        })
        .catch((reason) => {
          defer.reject(reason);
        });
    });
    return defer.promise as angular.IPromise<breeze.QueryResult>;
  }

  public reCalculateMinMaxValues(coefficient: number, value: number, TotVolMixInLiters: number, priceType: PriceUnit) {
    let result: number;
    switch (priceType) {
      case PriceUnit.PricePer1000L:
        result = value * this.calculateUnitsPer1000L(coefficient, TotVolMixInLiters);
        break;

      case PriceUnit.PricePerLitre:
        result = value * this.calculateUnitsPerLitre(coefficient, TotVolMixInLiters);
        break;
    }
    if (Number.isInteger(result)) result.toFixed(2);
    return result;
  }

  public calculateUnitsPer1000L(coefficient: number, TotVolMixInLiters: number) {
    // if total volume mix = 0 or <0 or null then set as 1 (preventing infinity value as any number divided by 0 = infinity)
    if (TotVolMixInLiters == 0 || TotVolMixInLiters == null || TotVolMixInLiters < 0) {
      TotVolMixInLiters = 1;
    }
    const result = (coefficient / TotVolMixInLiters) * 1000;
    return result;
  }

  public calculateUnitsPerLitre(coefficient: number, TotVolMixInLiters: number) {
    // if total volume mix = 0 or <0 or null then set as 1 (preventing infinity value as any number divided by 0 = infinity)
    if (TotVolMixInLiters == 0 || TotVolMixInLiters == null || TotVolMixInLiters < 0) {
      TotVolMixInLiters = 1;
    }
    const result = coefficient / TotVolMixInLiters;
    return result;
  }

  public getMinValue(fertiliserPrices: FertiliserPrice[]) {
    let fp: any;
    const newFertiliserPrices = fertiliserPrices;
    if (newFertiliserPrices.length) {
      const min: number = Math.min.apply(
        null,
        newFertiliserPrices.map((item) => item.PricePerUnit),
      );
      fp = min;
    } else {
      fp = 'NP';
    }
    if (Number.isInteger(fp)) {
      fp.toFixed(2);
    }
    return fp;
  }

  public getMaxValue(fertiliserPrices: FertiliserPrice[]) {
    let fp: any;
    const newFertiliserPrices = fertiliserPrices;
    if (newFertiliserPrices.length) {
      const max: number = Math.max.apply(
        null,
        newFertiliserPrices.map((item) => item.PricePerUnit),
      );
      fp = max;
    } else {
      fp = 'NP';
    }
    if (Number.isInteger(fp)) {
      fp.toFixed(2);
    }
    return fp;
  }

  public calcMixMinVol(siteAssets: SiteAsset[]) {
    let totVol = 0;
    siteAssets.forEach((obj) => {
      if (obj.ChildAsset.Fertiliser.Units != 'kg') totVol += obj.Coefficient;
    });
    return totVol;
  }

  public calculateMixsaturationPer(siteAssets: SiteAsset[], TotalVolofMixInLiters: number) {
    let perMaxConcentration = 0;
    siteAssets.forEach((obj) => {
      const unitsin1000L = (obj.Coefficient / TotalVolofMixInLiters) * 1000;

      let solubility = 1000; // liquid fertilisers considered to be at saturation: 1000L dissolved in 1000L. Volume effectively subtracted from mix total.
      if (obj.ChildAsset.Fertiliser.Units == 'kg') solubility = obj.ChildAsset.Fertiliser.Solubility;

      if (solubility > 0) perMaxConcentration += (unitsin1000L / solubility) * 100;
    });
    return perMaxConcentration;
  }

  public concPer1000FertVol(kgOrL, TotVolMixInLiters, unit: uomUnit, fertVolUnit: uomUnit) {
    const mixVolInUserUnits = fertVolUnit.fromBase(TotVolMixInLiters);
    const ratio1000 = 1000 / mixVolInUserUnits;

    const quantityInUserUnits = unit.fromBase(kgOrL);
    const concPer1000Whatevers = ratio1000 * quantityInUserUnits;

    return concPer1000Whatevers.toFixed(unit.decimalPlaces);
  }

  public setUnits(fert: SiteAsset, weightUnit: uomUnit, fertVolUnit: uomUnit) {
    const units = fert.ChildAsset.Fertiliser.Units;
    if (units == 'kg') fert.unit = weightUnit;
    else fert.unit = fertVolUnit;
  }

  public getMixingCost(mixingCost: number, totalVolMixInLitres: number) {
    return (mixingCost * 1000) / totalVolMixInLitres;
  }

  public NO3N_to_NO3(val: number): number {
    return val * NO3N_to_NO3;
  }

  public NO3_to_NO3N(val: number): number {
    return val / NO3N_to_NO3;
  }

  public NH4N_to_NH4(val: number): number {
    return val * NH4N_to_NH4;
  }

  public NH4_to_NH4N(val: number): number {
    return val / NH4N_to_NH4;
  }

  public nootDP(name: string): number | null {
    if (microNutrients.includes(name)) {
      return 4;
    }

    return null;
  }

  public getNutrientDecimalPaces(nutrients: string[]): Record<string, number | null> {
    return nutrients.reduce((acc, curr) => {
      acc[curr] = this.nootDP(curr);

      return acc;
    }, {});
  }
}

angular.module('fuse').service('FertiliserService', FertiliserService);
