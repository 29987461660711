import * as angular from 'angular';

angular.module('fuse').filter('yesterdayByDayNumber', () => {
  return (items: fuse.planIrrigationInfo[], todayDayNumber: number) => {
    const output = [];
    angular.forEach(items, (item) => {
      if (item.dayNumber == todayDayNumber - 1) {
        output.push(item); //yesterday
      }
    });
    if (!output.length) {
      angular.forEach(items, (item) => {
        if (item.dayNumber == todayDayNumber) {
          output.push(item); //if no yesterday info, put today info
        }
      });
    }
    return output;
  };
});
