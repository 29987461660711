import * as angular from 'angular';
import { ApplicationPrivileges } from './common/ApplicationPrivileges';
import { accountCustomStyling, AccountModel, PermissionService, PrivilegeFlags } from './_services/permission.service';

export interface PostSaveActions {
  navigateTo: () => void;
  changeAccount: () => void;
}

export class BaseController implements angular.IController {
  public scope: angular.IScope;
  public permissionService: PermissionService;

  public accountCustomStyling: accountCustomStyling;
  public apf: PrivilegeFlags;
  public entityManager: breeze.EntityManager;

  private _isReadOnly = false;

  constructor(
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    this.scope = $scope;
    this.permissionService = PermissionService;

    this.setProperties();

    this.scope.$on('accountChanged', () => {
      this.setProperties();
    });
  }

  public get accountId(): number {
    return this.permissionService.accountId;
  }

  public get account(): AccountModel {
    return this.permissionService.currentAccount;
  }

  public get isReadOnly(): boolean {
    return this._isReadOnly;
  }

  // IMPORTANT: If there is a need to use this '$onInit' then the derived classes MUST explicitly call 'super.$onInit()',
  // as otherwise the derived classes override this base definition!
  $onInit() {
  }

  public setEditPermission(permissions: ApplicationPrivileges | ApplicationPrivileges[]): void {
    this._isReadOnly = !this.permissionService.hasPermission(permissions);
  }

  public setReadOnlyAs(value: boolean): void {
    this._isReadOnly = value;
  }

  public updateAccountCustomStyling(): void {
    this.accountCustomStyling = this.permissionService.accountCustomStyling;
  }

  public hasAnyPostSaveActions(actions: PostSaveActions): boolean {
    const actionFn = this.getPostSaveAction(actions);
    const hasPostSaveAction = !!actionFn;

    return hasPostSaveAction;
  }


  public executeAnyPostSaveActions(actions: PostSaveActions): boolean {
    const hasPostSaveAction = this.hasAnyPostSaveActions(actions);

    if (hasPostSaveAction) {
      const actionFn = this.getPostSaveAction(actions);

      actionFn();
    }

    return hasPostSaveAction;
  }

  private getPostSaveAction(actions: PostSaveActions): () => void {
    return actions?.navigateTo ?? actions?.changeAccount;
  }

  private setProperties(): void {
    this.accountCustomStyling = this.permissionService.accountCustomStyling;
    this.apf = this.permissionService.apf;
  }
}

angular.module('fuse').controller('BaseController', BaseController);
