import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';

export class NutrientService {
  private _http: angular.IHttpService;

  constructor($http: angular.IHttpService) {
    this._http = $http;
  }

  public calculateDBValue(sign: string, displayValue: number): number {
    let result: number;

    if (isNaN(displayValue)) {
      displayValue = 0;
    }

    switch (sign) {
      case 'LessThan':
        result = displayValue * -1;
        break;

      case 'NotTested':
        result = -9999; //null; #IG183 : ObsNutrient doesn't accept null so this logic doesnt work so using -9999 for N/A
        break;
      default:
        result = Math.abs(displayValue);
        break;
    }

    return result;
  }

  public getNutrientSign(dbValue: any): string {
    let sign: string;
    if (this.IsValidFormatNutrientsValue(dbValue)) {
      dbValue = this.getNutrientObservationValue(dbValue);
    }
    if (dbValue === -9999) {
      sign = 'NotTested';
    }

    if (dbValue < 0 && dbValue != -9999) {
      sign = 'LessThan';
    }
    if (dbValue >= 0) {
      sign = 'blank';
    }

    return sign;
  }

  public calculateDisplayValue(dbValue: number): number {
    let result: number;

    if (dbValue === -9999) {
      result = Number.NaN;
    }

    if (dbValue < 0 && dbValue != -9999) {
      result = dbValue * -1;
    }
    if (dbValue >= 0) {
      result = dbValue;
    }

    return result;
  }

  public IsValidFormatNutrientsValue(value: string): boolean {
    return SWANConstants.RegexSampleWaterObservationValue.test(value);
  }

  public getNutrientObservationValue(value: string): number {
    let result: number;
    if (!angular.isUndefined(value) && value && value != null) {
      if (value.toString().indexOf('<') != -1) {
        const temp = value.toString().substring(1, value.toString().length);
        result = Number(temp) * -1;
      } else {
        result = Number(value);
      }
    }
    return result;
  }

  public applyPlannedToApplied(siteWeeks: fuse.siteWeek[], option: fuse.overrideOption): angular.IHttpPromise<void> {
    return this._http.post(CommonHelper.getApiUrl('nutrients/applyPlannedToApplied'), {
      overrideOption: option,
      siteWeeks: siteWeeks,
    } as fuse.applyPlannedNutrient);
  }

  public removeUnused(siteWeeks: fuse.siteWeek[]): angular.IHttpPromise<void> {
    return this._http.post(CommonHelper.getApiUrl('nutrients/removeUnused'), {
      siteWeeks: siteWeeks,
    } as fuse.removeUnusedNutrient);
  }
}

angular.module('fuse').service('NutrientService', NutrientService);
