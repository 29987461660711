import * as angular from 'angular';
import * as $ from 'jquery';
import { IDashboardParams, healthIndexColourScheme } from '@common/models/interfaces';
import { LocalStorageService } from './local-storage.service';

export interface palette {
  name: string;
  colours: string[];
}
export class ColourSchemeService {
  private colourSchemes: fuse.colourSchemes;
  public healthThemes = [] as healthIndexColourScheme[];
  public palettes: palette[] = [];
  public temporalPalettes: palette[] = [];

  private _localStorageService: LocalStorageService;

  constructor(LocalStorageService: LocalStorageService) {
    this._localStorageService = LocalStorageService;
  }

  public setColourSchemes(colourSchemes: fuse.colourSchemes) {
    this.colourSchemes = colourSchemes;
    this.initialisePalettes();
  }

  public resetHealthTheme(index: string) {
    const currentTheme = this.healthThemes.find((t) => t.healthIndex == index);
    if (currentTheme != null) {
      const healthTheme = this.buildColourScheme(index, currentTheme.paletteType, null);
      currentTheme.palette = healthTheme.palette;
      currentTheme.padLeft = healthTheme.padLeft;
      currentTheme.padLeftColour = healthTheme.padLeftColour;
      currentTheme.padRight = healthTheme.padRight;
      currentTheme.padRightColour = healthTheme.padRightColour;
      currentTheme.numberOfColours = healthTheme.numberOfColours;
      currentTheme.reverse = healthTheme.reverse;
      currentTheme.useDefault = true;
    }
  }

  public initialisePalettes() {
    const palettes: palette[] = [];
    for (var palette in this.colourSchemes.palettes) {
      palettes.push({
        name: palette,
        colours: this.colourSchemes.palettes[palette],
      } as palette);
    }
    const temporalPalettes: palette[] = [];
    for (var palette in this.colourSchemes.temporalPalettes) {
      temporalPalettes.push({
        name: palette,
        colours: this.colourSchemes.temporalPalettes[palette],
      } as palette);
    }
    this.palettes = palettes;
    this.temporalPalettes = temporalPalettes;
    this.createColourPaletteCSS();
  }

  private buildColourScheme(index: string, paletteType: string, dashboardParams?: IDashboardParams): healthIndexColourScheme {
    let defaultPalette = 'SWAN1';
    if (paletteType == 'Temporal') {
      defaultPalette = 'RedGreen';
    }
    const healthTheme = {
      healthIndex: index,
      numberOfColours: 0,
      padLeft: 0,
      padLeftColour: '#ffffff',
      padRight: 0,
      padRightColour: '#ffffff',
      reverse: false,
      palette: defaultPalette,
      paletteType: paletteType,
      useDefault: true,
    } as healthIndexColourScheme;
    const colourScheme = this.colourSchemes.defaults[index] as fuse.colourScheme;
    if (colourScheme != null) {
      //This has defaults
      healthTheme.palette = colourScheme.palette;
      healthTheme.padLeft = colourScheme.leftPad.width;
      healthTheme.padLeftColour = colourScheme.leftPad.colour;
      healthTheme.padRight = colourScheme.rightPad.width;
      healthTheme.padRightColour = colourScheme.rightPad.colour;
      healthTheme.reverse = colourScheme.reverse;
      healthTheme.numberOfColours = colourScheme.numberOfColours;
      //check that the palette exists
      if (paletteType == 'SiteHealth') {
        if (this.colourSchemes.palettes[healthTheme.palette] == null) {
          healthTheme.palette == defaultPalette;
        }
      } else {
        if (this.colourSchemes.temporalPalettes[healthTheme.palette] == null) {
          healthTheme.palette == defaultPalette;
        }
      }
    }
    if (dashboardParams != null) {
      const customScheme = dashboardParams.healthIndexColourSchemes.find((h) => h.healthIndex == index);
      if (customScheme != null) {
        if (customScheme.useDefault != null && !customScheme.useDefault) {
          healthTheme.useDefault = false;
          //User customisation exists. Validate it. If invalid use the default from above.
          if (paletteType == 'SiteHealth') {
            if (customScheme.palette != null && this.colourSchemes.palettes[customScheme.palette] != null) {
              healthTheme.palette = customScheme.palette;
            }
          } else {
            if (customScheme.palette != null && this.colourSchemes.temporalPalettes[customScheme.palette] != null) {
              healthTheme.palette = customScheme.palette;
            }
          }
          if (customScheme.padLeft != null && angular.isNumber(customScheme.padLeft)) {
            healthTheme.padLeft = Number(customScheme.padLeft);
          }
          if (customScheme.padLeftColour != null) {
            healthTheme.padLeftColour = customScheme.padLeftColour;
          }
          if (customScheme.padRight != null && angular.isNumber(customScheme.padRight)) {
            healthTheme.padRight = Number(customScheme.padRight);
          }
          if (customScheme.padRightColour != null) {
            healthTheme.padRightColour = customScheme.padRightColour;
          }
          if (customScheme.reverse != null) {
            healthTheme.reverse = customScheme.reverse;
          }
          if (customScheme.numberOfColours != null && angular.isNumber(customScheme.numberOfColours)) {
            healthTheme.numberOfColours = Number(customScheme.numberOfColours);
          }
        }
      }
    }
    return healthTheme;
  }

  public initialiseHealthThemes(indexList: string[]) {
    this.healthThemes = [];

    const dashboardParams: IDashboardParams = this.getDashboardParams();
    indexList.forEach((index) => {
      const healthTheme = this.buildColourScheme(index, 'SiteHealth', dashboardParams);
      this.healthThemes.push(healthTheme);
    });
    const temporalTheme = this.buildColourScheme('TEMPORALVARIATION', 'Temporal', dashboardParams);
    this.healthThemes.push(temporalTheme);
    //Overwirte the dashboard params object with the "cleaned" version
    this.saveHealthThemes();
    //Generate the CSS required for the palettes
    this.createHealthThemeCSS();
  }

  public createColourPaletteCSS() {
    const cssClassPrefix = 'colourPalette';
    let css = '';
    this.palettes.forEach((palette) => {
      const colourString = palette.colours.join(', ');
      const colourStringReverse = [...palette.colours].reverse().join(', ');
      css +=
        '.' +
        cssClassPrefix +
        '.SiteHealth.' +
        palette.name +
        ' { background-image: linear-gradient(to right, ' +
        colourString +
        ') }\n';
      css +=
        '.' +
        cssClassPrefix +
        '.SiteHealth.' +
        palette.name +
        '.reverse { background-image: linear-gradient(to right, ' +
        colourStringReverse +
        ') }\n';
    });
    this.temporalPalettes.forEach((palette) => {
      const colourString = palette.colours.join(', ');
      const colourStringReverse = [...palette.colours].reverse().join(', ');
      css +=
        '.' +
        cssClassPrefix +
        '.Temporal.' +
        palette.name +
        ' { background-image: linear-gradient(to right, ' +
        colourString +
        ') }\n';
      css +=
        '.' +
        cssClassPrefix +
        '.Temporal.' +
        palette.name +
        '.reverse { background-image: linear-gradient(to right, ' +
        colourStringReverse +
        ') }\n';
    });
    const element = $('#' + cssClassPrefix);
    if (!element.length) {
      $('<style type="text/css" id="' + cssClassPrefix + '">' + css + '</style>').appendTo($('head'));
    } else {
      element[0].innerText = css;
    }
  }

  public createHealthThemeCSS() {
    const cssClassPrefix = 'healthTheme';
    let css = '';
    this.healthThemes.forEach((theme) => {
      const colourPalette = this.getColourPalette(theme.healthIndex, false);
      const colourPaletteReverse = this.getColourPalette(theme.healthIndex, true);
      if (theme.numberOfColours > 0) {
        const percentage = 100 / colourPalette.length;
        //We need to assign a % to each colour gradient
        let percentFrom = 0;
        for (let i = 0; i < colourPalette.length; i++) {
          let percentTo = percentFrom + percentage;
          if (i == colourPalette.length - 1) {
            percentTo = 100;
          }
          const percentStr = ' ' + percentFrom + '% ' + percentTo + '%';
          colourPalette[i] = colourPalette[i] + percentStr;
          colourPaletteReverse[i] = colourPaletteReverse[i] + percentStr;
          percentFrom = percentTo;
        }
      }
      css +=
        '.' +
        cssClassPrefix +
        '.' +
        theme.healthIndex +
        ' { background-image: linear-gradient(to right, ' +
        colourPalette.join(',') +
        ') }\n';
      css +=
        '.' +
        cssClassPrefix +
        '.' +
        theme.healthIndex +
        '.reverse { background-image: linear-gradient(to right, ' +
        colourPaletteReverse.join(',') +
        ') }\n';
    });
    const element = $('#' + cssClassPrefix);
    if (!element.length) {
      $('<style type="text/css" id="' + cssClassPrefix + '">' + css + '</style>').appendTo($('head'));
    } else {
      element[0].innerText = css;
    }
  }

  public getColourPalette(index: string, reverse?: boolean): string[] {
    const theme = this.healthThemes.find((h) => h.healthIndex == index);
    let paletteArr: string[] = [];
    if (theme.paletteType == 'Temporal') {
      paletteArr = this.colourSchemes.temporalPalettes[theme.palette] as string[];
    } else {
      paletteArr = this.colourSchemes.palettes[theme.palette] as string[];
    }
    const leftArr = Array(theme.padLeft).fill(theme.padLeftColour);
    const rightPadArr = Array(theme.padRight).fill(theme.padRightColour);

    if (theme.numberOfColours != null && theme.numberOfColours > 0) {
      const reducedPalette: string[] = Array(theme.numberOfColours).map(() => '');

      //Always take the first colour and last colour
      reducedPalette[0] = paletteArr[0];
      if (theme.numberOfColours > 1) {
        reducedPalette[theme.numberOfColours - 1] = paletteArr[paletteArr.length - 1];
        if (theme.numberOfColours > 2) {
          const step = (paletteArr.length - 1) / (theme.numberOfColours - 1);
          let j = step - 1;
          for (let i = 1; i <= theme.numberOfColours - 2; i++) {
            const minJ = Math.floor(j);

            const difference = j - minJ;
            let colour = '';

            if (difference > 0) {
              const maxJ = Math.ceil(j);
              const prevRGB = this.parseColor(paletteArr[minJ]);
              const nextRGB = this.parseColor(paletteArr[maxJ]);

              const red = prevRGB[0] + Math.ceil((nextRGB[0] - prevRGB[0]) * difference);
              const green = prevRGB[1] + Math.ceil((nextRGB[1] - prevRGB[1]) * difference);
              const blue = prevRGB[2] + Math.ceil((nextRGB[2] - prevRGB[2]) * difference);

              const redStr = ('0' + red.toString(16)).slice(-2);
              const greenStr = ('0' + green.toString(16)).slice(-2);
              const blueStr = ('0' + blue.toString(16)).slice(-2);

              colour = '#' + redStr + greenStr + blueStr;
            } else {
              colour = paletteArr[j];
            }
            reducedPalette[i] = colour;
            j += step;
          }
        }
      }
      paletteArr = reducedPalette;
    }

    const palette: string[] = [];
    if (reverse == null) {
      reverse = theme.reverse;
    }
    if (reverse) {
      palette.push(...leftArr);
      const colours = [...paletteArr].reverse();
      palette.push(...colours);
      palette.push(...rightPadArr);
    } else {
      palette.push(...leftArr);
      palette.push(...paletteArr);
      palette.push(...rightPadArr);
    }

    return palette;
  }

  private parseColor(input: string) {
    const div = document.createElement('div');
    div.style.backgroundColor = input;
    const m = div.style.backgroundColor.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
    if (m) return [parseInt(m[1]), parseInt(m[2]), parseInt(m[3])];
    else throw new Error('Colour ' + input + ' could not be parsed.');
  }

  private getDashboardParams(): IDashboardParams {
    return this._localStorageService.get('dashboardParams') as IDashboardParams;
  }

  public saveHealthThemes() {
    const dashboardParams: IDashboardParams = this.getDashboardParams();
    dashboardParams.healthIndexColourSchemes = this.healthThemes;
    this._localStorageService.set('dashboardParams', dashboardParams);
  }
}

angular.module('fuse').service('ColourSchemeService', ColourSchemeService);
